import React, { Component } from 'react'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import jQuery from 'jquery'
import './paginate.css'

class Offres extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [
                {
                    name: 'Offre',
                    selector: 'titre',
                    sortable: false,
                    center: true
                },
                {
                    name: 'Modifier',
                    cell: row => <Link to={{
                        pathname: '/ModifOffre',
                        state: { offreId: row.offreId }
                    }}><button className='buttonModifier'><i style={{ color: 'white' }} className="fas fa-pencil-alt"></i></button></Link>,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                }
            ],
            nombreTotalElement: 0,
            filterElement: '',
            mycurrentPage: 1,
            resetFirstPage: false,
            nbrEltperPage: 20,
            modal: false,
            pending: true
        }
        this.newElement = this.newElement.bind(this);
        this.filterSearch = this.filterSearch.bind(this);
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mtd/Offres.php',
                type: 'POST',
                data: {
                    query: '',
                    limit: this.state.nbrEltperPage,
                    page: 1
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    console.log(code_html)
                    this.setState({
                        nombreTotalElement: code_html.total,
                        data: code_html.data,
                        pending: false
                    });
                }
            });
        }
        else {
            document.location = '/'
        }
    }

    newElement(page, totalRows) {
        this.setState({
            pending: true
        },
            function () {
                console.log(page)
                console.log(totalRows)
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mtd/Offres.php',
                    type: 'POST',
                    data: {
                        query: this.state.filterElement,
                        limit: this.state.nbrEltperPage,
                        page: page
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                });
            }.bind(this)
        )
    }

    perPage(currentRowsPerPage, currentPage) {
        this.setState(
            {
                nbrEltperPage: currentRowsPerPage,
                pending: true
            }
            , function () {
                console.log(currentRowsPerPage)
                console.log(currentPage)
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mtd/Offres.php',
                    type: 'POST',
                    data: {
                        query: this.state.filterElement,
                        limit: currentRowsPerPage,
                        page: currentPage
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                });
            }
        );
    }

    filterSearch(event) {
        this.setState({
            filterElement: event.target.value,
            resetFirstPage: !this.state.resetFirstPage,
            pending: true
        },
            function () {
                jQuery.ajax({
                    url: process.env.REACT_APP_API_URL + 'Back_mtd/Offres.php',
                    type: 'POST',
                    data: {
                        query: this.state.filterElement,
                        limit: this.state.nbrEltperPage,
                        page: 1
                    },
                    dataType: 'json',
                    success: (code_html, statut) => {
                        console.log(code_html)
                        this.setState({
                            nombreTotalElement: code_html.total,
                            data: code_html.data,
                            pending: false
                        });
                    }
                })
            }
        );
    }

    render() {
        return (
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="page-header">
                        <h3 className="page-title">Offres </h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 grid-margin">
                            <div className="card">
                                <div className="card-body">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            marginBottom: "10px",
                                            marginRight: "20px",
                                        }}
                                    >
                                        <Link to="/AjoutOffre">
                                            {" "}
                                            <button
                                                style={{
                                                    background: "#3A3F51",
                                                    border: "1px solid #3A3F51",
                                                    borderRadius: "5px",
                                                }}
                                                type="button"
                                                className="btn btn-info"
                                            >
                                                {" "}
                                                <i className="fas fa-plus"></i>Offre
                                            </button>
                                        </Link>
                                    </div>
                                    <div className="mb-0 form-group">
                                        <div className="input-group-alternative input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fas fa-search"></i>
                                                </span>
                                                <input style={{ width: '100%', height: '100%' }}
                                                    onChange={(currentRowsPerPage, currentPage) =>
                                                        this.filterSearch(currentRowsPerPage, currentPage)
                                                    }
                                                    placeholder="Search"
                                                    type="text"
                                                    className="search"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable
                                        columns={this.state.columns}
                                        data={this.state.data}
                                        highlightOnHover={true}
                                        pagination={true}
                                        paginationServer={true}
                                        onChangePage={(page, totalRows) =>
                                            this.newElement(page, totalRows)
                                        }
                                        paginationTotalRows={this.state.nombreTotalElement}
                                        paginationPerPage={this.state.nbrEltperPage}
                                        paginationRowsPerPageOptions={[20, 25, 30, 35, 40]}
                                        onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                                            this.perPage(currentRowsPerPage, currentPage)
                                        }
                                        paginationResetDefaultPage={this.state.resetFirstPage}
                                        paginationComponentOptions={{
                                            rowsPerPageText: "Elements par page:",
                                            rangeSeparatorText: "de",
                                            noRowsPerPage: false,
                                        }}
                                        progressPending={this.state.pending}
                                        noHeader={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Offres;

