import React, { Component } from "react";
import axios from "axios";
import jQuery from "jquery";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

class AjoutClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nom: "",
      adresse: "",
      tel: "",
      mf: "",
      villes: [],
      selectedVille: "",
      responsable: "",
      qualite: "",
      assujetti: [],
      selectedAssujetti: "",
      rs: '',
      rc: '',
      codePostal: '',
      fax: '',
      email: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mtd/GetVilles.php",
        type: "POST",
        data: {},
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            villes: code_html,
          });
        },
      });
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mtd/GetAssujetti.php",
        type: "POST",
        data: {},
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            assujetti: code_html,
          });
        },
      });
    }
  }

  addNom = (event) => {
    this.setState({
      nom: event.target.value,
    });
  };

  addAdresse = (event) => {
    this.setState({
      adresse: event.target.value,
    });
  };

  addVille = (selectedVille) => {
    this.setState({ selectedVille: selectedVille });
  };

  addTel = (event) => {
    this.setState({
      tel: event.target.value,
    });
  };

  addMf = (event) => {
    this.setState({
      mf: event.target.value,
    });
  };

  addResponsable = (event) => {
    this.setState({
      responsable: event.target.value,
    });
  };

  addQualite = (event) => {
    this.setState({
      qualite: event.target.value,
    });
  };

  addAssujetti = (selectedAssujetti) => {
    this.setState({ selectedAssujetti: selectedAssujetti });
  };

  addRs = (event) => {
    this.setState({
      rs: event.target.value,
    });
  };

  addRc = (event) => {
    this.setState({
      rc: event.target.value,
    });
  };

  addCodePostal = (event) => {
    this.setState({
      codePostal: event.target.value,
    });
  };

  addFax = (event) => {
    this.setState({
      fax: event.target.value,
    });
  };

  addEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  ajout = () => {
    if (
      this.state.selectedRole != "" &&
      this.state.login != "" &&
      this.state.password != ""
    ) {
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mtd/AjoutClient.php",
        type: "POST",
        data: {
          nom: this.state.nom,
          adresse: this.state.adresse,
          tel: this.state.tel,
          mf: this.state.mf,
          villes: this.state.selectedVille.value,
          assujetti: this.state.selectedAssujetti.value,
          responsable: this.state.responsable,
          qualite: this.state.qualite,
          rs: this.state.rs,
          rc: this.state.rc,
          codePostal: this.state.codePostal,
          fax: this.state.fax,
          email: this.state.email
        },
        dataType: "json",
        success: (code_html, statut) => {
          if (code_html.Result == "OK") {
            this.props.history.push("/Clients");
          } else if (code_html.Result == "KO") {
            alert("erreur,l'un de vos champs est vide!!");
          }
        },
      });
    } else {
      toast.error("⛔ veuillez verifier les champs !!", {
        containerId: "A",
      });
    }
  };

  render() {
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title"> Ajout client </h3>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Nom</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nom"
                        onChange={this.addNom}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Villes</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addVille}
                        options={this.state.villes}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Adresse</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Adresse"
                        onChange={this.addAdresse}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Code postal</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Code postal"
                        onChange={this.addCodePostal}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        onChange={this.addEmail}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Tel</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Tel"
                        onChange={this.addTel}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Fax</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Fax"
                        onChange={this.addFax}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Raison social</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Raison social"
                        onChange={this.addRs}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Registre de commerce</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Registre de commerce"
                        onChange={this.addRc}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Matricule fiscale</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Matricule fiscale"
                        onChange={this.addMf}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Responsable</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Responsable"
                        onChange={this.addResponsable}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Qualité</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Qualité"
                        onChange={this.addQualite}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Assujetti</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addAssujetti}
                        options={this.state.assujetti}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      style={{ borderRadius: "5px" }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.ajout}
                    >
                      Confirmer
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AjoutClient);
