import React, { Component, Fragment } from "react";
import axios from "axios";
import jQuery from "jquery";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import { RadioGroup, Radio } from "react-radio-group";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class ModifBonDeCommande extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bonDeCommandeId: "",
      offres: [],
      selectOffre: "",
      clients: [],
      selectClient: "",
      options: [],
      selectOption: "",
      rubrique: [],
      selectRubrique: "",
      prix: "",
      prixFinal: "",
      tauxRemise: "",
      fraisRes: "",
      fraisRef: "",
      remiseMax: "",
      societeId: "",
      offreId: "",
      selectLangue: "",
      arrayPaiement: [],
      lignePaiementSupp: "",
      // arrayDelais: [],
      delais: "",
      ligneDelaisSupp: "",
      modalDelais: false,
      modal: false,
      arrayModalites: [],
      tabModalites: [],
      villeContrat: "",
      pack: [],
      selectPack: '',
      nbrMois: 0,
      nbrCom: 0,
      date: new Date(),
      idOffreContrat: ''
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      let bonDeCommandeId = this.props.location.state.bonDeCommandeId;
      axios
        .get(
          process.env.REACT_APP_API_URL +
          "Back_mtd/GetBonDeCommande.php?id=" +
          bonDeCommandeId
        )
        .then((res) => {
          console.log(res);
          this.setState(
            {
              bonDeCommandeId: res.data.bonDeCommandeId,
              selectOffre: res.data.offres,
              societeId: res.data.societeId,
              selectClient: res.data.clients,
              selectOption: res.data.options,
              selectRubrique: res.data.rubrique,
              prix: res.data.prix,
              tauxRemise: res.data.tauxRemise,
              prixFinal: res.data.prixFinal,
              fraisRes: res.data.fraisRes,
              fraisRef: res.data.fraisRef,
              selectLangue: res.data.langue,
              arrayPaiement: res.data.paiement,
              // arrayDelais: res.data.delais,
              delais: res.data.delais,
              tabModalites: res.data.modalites,
              villeContrat: res.data.villeContrat,
              selectPack: res.data.pack,
              pack: res.data.pack,
              nbrMois: res.data.nbrMois,
              nbrCom: res.data.nbrCom,
              date: new Date(res.data.date),
              idOffreContrat: res.data.idOffreContrat
            },
            () => {
              var tab = [];
              for (let i = 0; i < this.state.tabModalites.length; i++) {
                if (this.state.tabModalites[i].etat == 1) {
                  tab.push(this.state.tabModalites[i].modaliteId);
                }
              }
              this.setState({
                arrayModalites: tab,
              });
            }
          );
        });
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mtd/GetClients.php",
        type: "POST",
        data: {},
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            clients: code_html,
          });
        },
      });
      axios
        .get(process.env.REACT_APP_API_URL + "Back_mtd/GetTauxRemise.php")
        .then((res) => {
          console.log(res);
          this.setState({
            remiseMax: res.data,
          });
        });
      axios
        .get(process.env.REACT_APP_API_URL + "Back_mtd/GetFraisRes.php")
        .then((res) => {
          console.log(res);
          this.setState({
            fraisRes: res.data,
          });
        });
      axios
        .get(process.env.REACT_APP_API_URL + "Back_mtd/GetFraisRef.php")
        .then((res) => {
          console.log(res);
          this.setState({
            fraisRef: res.data,
          });
        });
    }
    // jQuery.ajax({
    //   url: process.env.REACT_APP_API_URL + "Back_mtd/SelectPack.php",
    //   type: "POST",
    //   data: {},
    //   dataType: "json",
    //   success: (code_html, statut) => {
    //     this.setState({
    //       pack: code_html
    //     });
    //   },
    // });
  }

  modifDate = d => {
    this.setState({
      date: d
    });
  };

  // Ajout Ligne Paiement
  modifMotif = (event, index) => {
    var motif = event.target.value;
    this.setState({
      arrayPaiement: this.state.arrayPaiement.map((el, id) =>
        id === index ? Object.assign(el, { motif: motif }) : el
      ),
    });
  };

  modifPourcentage = (event, index) => {
    var pourcentage = event.target.value;
    this.setState({
      arrayPaiement: this.state.arrayPaiement.map((el, id) =>
        id === index ? Object.assign(el, { pourcentage: pourcentage }) : el
      ),
    });
  };

  addLignePaiement = () => {
    var element = { pourcentage: '', motif: "" };
    this.setState({
      arrayPaiement: [...this.state.arrayPaiement, element],
    });
  };

  togglePaiement = (index) => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      lignePaiementSupp: index,
    }));
  };

  deletePaiement = () => {
    this.state.arrayPaiement.splice(this.state.lignePaiementSupp, 1);
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  nonDeletePaiement = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  // Ajout Ligne Delais
  // addDelais = (event, index) => {
  //   var delais = event.target.value;
  //   this.setState({
  //     arrayDelais: this.state.arrayDelais.map((el, id) =>
  //       id === index ? Object.assign(el, { delais: delais }) : el
  //     ),
  //   });
  // };

  // addLigneDelais = () => {
  //   var element = { delais: "" };
  //   this.setState({
  //     arrayDelais: [...this.state.arrayDelais, element],
  //   });
  // };

  // toggleDelais = (index) => {
  //   this.setState((prevState) => ({
  //     modalDelais: !prevState.modalDelais,
  //     ligneDelaisSupp: index,
  //   }));
  // };

  // deleteDelais = () => {
  //   this.state.arrayDelais.splice(this.state.ligneDelaisSupp, 1);
  //   this.setState((prevState) => ({
  //     modalDelais: !prevState.modalDelais,
  //   }));
  // };

  // nonDeleteDelais = () => {
  //   this.setState((prevState) => ({
  //     modalDelais: !prevState.modalDelais,
  //   }));
  // };

  modifDelai = (event) => {
    this.setState({
      delais: event.target.value,
    });
  };

  modifNbrCom = (event) => {
    this.setState({
      nbrCom: event.target.value,
    });
  };

  modifClient = (selectClient) => {
    if (selectClient != null) {
      this.setState({ selectClient: selectClient }, () => {
        jQuery.ajax({
          url: process.env.REACT_APP_API_URL + "Back_mtd/GetOffresByClientId.php",
          type: "POST",
          data: {
            clientId: selectClient.value,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              offres: code_html,
            });
          },
        });
      });
    }
    else {
      this.setState({
        selectClient: '',
        offres: []
      });
    }
  };

  modifOffre = (selectOffre) => {
    if (selectOffre != null) {
      this.setState({ selectOffre: selectOffre }, () => {
        jQuery.ajax({
          url: process.env.REACT_APP_API_URL + "Back_mtd/GetInfoByClientId.php",
          type: "POST",
          data: {
            clientId: this.state.selectClient.value,
            offreId: selectOffre.value,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              societeId: code_html.societeId,
              offreId: code_html.offreId,
              selectOffre: code_html.offres,
              selectOption: code_html.options,
              selectRubrique: code_html.rubrique,
              prix: code_html.prix,
              tauxRemise: code_html.tauxRemise,
              prixFinal: code_html.prixFinal,
              fraisRes: code_html.fraisRes,
              fraisRef: code_html.fraisRef,
              pack: code_html.pack,
              idOffreContrat: code_html.idOffreContrat
            });
          },
        });
        jQuery.ajax({
          url: process.env.REACT_APP_API_URL + "Back_mtd/GetOptionByOffreId.php",
          type: "POST",
          data: {
            offreId: selectOffre.value,
            societeId: this.state.societeId,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              opt: code_html,
            });
          },
        });
        jQuery.ajax({
          url: process.env.REACT_APP_API_URL + "Back_mtd/GetRubrique.php",
          type: "POST",
          data: {
            offreId: selectOffre.value,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              rubrique: code_html,
            });
          },
        });
      });
    }
    else {
      this.setState({
        selectOffre: '',
        opt: [],
        rubrique: [],
        selectRubrique: []
      });
    }
  };

  modifVilleContrat = (event) => {
    this.setState({
      villeContrat: event.target.value,
    });
  };


  modifOption = (selectOption) => {
    this.setState({ selectOption: selectOption });
  };

  modifRubrique = (selectRubrique) => {
    this.setState({ selectRubrique: selectRubrique });
  };

  modifPrix = (event) => {
    var prix = event.target.value;
    this.setState({
      prix: prix,
      prixFinal: prix,
    }, () => {
      if (prix != "" && this.state.tauxRemise != "") {
        var prixFinal =
          parseFloat(prix) -
          (parseFloat(prix) * parseFloat(this.state.tauxRemise)) / 100;
        this.setState({
          prixFinal: prixFinal,
        });
      }
    });
  };

  modifTauxRemise = (event) => {
    var tauxRemise = parseFloat(event.target.value);
    if (isNaN(tauxRemise)) {
      this.setState({
        prixFinal: this.state.prix,
        tauxRemise: ""
      });
    }
    else {
      if (tauxRemise <= this.state.remiseMax && tauxRemise > 0) {
        if (tauxRemise != "" && this.state.prix != "") {
          var prixFinal =
            parseFloat(this.state.prix) -
            (parseFloat(this.state.prix) * parseFloat(tauxRemise)) / 100;
          this.setState({
            tauxRemise: tauxRemise,
            prixFinal: prixFinal,
          });
        }
        else {
          this.setState({
            tauxRemise: tauxRemise
          });
          toast.error("⛔ Veuillez saisir le prix !", {
            containerId: "A",
          });
        }
      } else {
        toast.error("⛔ Verifier le taux de remise !", {
          containerId: "A",
        });
      }
    }
  };

  modifLangue = (selectLangue) => {
    this.setState({ selectLangue: selectLangue });
  };

  modifPack = (selectPack) => {
    this.setState({ selectPack: selectPack });
  };

  modifMois = (event) => {
    this.setState({
      nbrMois: event.target.value,
    });
  };

  cocheModalite = (e, index, el) => {
    var modaliteId = el.modaliteId;
    if (e.target.checked === true) {
      var array = this.state.arrayModalites;
      array.push(modaliteId);
      this.setState({
        tabModalites: this.state.tabModalites.map((el, id) =>
          id === index ? Object.assign(el, { etat: 1 }) : el
        ),
        arrayModalites: array,
      });
    } else {
      var array = this.state.arrayModalites;
      for (let i = 0; i < array.length; i++) {
        if (modaliteId == array[i]) {
          var indice = i;
        }
      }
      array.splice(indice, 1);
      this.setState({
        tabModalites: this.state.tabModalites.map((el, id) =>
          id === index ? Object.assign(el, { etat: 0 }) : el
        ),
        arrayModalites: array,
      });
    }
  };

  modif = () => {
    // convert date paiement
    let date = this.state.date
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    var convertDate = [year, month, day].join('-');
    //
    var bonDeCommandeId = this.props.location.state.bonDeCommandeId;
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mtd/UpdBonDeCommande.php",
      type: "POST",
      data: {
        bonDeCommandeId: bonDeCommandeId,
        clientId: this.state.selectClient.value,
        societeId: this.state.societeId,
        offreId: this.state.selectOffre.value,
        options: this.state.selectOption,
        rubrique: this.state.selectRubrique,
        prix: this.state.prix,
        remise: this.state.tauxRemise,
        prixFinal: this.state.prixFinal,
        fraisRes: this.state.fraisRes,
        fraisRef: this.state.fraisRef,
        langue: this.state.selectLangue,
        arrayModalites: this.state.arrayModalites,
        // arrayDelais: this.state.arrayDelais,
        delais: this.state.delais,
        arrayPaiement: this.state.arrayPaiement,
        villeContrat: this.state.villeContrat,
        packId: this.state.selectPack,
        nbrMois: this.state.nbrMois,
        nbrCom: this.state.nbrCom,
        date: convertDate,
        idOffreContrat: this.state.idOffreContrat
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          //
          var bonDeCommandeId = code_html.bonDeCommandeId;
          window.open(
            "https://commercial.mtd-app.com/ImprBonCom?idBonCom=" + bonDeCommandeId
          );
          //
          this.props.history.push("/BonDeCommande");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title"> Modification bon de commande </h3>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Date</label>
                      <DatePicker class="form-control ddate" style={{ border: 'none' }}
                        selected={this.state.date}
                        onChange={this.modifDate}
                        dateFormat="dd/MM/yyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Client</label>
                      <Select
                        value={this.state.selectClient}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.modifClient}
                        options={this.state.clients}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Langue</label>
                      <RadioGroup
                        name="Langue"
                        selectedValue={this.state.selectLangue}
                        onChange={this.modifLangue}
                      >
                        <label style={{ paddingRight: "5px" }}>
                          <Radio value="2" />
                          Arabe
                        </label>
                        <label style={{ paddingRight: "5px" }}>
                          <Radio value="1" />
                          Français
                        </label>
                        <label style={{ paddingRight: "5px" }}>
                          <Radio value="3" />
                          Anglais
                        </label>
                      </RadioGroup>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Offre</label>
                      <Select
                        value={this.state.selectOffre}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.modifOffre}
                        options={this.state.offres}
                      />
                    </div>
                  </div>
                  {this.state.selectOffre.value != 6 ? (<Fragment>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Supplémént</label>
                        <Select
                          value={this.state.selectOption}
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.modifOption}
                          options={this.state.opt}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Rubrique</label>
                        <Select
                          value={this.state.selectRubrique}
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.modifRubrique}
                          options={this.state.rubrique}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Prix</label>
                        <input
                          value={this.state.prix}
                          type="text"
                          className="form-control"
                          placeholder="prix"
                          onChange={this.modifPrix}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Remise</label>
                        <input
                          value={this.state.tauxRemise}
                          type="Number"
                          min="0"
                          max="10"
                          className="form-control"
                          placeholder="remise"
                          onChange={this.modifTauxRemise}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Prix Final</label>
                        <input
                          value={this.state.prixFinal}
                          disabled
                          type="text"
                          className="form-control"
                          placeholder="prix final"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Frais Réservation</label>
                        <input
                          value={this.state.fraisRes}
                          type="text"
                          disabled
                          className="form-control"
                          placeholder="frais réservation"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Frais Référencement</label>
                        <input
                          value={this.state.fraisRef}
                          disabled
                          type="text"
                          className="form-control"
                          placeholder="frais référencement"
                        />
                      </div>
                    </div>
                  </Fragment>) : (<Fragment>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Supplémént</label>
                        <Select
                          value={this.state.selectOption}
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.modifOption}
                          options={this.state.opt}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Pack</label>
                        <Select
                          isMulti={true}
                          value={this.state.selectPack}
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.modifPack}
                          options={this.state.pack}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Nombre de mois</label>
                        <input
                          value={this.state.nbrMois}
                          onChange={this.modifMois}
                          type="number"
                          className="form-control"
                          placeholder="Nombre de mois"
                        />
                      </div>
                    </div>
                  </Fragment>)}
                  <div className="col-md-12">
                    <label>Modalité</label>
                    <div style={{ display: "flex" }}>
                      {this.state.tabModalites.map((el, index) => (
                        <div style={{ display: "flex", width: "100%" }}>
                          <p style={{ paddingRight: "10px", width: "40%" }}>
                            {" "}
                            {el.modalite}{" "}
                          </p>
                          {el.etat == 1 ? (
                            <input
                              checked
                              type="checkbox"
                              onChange={(e) =>
                                this.cocheModalite(e, index, el)
                              }
                            />
                          ) : (
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                this.cocheModalite(e, index, el)
                              }
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <table
                    style={{ marginTop: "10px" }}
                    className="table table-bordered"
                  >
                    <thead>
                      <tr>
                        <th
                          className="col-md-5"
                          style={{ textAlign: "center", width: "40%" }}
                          scope="col"
                        >
                          Pourcentage
                        </th>
                        <th
                          className="col-md-5"
                          style={{ textAlign: "center", width: "40%" }}
                          scope="col"
                        >
                          Modalite de paiement
                        </th>
                        <th
                          className="col-md-2"
                          style={{ textAlign: "center", width: "20%" }}
                          scope="col"
                        >
                          Supp
                        </th>
                      </tr>
                    </thead>
                    {this.state.arrayPaiement.map((el, index) => (
                      <tbody>
                        <tr>
                          <td
                            style={{ textAlign: "center", width: "40%" }}
                            className="col-md-5"
                          >
                            <input
                              style={{ width: '100%' }}
                              value={el.pourcentage}
                              type="text"
                              onChange={(e) => this.modifPourcentage(e, index)}
                            />
                          </td>
                          <td
                            style={{ textAlign: "center", width: "40%" }}
                            className="col-md-5"
                          >
                            <input
                              style={{ width: '100%' }}
                              value={el.motif}
                              type="text"
                              onChange={(e) => this.modifMotif(e, index)}
                            />
                          </td>
                          <td
                            className="col-md-2"
                            style={{ textAlign: "center", width: "20%" }}
                          >
                            <button
                              className="buttonSupprimer"
                              onClick={() => this.togglePaiement(index)}
                            >
                              <i
                                style={{ color: "white" }}
                                className="fas fa-trash-alt"
                              ></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginLeft: "15px",
                      marginTop: "5px",
                    }}
                  >
                    <Button
                      style={{ borderRadius: "5px" }}
                      onClick={this.addLignePaiement}
                      color="secondary"
                    >
                      <i className="fas fa-plus-circle"></i>Ajouter ligne
                    </Button>
                  </div>
                  {/* <table
                      style={{ width: "50%", marginTop: "10px" }}
                      className="table table-bordered"
                    >
                      <thead>
                        <tr>
                          <th
                            className="col-md-5"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Délais
                        </th>
                          <th
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                            scope="col"
                          >
                            Supp
                        </th>
                        </tr>
                      </thead>
                      {this.state.arrayDelais.map((el, index) => (
                        <tbody>
                          <tr>
                            <td
                              style={{ textAlign: "center" }}
                              className="col-md-5"
                            >
                              <input
                                value={el.delais}
                                type="text"
                                onChange={(e) => this.addDelais(e, index)}
                              />
                            </td>
                            <td
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                            >
                              <button
                                className="buttonSupprimer"
                                onClick={() => this.toggleDelais(index)}
                              >
                                <i
                                  style={{ color: "white" }}
                                  className="fas fa-trash-alt"
                                ></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table> */}
                  <div style={{ marginTop: '5px' }} className="col-md-12">
                    <div className="form-group">
                      <label>Delai</label>
                      <input
                        value={this.state.delais}
                        onChange={this.modifDelai}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  {this.state.selectOffre.value == 7 ? (
                    <div style={{ marginTop: '5px' }} className="col-md-12">
                      <div className="form-group">
                        <label>Nombre de commercial</label>
                        <input
                          value={this.state.nbrCom}
                          onChange={this.modifNbrCom}
                          type="number"
                          className="form-control"
                        />
                      </div>
                    </div>
                  ) : (null)}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Ville-Contrat</label>
                      <input
                        value={this.state.villeContrat}
                        type="text"
                        className="form-control"
                        placeholder="Ville-Contrat"
                        onChange={this.modifVilleContrat}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      style={{ borderRadius: "5px", marginTop: '20px' }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.modif}
                    >
                      Confirmer
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              isOpen={this.state.modal}
              toggle={this.nonDeletePaiement}
              className={this.props.className}
              fade={false}
            >
              <ModalHeader toggle={this.nonDeletePaiement}>
                {" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "465px",
                  }}
                >
                  <img width="30%" src="./images/deleteModal.png" alt=".." />
                </div>
              </ModalHeader>
              <ModalBody>
                <div
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    FontSize: "14px",
                    FontWeight: "700",
                    LineHeight: "18.375px",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
                  </div>
                  <div
                    className="hvr-push"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginBottom: "15px",
                    }}
                  >
                    <div>
                      <button
                        onClick={() => this.deletePaiement()}
                        style={{
                          backgroundColor: "#00FF7F",
                          borderRadius: "5px",
                          color: "white",
                        }}
                      >
                        <i
                          style={{ color: "white" }}
                          className="far fa-thumbs-up"
                        ></i>
                        Oui
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={() => this.nonDeletePaiement()}
                        style={{
                          backgroundColor: "#D9534F",
                          borderRadius: "5px",
                          color: "white",
                        }}
                      >
                        <i
                          style={{ color: "white" }}
                          className="far fa-thumbs-down"
                        ></i>
                        Non
                      </button>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            {/* <Modal
                isOpen={this.state.modalDelais}
                toggle={this.nonDeleteDelais}
                className={this.props.className}
                fade={false}
              >
                <ModalHeader toggle={this.nonDeleteDelais}>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "465px",
                    }}
                  >
                    <img width="30%" src="./images/deleteModal.png" alt=".." />
                  </div>
                </ModalHeader>
                <ModalBody>
                  <div
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      FontSize: "14px",
                      FontWeight: "700",
                      LineHeight: "18.375px",
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
                    </div>
                    <div
                      className="hvr-push"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginBottom: "15px",
                      }}
                    >
                      <div>
                        <button
                          onClick={() => this.deleteDelais()}
                          style={{
                            backgroundColor: "#00FF7F",
                            borderRadius: "5px",
                            color: "white",
                          }}
                        >
                          <i
                            style={{ color: "white" }}
                            className="far fa-thumbs-up"
                          ></i>
                      Oui
                    </button>
                      </div>
                      <div>
                        <button
                          onClick={() => this.nonDeleteDelais()}
                          style={{
                            backgroundColor: "#D9534F",
                            borderRadius: "5px",
                            color: "white",
                          }}
                        >
                          <i
                            style={{ color: "white" }}
                            className="far fa-thumbs-down"
                          ></i>
                      Non
                    </button>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal> */}
            <ToastContainer
              transition={Flip}
              enableMultiContainer
              containerId={"A"}
              position={toast.POSITION.TOP_RIGHT}
              autoClose={2500}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ModifBonDeCommande);
