import React, { Component } from "react";
import axios from "axios";
import jQuery from "jquery";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import Select from "react-select";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class ModifOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: "",
      montant: "",
      offres: [],
      selectOffre: "",
      societes: [],
      selectSociete: "",
      offreOptionId: "",
      arrayOffreOption: [],
      ligneOffreOptionSupp: "",
      arrayOffreOptionSupp: [],
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      let offreOptionId = this.props.location.state.offreOptionId;
      var arrayParams = offreOptionId.split("-");
      var offreId = arrayParams[0];
      var societeId = arrayParams[1];
      jQuery.ajax(
        {
          url: process.env.REACT_APP_API_URL + "Back_mtd/GetOptionById.php",
          type: "POST",
          data: {
            offreId: offreId,
            societeId: societeId,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              offreOptionId: code_html.offreOptionId,
              selectOffre: code_html.offres,
              selectSociete: code_html.societes,
            });
          },
        });
      // axios
      //   .get(process.env.REACT_APP_API_URL + "Back_mtd/GetOffres.php")
      //   .then((res) => {
      //     console.log(res);
      //     this.setState({
      //       offres: res.data,
      //     });
      //   });
      axios
        .get(process.env.REACT_APP_API_URL + "Back_mtd/GetSocietes.php")
        .then((res) => {
          console.log(res);
          this.setState({
            societes: res.data,
          });
        });
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mtd/GetOffreOptionById.php",
        type: "POST",
        data: {
          offreId: offreId,
          societeId: societeId,
        },
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            arrayOffreOption: code_html,
          });
        },
      });
    }
  }
  // Modif ligne
  updOptions = (event, index) => {
    var options = event.target.value;
    this.setState({
      arrayOffreOption: this.state.arrayOffreOption.map((el, id) =>
        id === index ? Object.assign(el, { options: options }) : el
      ),
    });
  };

  updMontant = (event, index) => {
    var montant = event.target.value;
    this.setState({
      arrayOffreOption: this.state.arrayOffreOption.map((el, id) =>
        id === index ? Object.assign(el, { montant: montant }) : el
      ),
    });
  };

  addLigneOffreOption = () => {
    var element = { offreOptionId: -1, options: "", montant: "" };
    this.setState({
      arrayOffreOption: [...this.state.arrayOffreOption, element],
    });
  };

  toggleOffreOption = (index, offreOptionId) => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      ligneOffreOptionSupp: index,
      offreOptionId: offreOptionId,
    }));
  };

  deleteOffreOption = () => {
    var offreOptionId = this.state.offreOptionId;
    this.setState(
      {
        arrayOffreOptionSupp: [
          ...this.state.arrayOffreOptionSupp,
          offreOptionId,
        ],
      },
      () => {
        console.log(this.state.arrayOffreOptionSupp);
      }
    );
    this.state.arrayOffreOption.splice(this.state.ligneOffreOptionSupp, 1);
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  nonDeleteOffreOption = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  modifOffre = (selectOffre) => {
    this.setState({ selectOffre: selectOffre });
  };

  modifSociete = (selectSociete) => {
    if (selectSociete != null) {
      this.setState({ selectSociete: selectSociete }, () => {
        jQuery.ajax({
          url: process.env.REACT_APP_API_URL + "Back_mtd/GetOffresBySociete.php",
          type: "POST",
          data: {
            societeId: selectSociete.value,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              offres: code_html,
            });
          },
        });
      });
    }
    else {
      this.setState({
        selectSociete: '',
        offres: []
      });
    }
  };

  modif = () => {
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mtd/UpdOption.php",
      type: "POST",
      data: {
        offreOptionId: this.state.offreOptionId,
        offreId: this.state.selectOffre.value,
        societeId: this.state.selectSociete.value,
        arrayOffreOptionSupp: this.state.arrayOffreOptionSupp,
        arrayOffreOption: this.state.arrayOffreOption,
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push("/Options");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title"> Modification supplémént </h3>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Societe</label>
                      <Select
                        value={this.state.selectSociete}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.modifSociete}
                        options={this.state.societes}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Offres</label>
                      <Select
                        value={this.state.selectOffre}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.modifOffre}
                        options={this.state.offres}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Suppléménts:</label>
                    </div>
                  </div>
                  <table
                    style={{ width: "100%", marginTop: "10px" }}
                    className="table table-bordered"
                  >
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }} scope="col">
                          Supplémént
                      </th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Montant
                      </th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Supp
                      </th>
                      </tr>
                    </thead>
                    {this.state.arrayOffreOption.map((el, index) => (
                      <tbody>
                        <tr>
                          <td
                            style={{ textAlign: "center" }}
                            className="col-md-5"
                          >
                            <input
                              style={{ width: "100%" }}
                              type="text"
                              value={el.options}
                              onChange={(e) => this.updOptions(e, index)}
                            />
                          </td>
                          <td
                            style={{ textAlign: "center" }}
                            className="col-md-5"
                          >
                            <input
                              style={{ width: "100%" }}
                              type="text"
                              value={el.montant}
                              onChange={(e) => this.updMontant(e, index)}
                            />
                          </td>
                          <td
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                          >
                            <button
                              className="buttonSupprimer"
                              onClick={() =>
                                this.toggleOffreOption(index, el.offreOptionId)
                              }
                            >
                              <i
                                style={{ color: "white" }}
                                className="fas fa-trash-alt"
                              ></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))}{" "}
                  </table>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginLeft: "15px",
                      marginTop: '5px'
                    }}
                  >
                    <Button
                      style={{ borderRadius: "5px" }}
                      onClick={this.addLigneOffreOption}
                      color="secondary"
                    >
                      <i className="fas fa-plus-circle"></i>Ajouter ligne
                  </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      style={{ borderRadius: "5px" }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.modif}
                    >
                      Confirmer
                  </button>
                  </div>
                </div>
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.nonDeleteOffreOption}
                  className={this.props.className}
                  fade={false}
                >
                  <ModalHeader toggle={this.nonDeleteOffreOption}>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "465px",
                      }}
                    >
                      <img
                        width="30%"
                        src="./images/deleteModal.png"
                        alt=".."
                      />
                    </div>
                  </ModalHeader>
                  <ModalBody>
                    <div
                      style={{
                        fontFamily: "Montserrat, sans-serif",
                        FontSize: "14px",
                        FontWeight: "700",
                        LineHeight: "18.375px",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
                      </div>
                      <div
                        className="hvr-push"
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginBottom: "15px",
                        }}
                      >
                        <div>
                          <button
                            onClick={() => this.deleteOffreOption()}
                            style={{
                              backgroundColor: "#00FF7F",
                              borderRadius: "5px",
                              color: "white",
                            }}
                          >
                            <i
                              style={{ color: "white" }}
                              className="far fa-thumbs-up"
                            ></i>
                            Oui
                          </button>
                        </div>
                        <div>
                          <button
                            onClick={() => this.nonDeleteOffreOption()}
                            style={{
                              backgroundColor: "#D9534F",
                              borderRadius: "5px",
                              color: "white",
                            }}
                          >
                            <i
                              style={{ color: "white" }}
                              className="far fa-thumbs-down"
                            ></i>
                            Non
                          </button>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ModifOption);
