import React, { Component } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import "./paginate.css";

class ImprBonCom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: "",
            clients: "",
            rs: "",
            rc: "",
            mf: "",
            adresse: "",
            ville: "",
            codePostale: "",
            responsable: "",
            fonction: "",
            tel: "",
            fax: "",
            email: "",
            langue: "",
            offres: "",
            options: [],
            prix: "",
            prixFinal: "",
            tauxRemise: "",
            societes: "",
            totalMontant: "",
            tva: '',
            retenue: '',
            tabModalites: [],
            delais: "",
            pourcentage: [],
            sousTotHT: '',
            mntTva: '',
            totalTtc: '',
            retenueSource: '',
            netPayer: '',
            colorText: '',
            pack: "",
            prixPack: "",
            nbrMois: '',
            nbrCom: "",
            mntCom: '',
            mntTotCom: '',
            prixAvecOpt : ""
        };
    }

    componentDidMount() {
        var url = window.location.href;
        var arrayBonCom = url.split("=");
        var bonComId = arrayBonCom[1];
        //
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + "Back_mtd/GetInfoBonCom.php",
            type: "POST",
            data: {
                bonComId: bonComId,
            },
            dataType: "json",
            success: (code_html, statut) => {
                this.setState({
                    date: code_html.date,
                    clients: code_html.clients,
                    rs: code_html.rs,
                    rc: code_html.rc,
                    mf: code_html.mf,
                    adresse: code_html.adresse,
                    ville: code_html.ville,
                    codePostale: code_html.codePostale,
                    responsable: code_html.responsable,
                    fonction: code_html.fonction,
                    tel: code_html.tel,
                    fax: code_html.fax,
                    email: code_html.email,
                    langue: code_html.langue,
                    offres: code_html.offres,
                    options: code_html.options,
                    prix: code_html.prix,
                    prixFinal: code_html.prixFinal,
                    tauxRemise: code_html.tauxRemise,
                    societes: code_html.societes,
                    tva: code_html.tva,
                    retenue: code_html.retenue,
                    tabModalites: code_html.modalites,
                    delais: code_html.delais,
                    pourcentage: code_html.paiement,
                    pack: code_html.pack,
                    prixPack: code_html.prixPack,
                    nbrMois: code_html.mois,
                    nbrCom: code_html.nbrCom,
                    mntCom: code_html.mntCom,
                    mntTotCom:code_html.mntTotCom
                }, () => {
                    if (this.state.societes.value == 1) {
                        this.setState({
                            colorText: '#ff944d'
                        });
                    } else if (this.state.societes.value == 2) {
                        this.setState({
                            colorText: '#4dffa6'
                        });
                    } else if (this.state.societes.value == 3) {
                        this.setState({
                            colorText: '#33ccff'
                        });
                    } else if (this.state.societes.value == 4) {
                        this.setState({
                            colorText: '#ff8080'
                        });
                    } else if (this.state.societes.value == 5) {
                        this.setState({
                            colorText: '#cccccc'
                        });
                    } else if (this.state.societes.value == 6) {
                        this.setState({
                            colorText: '#ff944d'
                        });
                    }
                    this.setState({
                        totalMontant: this.state.options.map(el => parseFloat(el.montant)).reduce((a, b) => a + b, 0)
                    }, () => {
                        if (this.state.prixFinal == "") {
                            this.setState({
                                prixFinal: 0
                            }, () => {
                                var sousTotHT = parseFloat(this.state.totalMontant) + parseFloat(this.state.prixFinal) + (parseFloat(this.state.prixPack) * this.state.nbrMois) + parseFloat(this.state.mntTotCom)
                                this.setState({
                                    sousTotHT: sousTotHT
                                }, () => {
                                    var mntTva = (this.state.sousTotHT * parseFloat(this.state.tva)) / 100
                                    this.setState({
                                        mntTva: mntTva,
                                        totalTtc: mntTva + this.state.sousTotHT
                                    }, () => {
                                        var retenueSource = (this.state.totalTtc * parseFloat(this.state.retenue)) / 100
                                        this.setState({
                                            retenueSource: retenueSource
                                        }, () => {
                                            var prixAvecOpt = parseFloat(this.state.prixPack) * parseFloat(this.state.nbrMois)
                                            var netPayer = this.state.totalTtc - this.state.retenueSource
                                            this.setState({
                                                netPayer: netPayer.toFixed(2),
                                                prixAvecOpt : prixAvecOpt
                                            });
                                        });
                                    });
                                });
                            });
                        }
                        else {
                            var sousTotHT = parseFloat(this.state.totalMontant) + parseFloat(this.state.prixFinal) + (parseFloat(this.state.prixPack) * this.state.nbrMois) + parseFloat(this.state.mntTotCom)
                            this.setState({
                                sousTotHT: sousTotHT
                            }, () => {
                                var mntTva = (this.state.sousTotHT * parseFloat(this.state.tva)) / 100
                                this.setState({
                                    mntTva: mntTva,
                                    totalTtc: mntTva + this.state.sousTotHT
                                }, () => {
                                    var retenueSource = (this.state.totalTtc * parseFloat(this.state.retenue)) / 100
                                    this.setState({
                                        retenueSource: retenueSource
                                    }, () => {
                                        var netPayer = this.state.totalTtc - this.state.retenueSource
                                        this.setState({
                                            netPayer: netPayer.toFixed(2)
                                        });
                                    });
                                });
                            });
                        }
                    });
                });
            },
        });
    }

    print = () => {
        window.print();
    };

    render() {
        return (
            <div style={{ width: '96%', marginLeft: '8%', fontFamily: 'cursive', color: 'black', marginTop: '1%' }}>
                <h1 style={{ fontWeight: 'bold', textAlign: 'center' }}>Bon de commande</h1>
                <div style={{ display: 'flex' }}>
                    <div style={{ paddingLeft: '5px', marginTop: '20px', border: 'solid 1px', width: '70%', fontWeight: 'bold', backgroundColor: this.state.colorText, fontSize: '25px' }}>
                        1. CLIENT
                </div>
                    <div style={{ paddingLeft: '5px', marginTop: '20px', border: 'solid 1px', width: '20%', fontSize: '20px' }}>
                        Numéro
                </div>
                </div>
                <div style={{ display: 'flex', marginTop: '-20px' }}>
                    <div style={{ paddingLeft: '5px', marginTop: '20px', border: 'solid 1px', width: '70%', fontSize: '20px' }}>
                        Raison sociale
                        <p style={{ fontSize: '19px' }}>{this.state.rs}</p>
                    </div>
                    <div style={{ paddingLeft: '5px', marginTop: '20px', border: 'solid 1px', width: '20%', fontSize: '20px' }}>
                        Date
                        <p style={{ fontSize: '19px' }}>{this.state.date}</p>
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: '-20px' }}>
                    <div style={{ paddingLeft: '5px', marginTop: '20px', border: 'solid 1px', width: '35%', fontSize: '20px' }}>
                        Registre de commerce
                        <p style={{ fontSize: '19px' }}>{this.state.rc}</p>
                    </div>
                    <div style={{ paddingLeft: '5px', marginTop: '20px', border: 'solid 1px', width: '55%', fontSize: '20px' }}>
                        Matricule Fiscale
                        <p style={{ fontSize: '19px' }}>{this.state.mf}</p>
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: '-20px' }}>
                    <div style={{ paddingLeft: '5px', paddingLeft: '5px', marginTop: '20px', border: 'solid 1px', width: '45%', fontSize: '20px' }}>
                        Adresse principale de l'entreprise
                        <p style={{ fontSize: '19px' }}>{this.state.adresse}</p>
                    </div>
                    <div style={{ paddingLeft: '5px', marginTop: '20px', border: 'solid 1px', width: '25%', fontSize: '20px' }}>
                        Ville
                        <p style={{ fontSize: '19px' }}>{this.state.ville}</p>
                    </div>
                    <div style={{ paddingLeft: '5px', marginTop: '20px', border: 'solid 1px', width: '20%', fontSize: '20px' }}>
                        Code postale
                        <p style={{ fontSize: '19px' }}>{this.state.codePostale}</p>
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: '-20px' }}>
                    <div style={{ paddingLeft: '5px', marginTop: '20px', border: 'solid 1px', width: '55%', fontSize: '20px' }}>
                        Responsable
                        <p style={{ fontSize: '19px' }}>{this.state.responsable}</p>
                    </div>
                    <div style={{ paddingLeft: '5px', marginTop: '20px', border: 'solid 1px', width: '35%', fontSize: '20px' }}>
                        Fonction
                        <p style={{ fontSize: '19px' }}>{this.state.fonction}</p>
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: '-20px' }}>
                    <div style={{ paddingLeft: '5px', marginTop: '20px', border: 'solid 1px', width: '22%', fontSize: '20px' }}>
                        Téléphone
                        <p style={{ fontSize: '19px' }}>{this.state.tel}</p>
                    </div>
                    <div style={{ paddingLeft: '5px', marginTop: '20px', border: 'solid 1px', width: '25%', fontSize: '20px' }}>
                        Fax
                        <p style={{ fontSize: '19px' }}>{this.state.fax}</p>
                    </div>
                    <div style={{ paddingLeft: '5px', marginTop: '20px', border: 'solid 1px', width: '43%', fontSize: '20px' }}>
                        Email
                        <p style={{ fontSize: '19px' }}>{this.state.email}</p>
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: '-20px', fontSize: '20px' }}>
                    <div style={{ marginTop: '20px', border: 'solid 1px', width: '90%', display: 'flex' }}>
                        <p style={{ paddingLeft: '5px', marginRight: '5px', fontSize: '19px' }}>Langue de correspondance : </p>
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            {this.state.langue == 2 ? (<div style={{ paddingRight: '10px' }}>
                                <label style={{ paddingRight: '5px' }}>Arabe</label>
                                <input type="radio" defaultChecked />
                            </div>) : (<div style={{ paddingRight: '10px' }}>
                                <label style={{ paddingRight: '5px' }}>Arabe</label>
                                <input type="radio" />
                            </div>)}
                            {this.state.langue == 1 ? (<div style={{ paddingRight: '10px' }}>
                                <label style={{ paddingRight: '5px' }}>Francais</label>
                                <input type="radio" defaultChecked />
                            </div>) : (<div style={{ paddingRight: '10px' }}>
                                <label style={{ paddingRight: '5px' }}>Francais</label>
                                <input type="radio" />
                            </div>)}
                            {this.state.langue == 3 ? (<div style={{ paddingRight: '10px' }}>
                                <label style={{ paddingRight: '5px' }}>Anglais</label>
                                <input type="radio" defaultChecked />
                            </div>) : (<div style={{ paddingRight: '10px' }}>
                                <label style={{ paddingRight: '5px' }}>Anglais</label>
                                <input type="radio" />
                            </div>)}
                        </div>
                    </div>
                </div>
                <div style={{ border: 'solid 1px', width: '90%' }}>
                    <p style={{ paddingLeft: '5px', fontWeight: 'bold', backgroundColor: this.state.colorText, border: 'solid 1px', fontSize: '25px' }}>2. SERVICES</p>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', width: '100%', marginBottom: '25px' }}>
                        <table style={{ border: 'solid 1px', width: '95%' }}>
                            <tr style={{ backgroundColor: this.state.colorText }}>
                                <th style={{ diplay: 'none', backgroundColor: 'white', borderTopStyle: 'hidden', borderLeftStyle: 'hidden', width: '5%' }}></th>
                                <th style={{ border: 'solid 1px', width: '50%', textAlign: 'center', fontSize: '22px' }}>Désignation</th>
                                <th style={{ border: 'solid 1px', width: '12.5%', textAlign: 'center', fontSize: '22px' }}>Quantité</th>
                                <th style={{ border: 'solid 1px', width: '17.5%', textAlign: 'center', fontSize: '22px' }}>Prix unitaire</th>
                                <th style={{ border: 'solid 1px', width: '15%', textAlign: 'center', fontSize: '22px' }}>Montant</th>
                            </tr>
                            <tr>
                                <td style={{ border: 'solid 1px', width: '5%', fontSize: '20px' }}>1</td>
                                {this.state.prixPack == 0 ? (<td style={{ border: 'solid 1px', width: '50%', fontSize: '20px' }}>
                                    {this.state.offres.label}
                                </td>) : (<td style={{ border: 'solid 1px', width: '50%', fontSize: '20px' }}>
                                    {this.state.offres.label} ({this.state.pack})
                                </td>)}
                                {this.state.prixPack == 0 ? (<td style={{ border: 'solid 1px', width: '12.5%', fontSize: '20px' }}>1</td>
                                ) : (<td style={{ border: 'solid 1px', width: '12.5%', fontSize: '20px' }}>{this.state.nbrMois}</td>
                                )}
                                {this.state.prixPack == 0 ? (<td style={{ border: 'solid 1px', width: '17.5%', fontSize: '20px' }}>{this.state.prix}</td>
                                ) : (<td style={{ border: 'solid 1px', width: '17.5%', fontSize: '20px' }}>{this.state.prixPack}</td>
                                )}
                                {this.state.prixPack == 0 ? (<td style={{ border: 'solid 1px', width: '15%', fontSize: '20px' }}>{this.state.prix}</td>
                                ) : (<td style={{ border: 'solid 1px', width: '15%', fontSize: '20px' }}>{this.state.prixAvecOpt}</td>
                                )}
                            </tr>
                            {this.state.offres.value == 7 ? (
                                <tr>
                                    <td style={{ border: 'solid 1px', width: '5%', fontSize: '20px' }}> 2 </td>
                                    <td style={{ border: 'solid 1px', width: '50%', fontSize: '20px' }}>Commercial</td>
                                    <td style={{ border: 'solid 1px', width: '12.5%', fontSize: '20px' }}>{this.state.nbrCom}</td>
                                    <td style={{ border: 'solid 1px', width: '17.5%', fontSize: '20px' }}>{this.state.mntCom}</td>
                                    <td style={{ border: 'solid 1px', width: '15%', fontSize: '20px' }}>{this.state.mntTotCom}</td>
                                </tr>
                            ) : (this.state.options.map((el, index) =>
                                <tr>
                                    <td style={{ border: 'solid 1px', width: '5%', fontSize: '20px' }}> {index + 2} </td>
                                    <td style={{ border: 'solid 1px', width: '50%', fontSize: '20px' }}>{el.options}</td>
                                    <td style={{ border: 'solid 1px', width: '12.5%', fontSize: '20px' }}>1</td>
                                    <td style={{ border: 'solid 1px', width: '17.5%', fontSize: '20px' }}>{el.montant}</td>
                                    <td style={{ border: 'solid 1px', width: '15%', fontSize: '20px' }}>{el.montant}</td>
                                </tr>
                            ))}

                            <tr>
                                <td style={{ borderBottomStyle: 'hidden', borderLeftStyle: 'hidden', width: '5%', fontSize: '20px' }}></td>
                                <td style={{ borderBottomStyle: 'hidden', borderLeftStyle: 'hidden', width: '50%', fontSize: '20px' }}></td>
                                <td style={{ borderBottomStyle: 'hidden', borderLeftStyle: 'hidden', width: '12.5%', fontSize: '20px' }}></td>
                                <td style={{ border: 'solid 1px', width: '17.5%', fontWeight: 'bold', fontSize: '20px' }}>REMISE</td>
                                <td style={{ border: 'solid 1px', width: '15%', fontSize: '20px' }}>-{this.state.tauxRemise} %</td>
                            </tr>
                            <tr>
                                <td style={{ borderBottomStyle: 'hidden', borderLeftStyle: 'hidden', width: '5%', fontSize: '20px' }}></td>
                                <td style={{ borderBottomStyle: 'hidden', borderLeftStyle: 'hidden', width: '50%', fontSize: '20px' }}></td>
                                <td style={{ borderBottomStyle: 'hidden', borderLeftStyle: 'hidden', width: '12.5%', fontSize: '20px' }}></td>
                                <td style={{ border: 'solid 1px', width: '17.5%', fontWeight: 'bold', backgroundColor: this.state.colorText, fontSize: '20px' }}>SOUS TOTAL HT</td>
                                <td style={{ border: 'solid 1px', width: '15%', backgroundColor: this.state.colorText, fontSize: '20px' }}>{this.state.sousTotHT}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div style={{ border: 'solid 1px', width: '90%' }}>
                    <p style={{ paddingLeft: '5px', fontWeight: 'bold', backgroundColor: this.state.colorText, border: 'solid 1px', fontSize: '25px' }}>3. MODALITÉS DE PAIEMENT</p>
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-around', width: '55%' }}>
                            {this.state.tabModalites.map(el =>
                                el.etat == 1 ? (<div>
                                    <input type="checkbox" checked />
                                    <label style={{ fontSize: '22px' }}>{el.modalite}</label>
                                </div>) : (<div>
                                    <input type="checkbox" />
                                    <label style={{ fontSize: '22px' }}>{el.modalite}</label>
                                </div>)
                            )}
                        </div>
                        <div style={{ width: '45%', paddingTop: '20px' }}>
                            <table style={{ border: 'solid 1px', width: '95%' }}>
                                <tr>
                                    <td style={{ border: 'solid 1px', width: '50%', fontWeight: 'bold', fontSize: '20px' }}>TVA ({this.state.tva})</td>
                                    <td style={{ border: 'solid 1px', width: '50%', fontSize: '20px' }}>{this.state.mntTva}</td>
                                </tr>
                                <tr>
                                    <td style={{ border: 'solid 1px', width: '50%', fontWeight: 'bold', fontSize: '20px' }}>TOTAL TTC</td>
                                    <td style={{ border: 'solid 1px', width: '50%', fontSize: '20px' }}>{this.state.totalTtc}</td>
                                </tr>
                                <tr>
                                    <td style={{ border: 'solid 1px', width: '50%', fontWeight: 'bold', fontSize: '20px' }}>RETENUE A LA SOURCE ({this.state.retenue})</td>
                                    <td style={{ border: 'solid 1px', width: '50%', fontSize: '20px' }}>{this.state.retenueSource}</td>
                                </tr>
                                <tr>
                                    <td style={{ borderBottomStyle: 'hidden', borderLeftStyle: 'hidden', width: '50%', fontWeight: 'bold', fontSize: '20px' }}>NET À PAYER</td>
                                    <td style={{ border: 'solid 1px', width: '50%', backgroundColor: this.state.colorText, fontSize: '20px' }}>{this.state.netPayer}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div style={{ width: '80%', marginBottom: '20px', marginTop: '10px', paddingLeft: '30px' }}>
                        <table style={{ border: 'solid 1px', width: '95%' }}>
                            {this.state.pourcentage.map((el, index) =>
                                <tr>
                                    <td style={{ border: 'solid 1px', width: '5%', fontSize: '20px' }}>{index + 1}</td>
                                    <td style={{ border: 'solid 1px', width: '10%', fontSize: '20px' }}>{el.pourcentage}%</td>
                                    <td style={{ border: 'solid 1px', width: '35%', fontSize: '20px' }}>{el.motif}</td>
                                </tr>
                            )}
                        </table>
                    </div>
                </div>
                <div style={{ border: 'solid 1px', width: '90%' }}>
                    <table style={{ width: '100%' }}>
                        <tr>
                            <th style={{ paddingLeft: '5px', fontWeight: 'bold', backgroundColor: this.state.colorText, border: 'solid 1px', width: '50%', fontSize: '25px' }}>4. DÉLAIS</th>
                            <th style={{ paddingLeft: '5px', fontWeight: 'bold', backgroundColor: this.state.colorText, border: 'solid 1px', width: '50%', fontSize: '25px' }}>5. REMARQUES </th>
                        </tr>
                        <tr>
                            <td style={{ border: 'solid 1px' }}><div style={{ marginBottom: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
                                {/* <table style={{ border: 'solid 1px', width: '100%', marginTop: '10px' }}>
                                    <tr>
                                        <td style={{ border: 'solid 1px', width: '30%', fontSize: '20px' }}>1</td>
                                        <td style={{ border: 'solid 1px', width: '70%', fontSize: '20px' }}>{this.state.delais}</td>
                                    </tr>
                                </table> */}
                                <p>{this.state.delais}</p>
                            </div></td>
                            <td style={{ border: 'solid 1px' }}><textarea rows="2" cols="60">
                            </textarea></td>
                        </tr>
                    </table>
                </div>
                <div style={{ border: 'solid 1px', width: '90%' }}>
                    <p style={{ paddingLeft: '5px', fontSize: '20px' }}>Les informations générales sur la commande font partie intégrante du présent contrat.je soussigné(e) déclare avoir lu les présentes et être autorisé á en accepter les termes.</p>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <div style={{ width: '40%', border: 'solid 1px' }}>
                            <div style={{ border: 'solid 1px', fontWeight: 'bold', backgroundColor: this.state.colorText, paddingLeft: '5px', fontSize: '20px' }}>Représentant(e) de {this.state.societes.label}</div>
                            <p style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px' }}>
                            </p>
                        </div>
                        <div style={{ width: '40%', border: 'solid 1px' }}>
                            <div style={{ border: 'solid 1px', fontWeight: 'bold', backgroundColor: this.state.colorText, paddingLeft: '5px', fontSize: '20px' }}>Client</div>
                            <p style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px' }}>
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                    }}
                >
                    <i id="print_me_not"
                        style={{ fontSize: "30px", cursor: "pointer" }}
                        onClick={this.print}
                        class="fas fa-print"
                    ></i>
                </div>
            </div>
        );
    }
}

export default withRouter(ImprBonCom);
