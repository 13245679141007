import React, { Component } from "react";
import axios from "axios";
import jQuery from "jquery";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import Select from 'react-select';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

class ModifOffreRubrique extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offreId: '',
            offre: [],
            selectedOffre: '',
            rubrique: [],
            selectedRubrique: []
        };
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
            var offreId = this.props.location.state.offreId;
            axios
                .get(
                    process.env.REACT_APP_API_URL +
                    "Back_mtd/GetOffreRubriqueById.php?id=" +
                    offreId
                )
                .then((res) => {
                    console.log(res);
                    this.setState({
                        offreId: offreId,
                        selectedOffre: res.data.offre,
                        selectedRubrique: res.data.rubrique
                    });
                });
            axios.get(process.env.REACT_APP_API_URL + 'Back_mtd/SelectOffre.php').then(res => {
                console.log(res)
                this.setState({
                    offre: res.data
                })
            })
            axios.get(process.env.REACT_APP_API_URL + 'Back_mtd/SelectRubrique.php').then(res => {
                console.log(res)
                this.setState({
                    rubrique: res.data
                })
            })
        }
    }

    modifOffre = selectedOffre => {
        this.setState({ selectedOffre: selectedOffre });
    };

    modifRubrique = selectedRubrique => {
        this.setState({ selectedRubrique: selectedRubrique });
    }

    modif = () => {
        if (this.state.selectedOffre != '' && this.state.selectedRubrique.length > 0) {
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mtd/UpdOffreRubrique.php?id=' +
                    this.state.offreId,
                type: 'POST',
                data: {
                    offreId: this.state.selectedOffre.value,
                    arrayRubrique: this.state.selectedRubrique
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    if (code_html.Result == "OK") {
                        this.props.history.push('/OffreRubrique');
                    }
                    else if (code_html.Result == "KO") {
                        alert("erreur,l'un de vos champs est vide!!");
                    }
                }
            });
        }
        else {
            toast.error('⛔ Veuillez verifier les champs offre et rubrique  !!', { containerId: 'A' });
        }
    };

    render() {
        return (
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="page-header">
                        <h3 className="page-title"> Modification offre-rubrique </h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 grid-margin">
                            <div className="card">
                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Offre</label>
                                            <Select
                                                value={this.state.selectedOffre}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.modifOffre}
                                                options={this.state.offre}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Rubrique</label>
                                            <Select
                                                value={this.state.selectedRubrique}
                                                isMulti={true}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.modifRubrique}
                                                options={this.state.rubrique}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <button
                                            style={{ borderRadius: "5px" }}
                                            type="button"
                                            className="btn btn-success"
                                            onClick={this.modif}
                                        >
                                            Confirmer
                  </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ModifOffreRubrique);
