import React, { Component } from "react";
import axios from "axios";
import jQuery from "jquery";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import Select from "react-select";

class ModifClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: "",
      nom: "",
      adresse: "",
      tel: "",
      mf: "",
      villes: [],
      selectedVille: "",
      responsable: "",
      qualite: "",
      assujetti: [],
      selectedAssujetti: "",
      rs: '',
      rc: '',
      codePostal: '',
      fax: '',
      email: ''
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      let clientId = this.props.location.state.clientId;
      axios
        .get(
          process.env.REACT_APP_API_URL +
          "Back_mtd/GetClientById.php?id=" +
          clientId
        )
        .then((res) => {
          console.log(res);
          this.setState({
            clientId: res.data.clientId,
            nom: res.data.nom,
            email: res.data.email,
            adresse: res.data.adresse,
            tel: res.data.tel,
            mf: res.data.mf,
            selectedVille: res.data.ville,
            responsable: res.data.responsable,
            qualite: res.data.qualite,
            selectedAssujetti: res.data.assujetti,
            rs: res.data.rs,
            rc: res.data.rc,
            codePostal: res.data.codePostal,
            fax: res.data.fax
          });
        });
      axios
        .get(process.env.REACT_APP_API_URL + "Back_mtd/GetVilles.php")
        .then((res) => {
          console.log(res);
          this.setState({
            villes: res.data,
          });
        });
      axios
        .get(process.env.REACT_APP_API_URL + "Back_mtd/GetAssujetti.php")
        .then((res) => {
          console.log(res);
          this.setState({
            assujetti: res.data,
          });
        });
    }
  }

  modifNom = (event) => {
    this.setState({
      nom: event.target.value,
    });
  };

  modifAdresse = (event) => {
    this.setState({
      adresse: event.target.value,
    });
  };

  modifTel = (event) => {
    this.setState({
      tel: event.target.value,
    });
  };

  modifMf = (event) => {
    this.setState({
      mf: event.target.value,
    });
  };

  modifVille = (selectedVille) => {
    this.setState({ selectedVille: selectedVille });
  };

  modifResponsable = (event) => {
    this.setState({
      responsable: event.target.value,
    });
  };

  modifQualite = (event) => {
    this.setState({
      qualite: event.target.value,
    });
  };

  modifAssujetti = (selectedAssujetti) => {
    this.setState({ selectedAssujetti: selectedAssujetti });
  };

  modifRs = (event) => {
    this.setState({
      rs: event.target.value,
    });
  };

  modifRc = (event) => {
    this.setState({
      rc: event.target.value,
    });
  };

  modifCodePostal = (event) => {
    this.setState({
      codePostal: event.target.value,
    });
  };

  modifFax = (event) => {
    this.setState({
      fax: event.target.value,
    });
  };

  modifEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  modif = () => {
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mtd/UpdClient.php?id=" +
        this.state.clientId,
      type: "POST",
      data: {
        nom: this.state.nom,
        adresse: this.state.adresse,
        tel: this.state.tel,
        mf: this.state.mf,
        ville: this.state.selectedVille.value,
        responsable: this.state.responsable,
        qualite: this.state.qualite,
        assujetti: this.state.selectedAssujetti.value,
        rs: this.state.rs,
        rc: this.state.rc,
        codePostal: this.state.codePostal,
        fax: this.state.fax,
        email: this.state.email
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push("/Clients");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title"> Modification Client </h3>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Nom</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.nom}
                        onChange={this.modifNom}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Ville</label>
                      <Select
                        value={this.state.selectedVille}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.modifVille}
                        options={this.state.villes}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Adresse</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.adresse}
                        onChange={this.modifAdresse}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Code postal</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Code postal"
                        value={this.state.codePostal}
                        onChange={this.modifCodePostal}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.email}
                        onChange={this.modifEmail}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Tel</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.tel}
                        onChange={this.modifTel}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Fax</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Fax"
                        value={this.state.fax}
                        onChange={this.modifFax}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Matricule fiscale</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.mf}
                        onChange={this.modifMf}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Raison social</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Raison social"
                        value={this.state.rs}
                        onChange={this.modifRs}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Registre de commerce</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Registre de commerce"
                        value={this.state.rc}
                        onChange={this.modifRc}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Responsable</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.responsable}
                        onChange={this.modifResponsable}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Qualité</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.qualite}
                        onChange={this.modifQualite}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Assujetti</label>
                      <Select
                        value={this.state.selectedAssujetti}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.modifAssujetti}
                        options={this.state.assujetti}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      style={{ borderRadius: "5px" }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.modif}
                    >
                      Confirmer
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ModifClient);
