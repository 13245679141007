import React, { Component } from "react";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import jQuery from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      userPasse: "",
    };
  }

  changeusername = (event) => {
    this.setState({
      userName: event.target.value,
    });
  };

  changeuserpasse = (event) => {
    this.setState({
      userPasse: event.target.value,
    });
  };

  connect = () => {
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL + "Back_mtd/ConnectUtilisateur.php",
      type: "POST",
      data: {
        userName: this.state.userName,
        userPasse: this.state.userPasse,
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.connexion === "OK") {
          localStorage.setItem("userPermissions", JSON.stringify(code_html));
          toast.success("✅ Bienvenue dans SED !", { containerId: "A" });
          document.location = "/Societes";
        } else if (code_html.connexion === "KO") {
          toast.error("⛔ Veuillez vérifier votre login et mot de passe !", {
            containerId: "A",
          });
        }
      },
    });
  };

  render() {
    return (
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth">
            <div className="row flex-grow">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left p-5">
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '0px' }} className="brand-logo">
                    <img src="../../assets/images/mtd.png" />
                  </div>
                  <form className="pt-3">
                    <div className="form-group">
                      <input type="email" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Login" onChange={this.changeusername} />
                    </div>
                    <div className="form-group">
                      <input type="password" className="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password" onChange={this.changeuserpasse} />
                    </div>
                    <div className="mt-3">
                      <p className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" onClick={this.connect}>Connexion</p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* content-wrapper ends */}
        </div>
        {/* page-body-wrapper ends */}
      </div>
    );
  }
}
export default Login;
