import React, { Component } from "react";
import axios from "axios";
import jQuery from "jquery";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import { RadioGroup, Radio } from "react-radio-group";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class AjoutContratOffre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      societes: [],
      selectSociete: "",
      offres: [],
      selectOffre: "",
      clients: [],
      selectClient: "",
      selectedOption: "0",
      options: [],
      remiseMax: "",
      tauxRemise: "",
      prix: "",
      prixFinal: "",
      fraisRef: "",
      fraisRes: "",
      selectedOpt: [],
      opt: [],
      rubrique: [],
      selectRubrique: [],
      // packGold: "",
      // packSilver: "",
      // packPlatinum: "",
      pack: [],
      arrayPack: [],
      dateOffre: new Date(),
      modal: false,
      lignePackSupp: '',
      prixPack: ''
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mtd/GetSocietes.php",
        type: "POST",
        data: {},
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            societes: code_html,
          });
        },
      });
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mtd/GetClients.php",
        type: "POST",
        data: {},
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            clients: code_html,
          });
        },
      });
      axios
        .get(process.env.REACT_APP_API_URL + "Back_mtd/GetTauxRemise.php")
        .then((res) => {
          console.log(res);
          this.setState({
            remiseMax: res.data,
          });
        });
      axios
        .get(process.env.REACT_APP_API_URL + "Back_mtd/GetFraisRes.php")
        .then((res) => {
          console.log(res);
          this.setState({
            fraisRes: res.data,
          });
        });
      axios
        .get(process.env.REACT_APP_API_URL + "Back_mtd/GetFraisRef.php")
        .then((res) => {
          console.log(res);
          this.setState({
            fraisRef: res.data,
          });
        });
      // jQuery.ajax({
      //   url: process.env.REACT_APP_API_URL + "Back_mtd/GetPack.php",
      //   type: "POST",
      //   data: {},
      //   dataType: "json",
      //   success: (code_html, statut) => {
      //     this.setState({
      //       packSilver: code_html[0],
      //       packGold: code_html[1],
      //       packPlatinum: code_html[2],
      //     });
      //   },
      // });
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mtd/SelectPack.php",
        type: "POST",
        data: {},
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            pack: code_html
          });
        },
      });
    }
  }

  addDateOffre = d => {
    this.setState({
      dateOffre: d
    });
  };

  addSociete = (selectSociete) => {
    if (selectSociete != null) {
      this.setState({ selectSociete: selectSociete }, () => {
        jQuery.ajax({
          url: process.env.REACT_APP_API_URL + "Back_mtd/GetOffresBySociete.php",
          type: "POST",
          data: {
            societeId: selectSociete.value,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              offres: code_html,
            });
          },
        });
      });
    }
    else {
      this.setState({
        selectSociete: '',
        offres: []
      });
    }
  };

  addOffre = (selectOffre) => {
    if (selectOffre != null) {
      this.setState({ selectOffre: selectOffre }, () => {
        jQuery.ajax({
          url: process.env.REACT_APP_API_URL + "Back_mtd/GetOptionByOffreId.php",
          type: "POST",
          data: {
            offreId: selectOffre.value,
            societeId: this.state.selectSociete.value,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              opt: code_html,
            });
          },
        });
        jQuery.ajax({
          url: process.env.REACT_APP_API_URL + "Back_mtd/GetRubrique.php",
          type: "POST",
          data: {
            offreId: selectOffre.value,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              rubrique: code_html,
              selectRubrique: code_html,
            });
          },
        });
      });
    }
    else {
      this.setState({
        selectOffre: '',
        opt: [],
        rubrique: [],
        selectRubrique: []
      });
    }
  };

  addClient = (selectClient) => {
    this.setState({ selectClient: selectClient });
  };

  addOption = (selectedOption) => {
    this.setState({ selectedOption: selectedOption });
  };

  addOpt = (selectedOpt) => {
    this.setState({ selectedOpt: selectedOpt });
  };

  addRubrique = (selectRubrique) => {
    this.setState({ selectRubrique: selectRubrique });
  };

  addPrix = (event) => {
    var prix = event.target.value;
    this.setState({
      prix: prix,
      prixFinal: prix,
    }, () => {
      if (prix != "" && this.state.tauxRemise != "") {
        var prixFinal =
          parseFloat(prix) -
          (parseFloat(prix) * parseFloat(this.state.tauxRemise)) / 100;
        this.setState({
          prixFinal: prixFinal,
        });
      }
    });
  };

  // addPackSilver = (event) => {
  //   this.setState({
  //     packSilver: event.target.value,
  //   });
  // };
  // addPackGold = (event) => {
  //   this.setState({
  //     packGold: event.target.value,
  //   });
  // };
  // addPackPlatinum = (event) => {
  //   this.setState({
  //     packPlatinum: event.target.value,
  //   });
  // };

  addPack = (event, index) => {
    var packChoisi = event;
    // Recuperer prix pack
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mtd/GetPrixPack.php",
      type: "POST",
      data: {
        packId: packChoisi
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState({
          arrayPack: this.state.arrayPack.map((el, id) =>
            id === index ? Object.assign(el, {
              pack: packChoisi,
              prix: code_html
            }) : el
          ),
        });
      }
    });
  };

  addPrixPack = (event, index) => {
    var prixChoisi = event.target.value;
    this.setState({
      arrayPack: this.state.arrayPack.map((el, id) =>
        id === index ? Object.assign(el, { prix: prixChoisi }) : el
      ),
    });
  };

  addLignePack = () => {
    var element = { pack: "", prix: "" };
    this.setState({
      arrayPack: [...this.state.arrayPack, element],
    });
  };

  togglePack = (index) => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      lignePackSupp: index,
    }));
  };

  deletePack = () => {
    this.state.arrayPack.splice(this.state.lignePackSupp, 1);
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  nonDeletePack = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  addTauxRemise = (event) => {
    var tauxRemise = parseFloat(event.target.value);
    if (isNaN(tauxRemise)) {
      this.setState({
        prixFinal: this.state.prix,
        tauxRemise: ""
      });
    }
    else {
      if (tauxRemise <= this.state.remiseMax && tauxRemise > 0) {
        if (tauxRemise != "" && this.state.prix != "") {
          var prixFinal =
            parseFloat(this.state.prix) -
            (parseFloat(this.state.prix) * parseFloat(tauxRemise)) / 100;
          this.setState({
            tauxRemise: tauxRemise,
            prixFinal: prixFinal,
          });
        }
        else {
          this.setState({
            tauxRemise: tauxRemise
          });
          toast.error("⛔ Veuillez saisir le prix !", {
            containerId: "A",
          });
        }
      } else {
        toast.error("⛔ Verifier le taux de remise !", {
          containerId: "A",
        });
      }
    }
  };

  ajout = () => {
    // convert date paiement
    let dateOffre = this.state.dateOffre
    let month = '' + (dateOffre.getMonth() + 1);
    let day = '' + dateOffre.getDate();
    let year = dateOffre.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    var convertDate = [year, month, day].join('-');
    //
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mtd/AjoutOffreContrat.php",
      type: "POST",
      data: {
        societeId: this.state.selectSociete.value,
        offreId: this.state.selectOffre.value,
        clientId: this.state.selectClient.value,
        options: this.state.selectedOpt,
        rubrique: this.state.selectRubrique,
        prix: this.state.prix,
        remise: this.state.tauxRemise,
        prixFinal: this.state.prixFinal,
        fraisRes: this.state.fraisRes,
        fraisRef: this.state.fraisRef,
        // packPlatinum: this.state.packPlatinum,
        // packGold: this.state.packGold,
        // packSilver: this.state.packSilver,
        arrayPack: this.state.arrayPack,
        dateOffre: convertDate
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          //
          var offreContratId = code_html.offreContratId
          window.open(
            "https://commercial.mtd-app.com/ImprContratOffre?idOffreContrat=" + offreContratId
          )
          //
          this.props.history.push("/ContratOffre");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title"> Ajout offre de prix </h3>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Date offre</label>
                      <DatePicker class="form-control ddate" style={{ border: 'none' }}
                        selected={this.state.dateOffre}
                        onChange={this.addDateOffre}
                        dateFormat="dd/MM/yyy"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Societe</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addSociete}
                        options={this.state.societes}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Offre</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addOffre}
                        options={this.state.offres}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Client</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addClient}
                        options={this.state.clients}
                      />
                    </div>
                  </div>
                  {this.state.selectOffre.value == 6 ? (
                    <div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Supplémént</label>
                          <RadioGroup
                            name="Supplémentaire"
                            selectedValue={this.state.selectedOption}
                            onChange={this.addOption}
                          >
                            <label style={{ paddingRight: "5px" }}>
                              <Radio value="0" />
                              Sans Supplémént
                            </label>
                            <label style={{ paddingRight: "5px" }}>
                              <Radio value="1" />
                              Avec Supplémént
                            </label>
                          </RadioGroup>
                        </div>
                      </div>
                      {this.state.selectedOption == "1" ? (
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Supplémént</label>
                            <Select
                              isMulti={true}
                              isClearable={true}
                              isSearchable={true}
                              onChange={this.addOpt}
                              options={this.state.opt}
                            />
                          </div>
                        </div>
                      ) : null}
                      <table
                        style={{ width: "100%", marginTop: "10px" }}
                        className="table table-bordered"
                      >
                        <thead>
                          <tr>
                            <th
                              className="col-md-5"
                              style={{ textAlign: "center" }}
                              scope="col"
                            >
                              Pack
                            </th>
                            <th
                              className="col-md-5"
                              style={{ textAlign: "center" }}
                              scope="col"
                            >
                              Prix pack
                            </th>
                            <th
                              className="col-md-2"
                              style={{ textAlign: "center" }}
                              scope="col"
                            >
                              Supp
                            </th>
                          </tr>
                        </thead>
                        {this.state.arrayPack.map((el, index) => (
                          <tbody>
                            <tr>
                              <td
                                style={{ textAlign: "center" }}
                                className="col-md-5"
                              >
                                <Select
                                  isClearable={true}
                                  isSearchable={true}
                                  onChange={(e) => this.addPack(e, index)}
                                  options={this.state.pack}
                                />
                              </td>
                              <td
                                style={{ textAlign: "center" }}
                                className="col-md-5"
                              >
                                <input style={{ width: '100%', textAlign: 'center' }}
                                  value={el.prix}
                                  type="text"
                                  onChange={(e) => this.addPrixPack(e, index)}
                                />
                              </td>
                              <td
                                className="col-md-2"
                                style={{ textAlign: "center" }}
                              >
                                <button
                                  className="buttonSupprimer"
                                  onClick={() => this.togglePack(index)}
                                >
                                  <i
                                    style={{ color: "white" }}
                                    className="fas fa-trash-alt"
                                  ></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginLeft: "15px",
                          marginTop: "5px",
                        }}
                      >
                        <Button
                          style={{ borderRadius: "5px" }}
                          onClick={this.addLignePack}
                          color="secondary"
                        >
                          <i className="fas fa-plus-circle"></i>Ajouter ligne
                        </Button>
                      </div>
                      {/* <div className="col-md-12"> */}
                      {/* <div className="form-group">
                      <label>Pack Silver</label>
                      <input
                        type="text"
                        value={this.state.packSilver}
                        className="form-control"
                        placeholder="pack silver"
                        onChange={this.addPackSilver}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Pack Gold</label>
                        <input
                          type="text"
                          value={this.state.packGold}
                          className="form-control"
                          placeholder="pack gold"
                          onChange={this.addPackGold}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Pack Platinum</label>
                        <input
                          type="text"
                          value={this.state.packPlatinum}
                          className="form-control"
                          placeholder="pack platinum"
                          onChange={this.addPackPlatinum}
                          style={{
                            borderRadius: "5px",
                            border: "solid 1px #B3B3B3",
                          }}
                        />
                      </div> */}
                      {/* </div> */}
                    </div>) : (<div><div className="col-md-12">
                      <div className="form-group">
                        <label>Supplémént</label>
                        <RadioGroup
                          name="Supplémentaire"
                          selectedValue={this.state.selectedOption}
                          onChange={this.addOption}
                        >
                          <label style={{ paddingRight: "5px" }}>
                            <Radio value="0" />
                            Sans Supplémént
                          </label>
                          <label style={{ paddingRight: "5px" }}>
                            <Radio value="1" />
                            Avec Supplémént
                          </label>
                        </RadioGroup>
                      </div>
                    </div>
                      {this.state.selectedOption == "1" ? (
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Supplémént</label>
                            <Select
                              isMulti={true}
                              isClearable={true}
                              isSearchable={true}
                              onChange={this.addOpt}
                              options={this.state.opt}
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Rubrique</label>
                          <Select
                            value={this.state.selectRubrique}
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            onChange={this.addRubrique}
                            options={this.state.rubrique}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Prix</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="prix"
                            onChange={this.addPrix}
                            style={{
                              borderRadius: "5px",
                              border: "solid 1px #B3B3B3",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Remise</label>
                          <input
                            type="Number"
                            min="0"
                            max="10"
                            className="form-control"
                            placeholder="remise"
                            onChange={this.addTauxRemise}
                            style={{
                              borderRadius: "5px",
                              border: "solid 1px #B3B3B3",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Prix Final</label>
                          <input
                            value={this.state.prixFinal}
                            disabled
                            type="text"
                            className="form-control"
                            placeholder="prix final"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Frais Réservation</label>
                          <input
                            value={this.state.fraisRes}
                            type="text"
                            disabled
                            className="form-control"
                            placeholder="frais réservation"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Frais Référencement</label>
                          <input
                            value={this.state.fraisRef}
                            disabled
                            type="text"
                            className="form-control"
                            placeholder="frais référencement"
                          />
                        </div>
                      </div>
                    </div>)}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  <button
                    style={{ borderRadius: "5px" }}
                    type="button"
                    className="btn btn-success"
                    onClick={this.ajout}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
            <Modal
              isOpen={this.state.modal}
              toggle={this.nonDeletePack}
              className={this.props.className}
              fade={false}
            >
              <ModalHeader toggle={this.nonDeletePack}>
                {" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "465px",
                  }}
                >
                  <img width="30%" src="./images/deleteModal.png" alt=".." />
                </div>
              </ModalHeader>
              <ModalBody>
                <div
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    FontSize: "14px",
                    FontWeight: "700",
                    LineHeight: "18.375px",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
                  </div>
                  <div
                    className="hvr-push"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginBottom: "15px",
                    }}
                  >
                    <div>
                      <button
                        onClick={() => this.deletePack()}
                        style={{
                          backgroundColor: "#00FF7F",
                          borderRadius: "5px",
                          color: "white",
                        }}
                      >
                        <i
                          style={{ color: "white" }}
                          className="far fa-thumbs-up"
                        ></i>
                        Oui
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={() => this.nonDeletePack()}
                        style={{
                          backgroundColor: "#D9534F",
                          borderRadius: "5px",
                          color: "white",
                        }}
                      >
                        <i
                          style={{ color: "white" }}
                          className="far fa-thumbs-down"
                        ></i>
                        Non
                      </button>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </div>
          <ToastContainer
            transition={Flip}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(AjoutContratOffre);
