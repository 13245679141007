import React, { Component } from "react";
import jQuery from "jquery";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";

class AjoutOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayOption: [{ option: "", montant: "" }],
      ligneOptionSupp: "",
      modal: false,
      societes: [],
      selectSociete: "",
      offres: [],
      selectOffre: "",
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mtd/GetSocietes.php",
        type: "POST",
        data: {},
        dataType: "json",
        success: (code_html, statut) => {
          this.setState({
            societes: code_html,
          });
        },
      });
    }
  }

  addSociete = (selectSociete) => {
    if (selectSociete != null) {
      this.setState({ selectSociete: selectSociete }, () => {
        jQuery.ajax({
          url: process.env.REACT_APP_API_URL + "Back_mtd/GetOffresBySociete.php",
          type: "POST",
          data: {
            societeId: selectSociete.value,
          },
          dataType: "json",
          success: (code_html, statut) => {
            this.setState({
              offres: code_html,
            });
          },
        });
      });
    }
    else {
      this.setState({
        selectSociete: '',
        offres: []
      });
    }
  };

  addOffre = (selectOffre) => {
    this.setState({ selectOffre: selectOffre });
  };

  addOption = (event, index) => {
    var option = event.target.value;
    this.setState({
      arrayOption: this.state.arrayOption.map((el, id) =>
        id === index ? Object.assign(el, { option: option }) : el
      ),
    });
  };

  addMnt = (event, index) => {
    var montant = event.target.value;
    this.setState({
      arrayOption: this.state.arrayOption.map((el, id) =>
        id === index ? Object.assign(el, { montant: montant }) : el
      ),
    });
  };

  addLigneOption = () => {
    var element = { option: "", montant: "" };
    this.setState({
      arrayOption: [...this.state.arrayOption, element],
    });
  };

  toggleOption = (index) => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      ligneOptionSupp: index,
    }));
  };

  deleteOption = () => {
    this.state.arrayOption.splice(this.state.ligneOptionSupp, 1);
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  nonDeleteOption = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  ajout = () => {
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mtd/AjoutOption.php",
      type: "POST",
      data: {
        arrayOption: this.state.arrayOption,
        offreId: this.state.selectOffre.value,
        societeId: this.state.selectSociete.value,
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push("/Options");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title"> Ajout supplémént </h3>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Societe:</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addSociete}
                        options={this.state.societes}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Offre:</label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.addOffre}
                        options={this.state.offres}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Suppléménts:</label>
                    </div>
                  </div>
                  <table
                    style={{ width: "100%", marginTop: "10px" }}
                    className="table table-bordered"
                  >
                    <thead>
                      <tr>
                        <th
                          className="col-md-5"
                          style={{ textAlign: "center" }}
                          scope="col"
                        >
                          Supplémént
                      </th>
                        <th
                          className="col-md-5"
                          style={{ textAlign: "center" }}
                          scope="col"
                        >
                          Montant
                      </th>
                        <th
                          className="col-md-2"
                          style={{ textAlign: "center" }}
                          scope="col"
                        >
                          Supp
                      </th>
                      </tr>
                    </thead>
                    {this.state.arrayOption.map((el, index) => (
                      <tbody>
                        <tr>
                          <td
                            style={{ textAlign: "center" }}
                            className="col-md-5"
                          >
                            <input style={{ width: '100%' }}
                              type="text"
                              onChange={(e) => this.addOption(e, index)}
                            />
                          </td>
                          <td
                            style={{ textAlign: "center" }}
                            className="col-md-5"
                          >
                            <input
                              type="text"
                              onChange={(e) => this.addMnt(e, index)}
                            />
                          </td>
                          <td
                            className="col-md-2"
                            style={{ textAlign: "center" }}
                          >
                            <button
                              className="buttonSupprimer"
                              onClick={() => this.toggleOption(index)}
                            >
                              <i
                                style={{ color: "white" }}
                                className="fas fa-trash-alt"
                              ></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginLeft: "15px",
                      marginTop: "5px",
                    }}
                  >
                    <Button
                      style={{ borderRadius: "5px" }}
                      onClick={this.addLigneOption}
                      color="secondary"
                    >
                      <i className="fas fa-plus-circle"></i>Ajouter ligne
                  </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      style={{ borderRadius: "5px" }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.ajout}
                    >
                      Confirmer
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            isOpen={this.state.modal}
            toggle={this.nonDeleteOption}
            className={this.props.className}
            fade={false}
          >
            <ModalHeader toggle={this.nonDeleteOption}>
              {" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "465px",
                }}
              >
                <img width="30%" src="./images/deleteModal.png" alt=".." />
              </div>
            </ModalHeader>
            <ModalBody>
              <div
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  FontSize: "14px",
                  FontWeight: "700",
                  LineHeight: "18.375px",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
                </div>
                <div
                  className="hvr-push"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "15px",
                  }}
                >
                  <div>
                    <button
                      onClick={() => this.deleteOption()}
                      style={{
                        backgroundColor: "#00FF7F",
                        borderRadius: "5px",
                        color: "white",
                      }}
                    >
                      <i
                        style={{ color: "white" }}
                        className="far fa-thumbs-up"
                      ></i>
                    Oui
                  </button>
                  </div>
                  <div>
                    <button
                      onClick={() => this.nonDeleteOption()}
                      style={{
                        backgroundColor: "#D9534F",
                        borderRadius: "5px",
                        color: "white",
                      }}
                    >
                      <i
                        style={{ color: "white" }}
                        className="far fa-thumbs-down"
                      ></i>
                    Non
                  </button>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(AjoutOption);
