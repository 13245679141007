import React from 'react';
import Login from './Login';
import { AppRoute } from './AppRoute'
import StatiquePage from './StatiquePage';
import Users from './Users';
import AjoutUser from './AjoutUser'
import ModifUser from './ModifUser'
import Societes from './Societes'
import AjoutSociete from './AjoutSociete'
import ModifSociete from './ModifSociete'
import Clients from './Clients'
import AjoutClient from './AjoutClient'
import ModifClient from './ModifClient'
import Options from './Options'
import AjoutOption from './AjoutOption'
import ModifOption from './ModifOption'
import Offres from './Offres'
import AjoutOffre from './AjoutOffre'
import ModifOffre from './ModifOffre'
import Rubrique from './Rubrique'
import AjoutRubrique from './AjoutRubrique'
import ModifRubrique from './ModifRubrique'
import OffreRubrique from './OffreRubrique'
import AjoutOffreRubrique from './AjoutOffreRubrique'
import ModifOffreRubrique from './ModifOffreRubrique'
import OffresSociete from './OffresSociete'
import AjoutOffreSociete from './AjoutOffreSociete'
import ModifOffreSociete from './ModifOffreSociete'
import ContratOffre from './ContratOffre'
import AjoutContratOffre from './AjoutContratOffre'
import ModifOffreContrat from './ModifOffreContrat'
import ImprContratOffre from './ImprContratOffre'
import ImprBonCom from './ImprBonCom'
import BonDeCommande from './BonDeCommande'
import AjoutBonDeCommande from './AjoutBonDeCommande'
import ModifBonDeCommande from './ModifBonDeCommande'
import ImprContrat from './ImprContrat'

import { Link, BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom'

function App() {
  return (
    <Router>
      <Switch>
        <AppRoute path="/ModifBonDeCommande" component={ModifBonDeCommande} layout={StatiquePage}></AppRoute>
        <AppRoute path="/ModifOffreRubrique" component={ModifOffreRubrique} layout={StatiquePage}></AppRoute>
        <AppRoute path="/AjoutOffreRubrique" component={AjoutOffreRubrique} layout={StatiquePage}></AppRoute>
        <AppRoute path="/AjoutBonDeCommande" component={AjoutBonDeCommande} layout={StatiquePage}></AppRoute>
        <AppRoute path="/AjoutContratOffre" component={AjoutContratOffre} layout={StatiquePage}></AppRoute>
        <AppRoute path="/ModifOffreContrat" component={ModifOffreContrat} layout={StatiquePage}></AppRoute>
        <AppRoute path="/ModifOffreSociete" component={ModifOffreSociete} layout={StatiquePage}></AppRoute>
        <AppRoute path="/AjoutOffreSociete" component={AjoutOffreSociete} layout={StatiquePage}></AppRoute>
        <AppRoute path="/BonDeCommande" component={BonDeCommande} layout={StatiquePage}></AppRoute>
        <AppRoute path="/OffresSociete" component={OffresSociete} layout={StatiquePage}></AppRoute>
        <AppRoute path="/OffreRubrique" component={OffreRubrique} layout={StatiquePage}></AppRoute>
        <AppRoute path="/ModifRubrique" component={ModifRubrique} layout={StatiquePage}></AppRoute>
        <AppRoute path="/AjoutRubrique" component={AjoutRubrique} layout={StatiquePage}></AppRoute>
        <AppRoute path="/ContratOffre" component={ContratOffre} layout={StatiquePage}></AppRoute>
        <AppRoute path="/ModifSociete" component={ModifSociete} layout={StatiquePage}></AppRoute>
        <AppRoute path="/AjoutSociete" component={AjoutSociete} layout={StatiquePage}></AppRoute>
        <AppRoute path="/ModifOption" component={ModifOption} layout={StatiquePage}></AppRoute>
        <AppRoute path="/AjoutOption" component={AjoutOption} layout={StatiquePage}></AppRoute>
        <AppRoute path="/ModifClient" component={ModifClient} layout={StatiquePage}></AppRoute>
        <AppRoute path="/AjoutClient" component={AjoutClient} layout={StatiquePage}></AppRoute>
        <AppRoute path="/AjoutOffre" component={AjoutOffre} layout={StatiquePage}></AppRoute>
        <AppRoute path="/ModifOffre" component={ModifOffre} layout={StatiquePage}></AppRoute>
        <AppRoute path="/ModifUser" component={ModifUser} layout={StatiquePage}></AppRoute>
        <AppRoute path="/AjoutUser" component={AjoutUser} layout={StatiquePage}></AppRoute>
        <AppRoute path="/Societes" component={Societes} layout={StatiquePage}></AppRoute>
        <AppRoute path="/Rubrique" component={Rubrique} layout={StatiquePage}></AppRoute>
        <AppRoute path="/Options" component={Options} layout={StatiquePage}></AppRoute>
        <AppRoute path="/Clients" component={Clients} layout={StatiquePage}></AppRoute>
        <AppRoute path="/Offres" component={Offres} layout={StatiquePage}></AppRoute>
        <AppRoute path="/Users" component={Users} layout={StatiquePage}></AppRoute>
        {/* Impression */}
        <Route path="/ImprContratOffre" component={ImprContratOffre}></Route>
        <Route path="/ImprContrat" component={ImprContrat}></Route>
        <Route path="/ImprBonCom" component={ImprBonCom}></Route>
        <Route path="/" render={() => <Login />} />
      </Switch>
    </Router>
  );
}

export default App;