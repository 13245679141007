import React, { Component } from "react";
import jQuery from "jquery";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

class AjoutSociete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nom: "",
      adresse: "",
      tel: "",
      rib: "",
      mf: "",
      rc: "",
      email: "",
      siteWeb: "",
      logo: null
    };
  }

  addNom = (event) => {
    this.setState({
      nom: event.target.value,
    });
  };

  addAdresse = (event) => {
    this.setState({
      adresse: event.target.value,
    });
  };

  addRib = (event) => {
    this.setState({
      rib: event.target.value,
    });
  };

  addMf = (event) => {
    this.setState({
      mf: event.target.value,
    });
  };

  addRc = (event) => {
    this.setState({
      rc: event.target.value,
    });
  };

  addTel = (event) => {
    this.setState({
      tel: event.target.value,
    });
  };

  addEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  addSiteWeb = (event) => {
    this.setState({
      siteWeb: event.target.value,
    });
  };

  addLogo(e) {
    this.setState({ logo: e.target.files })
  }

  ajout = () => {
    var logo = '';
    if (this.state.logo != null) {
      logo = process.env.REACT_APP_API_URL + "images/logo_societe/" + this.state.logo[0].name
    }
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mtd/AjoutSociete.php",
      type: "POST",
      data: {
        nom: this.state.nom,
        adresse: this.state.adresse,
        tel: this.state.tel,
        rib: this.state.rib,
        mf: this.state.mf,
        rc: this.state.rc,
        email: this.state.email,
        siteWeb: this.state.siteWeb,
        logo: logo
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          if (this.state.logo != null) {
            let files = this.state.logo;
            console.warn('data files', files)
            let reader = new FileReader();
            reader.readAsDataURL(files[0])
            reader.onload = (e) => {
              console.log(e)
              jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mtd/UploadLogoSociete.php',
                type: 'POST',
                data: {
                  file: e.target.result,
                  nomfile: this.state.logo[0].name
                },
                dataType: 'json',
                success: (code_html, statut) => {
                }
              }
              )
            }
            this.props.history.push("/Societes");
          }
          else {
            this.props.history.push("/Societes");
          }
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title"> Ajout Societe </h3>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Nom</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nom"
                        onChange={this.addNom}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Adresse</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Adresse"
                        onChange={this.addAdresse}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>RIB</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="RIB"
                        onChange={this.addRib}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Registre de commerce</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Registre de commerce"
                        onChange={this.addRc}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Matricule fiscale</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Matricule fiscale"
                        onChange={this.addMf}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Tel</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Tel"
                        onChange={this.addTel}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        onChange={this.addEmail}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Site Web</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Site web"
                        onChange={this.addSiteWeb}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Logo</label>
                      <input type="file" class="form-control" name="file" onChange={(e) => this.addLogo(e)} />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      style={{ borderRadius: "5px" }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.ajout}
                    >
                      Confirmer
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AjoutSociete);
