import React, { Component } from "react";
import axios from "axios";
import jQuery from "jquery";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

class AjoutOffreSociete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      societes: [],
      offres: [],
      selectOffre: "",
      selectSociete: "",
    };
  }

  componentDidMount() {
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mtd/GetOffres.php",
      type: "POST",
      data: {},
      dataType: "json",
      success: (code_html, statut) => {
        this.setState({
          offres: code_html,
        });
      },
    });
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mtd/GetSocietes.php",
      type: "POST",
      data: {},
      dataType: "json",
      success: (code_html, statut) => {
        this.setState({
          societes: code_html,
        });
      },
    });
  }

  addOffre = (selectOffre) => {
    this.setState({ selectOffre: selectOffre });
  };

  addSociete = (selectSociete) => {
    this.setState({ selectSociete: selectSociete });
  };

  ajout = () => {
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mtd/AjoutOffreSociete.php",
      type: "POST",
      data: {
        offreId: this.state.selectOffre.value,
        societes: this.state.societes
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push("/OffresSociete");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title"> Ajout offre-sociéte </h3>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin">
              <div className="card">
                <div className="card-body"></div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Offre</label>
                    <Select
                      isClearable={true}
                      isSearchable={true}
                      onChange={this.addOffre}
                      options={this.state.offres}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Societe</label>
                    <Select
                      isMulti={true}
                      isClearable={true}
                      isSearchable={true}
                      onChange={this.addSociete}
                      options={this.state.societes}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  <button
                    style={{ borderRadius: "5px" }}
                    type="button"
                    className="btn btn-success"
                    onClick={this.ajout}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            transition={Flip}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(AjoutOffreSociete);
