import React, { Component } from "react";
import { Link, BrowserRouter as Router } from "react-router-dom";
import DataTable from "react-data-table-component";
import jQuery from "jquery";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";
// import './paginate.css'

class BonDeCommande extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [
        {
          name: "Client",
          selector: "nom",
          sortable: false,
          center: true,
        },
        {
          name: "Offre",
          selector: "titre",
          sortable: false,
          center: true,
        },
        {
          name: "Bon-Cmd",
          cell: (row) => (
            <button
              style={{
                backgroundColor: "#DB7093",
                borderColor: "#DB7093",
                borderRadius: "5px",
              }}
              onClick={() => this.imprBonCmd(row.bonDeCommandeId)}
            >
              <i style={{ color: 'white' }} class="fas fa-print"></i>
            </button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "10%",
        },
        {
          name: "Contrat",
          cell: (row) => (
            <button
              style={{
                backgroundColor: "mediumaquamarine",
                borderColor: "mediumaquamarine",
                borderRadius: "5px",
              }}
              onClick={() => this.imprContrat(row.bonDeCommandeId)}
            >
              <i style={{ color: 'white' }} class="fas fa-print"></i>
            </button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
          width: "10%",
        },
        {
          name: "Modifier",
          cell: (row) => (
            <Link
              to={{
                pathname: "/ModifBonDeCommande",
                state: { bonDeCommandeId: row.bonDeCommandeId },
              }}
            >
              <button className="buttonModifier">
                <i style={{ color: "white" }} className="fas fa-pencil-alt"></i>
              </button>
            </Link>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          name: "Desactiver",
          cell: (row) => (
            <button
              className="buttonSupprimer"
              onClick={() => this.toggletwo(row.bonDeCommandeId)}
            >
              <i style={{ color: "white" }} className="fas fa-trash-alt"></i>
            </button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
      nombreTotalElement: 0,
      filterElement: "",
      mycurrentPage: 1,
      resetFirstPage: false,
      nbrEltperPage: 20,
      modal: false,
      clientId: "",
      pending: true,
      bonDeCommandeId: "",
      offres: [],
      selectOffre: ""
    };
    this.newElement = this.newElement.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mtd/BonDeCommande.php",
        type: "POST",
        data: {
          query: "",
          limit: this.state.nbrEltperPage,
          page: 1,
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            nombreTotalElement: code_html.total,
            data: code_html.data,
            pending: false,
          });
        },
      });
      jQuery.ajax({
        url: process.env.REACT_APP_API_URL + "Back_mtd/GetOffresPrixBC.php",
        type: "POST",
        data: {
        },
        dataType: "json",
        success: (code_html, statut) => {
          console.log(code_html);
          this.setState({
            offres: code_html,
          });
        },
      });
    }
  }

  newElement(page, totalRows) {
    this.setState(
      {
        pending: true,
      },
      function () {
        if (this.state.selectOffre != "") {
          var offreId = this.state.selectOffre.value;
        } else {
          var offreId = "";
        }
        console.log(page);
        console.log(totalRows);
        jQuery.ajax({
          url: process.env.REACT_APP_API_URL + "Back_mtd/BonDeComSearch.php",
          type: "POST",
          data: {
            offreId: offreId,
            param: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: page,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }.bind(this)
    );
  }

  perPage(currentRowsPerPage, currentPage) {
    this.setState(
      {
        nbrEltperPage: currentRowsPerPage,
        pending: true,
      },
      function () {
        if (this.state.selectOffre != "") {
          var offreId = this.state.selectOffre.value;
        } else {
          var offreId = "";
        }
        console.log(currentRowsPerPage);
        console.log(currentPage);
        jQuery.ajax({
          url: process.env.REACT_APP_API_URL + "Back_mtd/BonDeComSearch.php",
          type: "POST",
          data: {
            offreId: offreId,
            param: this.state.filterElement,
            limit: currentRowsPerPage,
            page: currentPage,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  filterSearch(event) {
    this.setState(
      {
        filterElement: event.target.value,
        resetFirstPage: !this.state.resetFirstPage,
        pending: true,
      },
      function () {
        if (this.state.selectOffre != "") {
          var offreId = this.state.selectOffre.value;
        } else {
          var offreId = "";
        }
        jQuery.ajax({
          url: process.env.REACT_APP_API_URL + "Back_mtd/BonDeComSearch.php",
          type: "POST",
          data: {
            offreId: offreId,
            param: this.state.filterElement,
            limit: this.state.nbrEltperPage,
            page: 1,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  }

  toggletwo = (bonDeCommandeId) => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      bonDeCommandeId: bonDeCommandeId,
    }));
  };

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  deleteBonCmd() {
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mtd/DeleteBonDeCommande.php",
      type: "POST",
      data: {
        bonDeCommandeId: this.state.bonDeCommandeId,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState(
          {
            modal: false,
          },
          () => {
            jQuery.ajax({
              url: process.env.REACT_APP_API_URL + "Back_mtd/BonDeCommande.php",
              type: "POST",
              data: {
                query: "",
                limit: this.state.nbrEltperPage,
                page: 1,
              },
              dataType: "json",
              success: (code_html, statut) => {
                console.log(code_html);
                this.setState({
                  nombreTotalElement: code_html.total,
                  data: code_html.data,
                });
              },
            });
          }
        );
      },
    });
  }

  recherche = () => {
    if (this.state.selectOffre != "") {
      var offreId = this.state.selectOffre.value;
    } else {
      var offreId = "";
    }
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mtd/BonDeComSearch.php",
      type: "POST",
      data: {
        offreId: offreId,
        param: this.state.filterElement,
        limit: this.state.nbrEltperPage,
        page: 1,
      },
      dataType: "json",
      success: (code_html, statut) => {
        console.log(code_html);
        this.setState({
          nombreTotalElement: code_html.total,
          data: code_html.data,
          pending: false,
        });
      },
    });
  };

  afficherTout = () => {
    this.setState(
      {
        selectOffre: "",
      },
      () => {
        jQuery.ajax({
          url: process.env.REACT_APP_API_URL + "Back_mtd/BonDeCommande.php",
          type: "POST",
          data: {
            query: "",
            limit: this.state.nbrEltperPage,
            page: 1,
          },
          dataType: "json",
          success: (code_html, statut) => {
            console.log(code_html);
            this.setState({
              nombreTotalElement: code_html.total,
              data: code_html.data,
              pending: false,
            });
          },
        });
      }
    );
  };

  searchOffres = (selectOffre) => {
    if (selectOffre !== null) {
      this.setState({ selectOffre: selectOffre });
    } else {
      this.setState({
        selectOffre: "",
      });
    }
  };

  imprBonCmd = (el) => {
    var bonDeCommandeId = el;
    window.open(
      "https://commercial.mtd-app.com/ImprBonCom?idBonCom=" + bonDeCommandeId
    );
  }

  imprContrat = (el) => {
    var bonDeCommandeId = el;
    window.open(
      "https://commercial.mtd-app.com/ImprContrat?idBonCom=" + bonDeCommandeId
    );
  }

  render() {
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title">Bon de commande </h3>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginBottom: "10px",
                      marginRight: "20px",
                    }}
                  >
                    <Link to="/AjoutBonDeCommande">
                      {" "}
                      <button
                        style={{
                          background: "#3A3F51",
                          border: "1px solid #3A3F51",
                          borderRadius: "5px",
                        }}
                        type="button"
                        className="btn btn-info"
                      >
                        {" "}
                        <i className="fas fa-plus"></i>Bon-cmd
                    </button>
                    </Link>
                  </div>
                  <div>
                    <h4 style={{ paddingLeft: "10px" }}>Critères de recherche</h4>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Offres </label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          onChange={this.searchOffres}
                          options={this.state.offres}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-around", marginBottom: '10px' }}
                  >
                    <div>
                      <button
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "#761C19",
                          borderColor: "#761C19",
                        }}
                        type="button"
                        className="btn btn-success"
                        onClick={this.recherche}
                      ><i style={{ paddingRight: '5px' }} class="fas fa-search"></i>
                        Rechercher
                    </button>
                    </div>
                    <div>
                      <button
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "#761C19",
                          borderColor: "#761C19",
                        }}
                        type="button"
                        className="btn btn-success"
                        onClick={this.afficherTout}
                      >
                        Afficher tout
                    </button>
                    </div>
                  </div>
                  <div className="mb-0 form-group">
                    <div className="input-group-alternative input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-search"></i>
                        </span>
                        <input style={{ width: '100%', height: '100%' }}
                          onChange={(currentRowsPerPage, currentPage) =>
                            this.filterSearch(currentRowsPerPage, currentPage)
                          }
                          placeholder="Search"
                          type="text"
                          className="search"
                        />
                      </div>
                    </div>
                  </div>
                  <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    highlightOnHover={true}
                    pagination={true}
                    paginationServer={true}
                    onChangePage={(page, totalRows) =>
                      this.newElement(page, totalRows)
                    }
                    paginationTotalRows={this.state.nombreTotalElement}
                    paginationPerPage={this.state.nbrEltperPage}
                    paginationRowsPerPageOptions={[20, 25, 30, 35, 40]}
                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                      this.perPage(currentRowsPerPage, currentPage)
                    }
                    paginationResetDefaultPage={this.state.resetFirstPage}
                    paginationComponentOptions={{
                      rowsPerPageText: "Elements par page:",
                      rangeSeparatorText: "de",
                      noRowsPerPage: false,
                    }}
                    progressPending={this.state.pending}
                    noHeader={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
            fade={false}
          >
            <ModalHeader toggle={this.toggle}>
              {" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "465px",
                }}
              >
                <img width="30%" src="./images/deleteModal.png" alt=".." />
              </div>
            </ModalHeader>
            <ModalBody>
              <div
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  FontSize: "14px",
                  FontWeight: "700",
                  LineHeight: "18.375px",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p>Êtes-Vous sûr de vouloir supprimer ce bon de commande ?</p>
                </div>
                <div
                  className="hvr-push"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "15px",
                  }}
                >
                  <div>
                    <button
                      onClick={() => this.deleteBonCmd()}
                      style={{
                        backgroundColor: "#00FF7F",
                        borderRadius: "5px",
                        color: "white",
                      }}
                    >
                      <i
                        style={{ color: "white" }}
                        className="far fa-thumbs-up"
                      ></i>
                    Oui
                  </button>
                  </div>
                  <div>
                    <button
                      onClick={() => this.toggle()}
                      style={{
                        backgroundColor: "#D9534F",
                        borderRadius: "5px",
                        color: "white",
                      }}
                    >
                      <i
                        style={{ color: "white" }}
                        className="far fa-thumbs-down"
                      ></i>
                    Non
                  </button>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

export default BonDeCommande;
