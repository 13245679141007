import React, { Component } from "react";
import axios from "axios";
import jQuery from "jquery";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import Select from "react-select";

class ModifUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      nom: "",
      prenom: "",
      role: [],
      selectedRole: "",
      login: "",
      password: "",
      qualite: "",
      email: "",
      tel: ""
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      let userId = this.props.location.state.userId;
      axios
        .get(
          process.env.REACT_APP_API_URL +
          "Back_mtd/GetUserById.php?id=" +
          userId
        )
        .then((res) => {
          console.log(res);
          this.setState({
            userId: res.data.userId,
            nom: res.data.nom,
            prenom: res.data.prenom,
            selectedRole: res.data.role,
            login: res.data.login,
            password: res.data.password,
            qualite: res.data.qualite,
            email: res.data.email,
            tel: res.data.tel
          });
        });
      axios
        .get(
          process.env.REACT_APP_API_URL + "Back_mtd/GetRole.php"
        )
        .then((res) => {
          console.log(res);
          this.setState({
            role: res.data,
          });
        });
    }
  }

  modifNom = (event) => {
    this.setState({
      nom: event.target.value,
    });
  };

  modifPrenom = (event) => {
    this.setState({
      prenom: event.target.value,
    });
  };

  modifTel = (event) => {
    this.setState({
      tel: event.target.value,
    });
  };

  modifRole = (selectedRole) => {
    this.setState({ selectedRole: selectedRole });
  };

  modifLogin = (event) => {
    this.setState({
      login: event.target.value,
    });
  };

  modifPassword = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  modifQualite = (event) => {
    this.setState({
      qualite: event.target.value,
    });
  };

  modifEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  modif = () => {
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mtd/UpdUser.php?id=" +
        this.state.userId,
      type: "POST",
      data: {
        nom: this.state.nom,
        prenom: this.state.prenom,
        tel: this.state.tel,
        role: this.state.selectedRole.value,
        login: this.state.login,
        password: this.state.password,
        qualite: this.state.qualite,
        email: this.state.email
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push("/Users");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title"> Modification user </h3>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Nom</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.nom}
                        onChange={this.modifNom}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Prenom</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.prenom}
                        onChange={this.modifPrenom}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Tel</label>
                      <input
                        value={this.state.tel}
                        type="text"
                        className="form-control"
                        placeholder="Nom"
                        onChange={this.modifTel}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Role</label>
                      <Select
                        value={this.state.selectedRole}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.modifRole}
                        options={this.state.role}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Login</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.login}
                        onChange={this.modifLogin}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Mot de passe</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.password}
                        onChange={this.modifPassword}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Qualité</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.qualite}
                        onChange={this.modifQualite}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.email}
                        onChange={this.modifEmail}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      style={{ borderRadius: "5px" }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.modif}
                    >
                      Confirmer
        </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ModifUser);
