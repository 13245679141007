import React, { Component, Fragment } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import "./paginate.css";

class ImprContratOffre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      selectOffre: "",
      selectSociete: "",
      selectClient: "",
      selectOption: [],
      selectRubrique: [],
      prix: "",
      prixFinal: "",
      tauxRemise: "",
      fraisRes: "",
      fraisRef: "",
      remiseMax: "",
      entete: "",
      pied: "",
      chart: "",
      societes: "",
      colorText: "",
      yearAfter: "",
      arrayPack: [],
      mntTotPack: 0,
      mntTotPackInit: 0,
      etatPrixPack: 0,
    };
  }

  componentDidMount() {
    var url = window.location.href;
    var arrayContratOffre = url.split("=");
    var offreContratId = arrayContratOffre[1];
    //
    let date = new Date();
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();
    let year = date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    var convertDate = [day, month, year].join("/");
    //
    var yearAfter = new Date().getFullYear();
    //
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mtd/GetPackPrix.php",
      type: "POST",
      data: {},
      dataType: "json",
      success: (code_html, statut) => {
        this.setState({
          packPrix: code_html,
        });
      },
    });
    //
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mtd/GetInfoOffreContrat.php",
      type: "POST",
      data: {
        offreContratId: offreContratId,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState(
          {
            date: convertDate,
            selectOffre: code_html.offres,
            selectSociete: code_html.societes,
            selectClient: code_html.clients,
            selectOption: code_html.options,
            selectRubrique: code_html.rubrique,
            prix: code_html.prix,
            tauxRemise: code_html.tauxRemise,
            prixFinal: code_html.prixFinal,
            fraisRes: code_html.fraisRes,
            fraisRef: code_html.fraisRef,
            entete: "images/entete_pied_societe/" + code_html.entete,
            pied: "images/entete_pied_societe/" + code_html.pied,
            chart: "images/first_page/" + code_html.chart,
            societes: code_html.societes,
            yearAfter: parseInt(yearAfter) + 1,
            arrayPack: code_html.pack,
          },
          () => {
            console.log(this.state.selectOption);
            // somme prix-pack
            var mntTotPack = 0;
            for (var i = 0; i < this.state.arrayPack.length; i++) {
              mntTotPack += parseFloat(this.state.arrayPack[i].prix);
            }
            var tabPack = [];
            for (var ij = 0; ij < this.state.packPrix.length; ij++) {
              var idPack = this.state.packPrix[ij].packId;
              for (var ji = 0; ji < this.state.arrayPack.length; ji++) {
                var packId = this.state.arrayPack[ji].pack;
                if (idPack == packId) {
                  tabPack.push(this.state.packPrix[ij]);
                }
              }
            }
            // Somme prix-pack original
            var mntTotPackInit = 0;
            for (var j = 0; j < tabPack.length; j++) {
              mntTotPackInit += parseFloat(tabPack[j].prix);
            }
            if (mntTotPackInit > mntTotPack) {
              this.setState({
                mntTotPackInit: mntTotPackInit,
                mntTotPack: mntTotPack,
                etatPrixPack: 1,
              });
            } else {
              this.setState({
                mntTotPackInit: mntTotPackInit,
                mntTotPack: mntTotPack,
                etatPrixPack: 0,
              });
            }
            //
            if (this.state.societes.value == 1) {
              this.setState({
                colorText: "#ff944d",
              });
            } else if (this.state.societes.value == 2) {
              this.setState({
                colorText: "#4dffa6",
              });
            } else if (this.state.societes.value == 3) {
              this.setState({
                colorText: "#33ccff",
              });
            } else if (this.state.societes.value == 4) {
              this.setState({
                colorText: "#ff8080",
              });
            } else if (this.state.societes.value == 5) {
              this.setState({
                colorText: "#cccccc",
              });
            } else if (this.state.societes.value == 6) {
              this.setState({
                colorText: "#ff944d",
              });
            }
          }
        );
      },
    });
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div>
        {this.state.selectOffre.value != 6 ? (
          this.state.selectOffre.value == 7 ? (
            <div
              style={{ marginLeft: "7%", color: "black", marginRight: "7%" }}
            >
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  marginTop: "15px",
                }}
                className="logoSociete"
              >
                <div className="form-group">
                  <img
                    style={{ marginLeft: "-7%", width: "95%", height: "60%" }}
                    src={this.state.entete}
                    alt=""
                  />
                </div>
              </div>
              <div
                style={{ textAlign: "center", marginTop: "25%" }}
                className="adresse"
              >
                <div className="form-group">
                  <img
                    style={{ marginLeft: "-7%", width: "95%", height: "10%" }}
                    src={this.state.pied}
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div className="form-group">
                  <img
                    style={{ width: "95%", marginTop: "10%" }}
                    src={this.state.chart}
                    alt=""
                  />
                </div>
              </div>
              <div className="pagebreak">
                <div style={{ marginTop: "30%" }} className="row">
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "20%",
                    }}
                    className="form-group"
                  >
                    <p style={{ fontWeight: "15px", fontSize: "30px" }}>
                      Sfax, le {this.state.date}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "40%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="row"
                >
                  <div className="form-group">
                    <h1 style={{ fontWeight: "bold", fontSize: "50px" }}>
                      OFFRE DE PRIX
                    </h1>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="row"
                >
                  <div className="form-group">
                    <h1 style={{ fontSize: "35px" }}>
                      Développement d’une Application Mobile
                    </h1>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="row"
                >
                  <div className="form-group">
                    <h1 style={{ fontSize: "35px" }}>MFV</h1>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="row"
                >
                  <div className="form-group">
                    <h1 style={{ fontSize: "35px" }}>
                      Avec un interface web Cloud
                    </h1>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "15%",
                  }}
                  className="row"
                >
                  <div className="form-group">
                    <h1 style={{ fontSize: "35px" }}>Destiné à :</h1>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="row"
                >
                  <div className="form-group">
                    <h1 style={{ fontSize: "35px", fontWeight: "bold" }}>
                      {this.state.selectClient.label}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="pagebreak">
                <div style={{ marginTop: "15%" }} className="row">
                  <div className="form-group">
                    <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                      I. Présentation de la société MTD GROUP :
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <p style={{ fontSize: "25px" }}>
                      MTD GROUP, groupe créé en 2000, spécialisé en solution
                      Internet et media
                    </p>
                    <p style={{ fontSize: "25px" }}>
                      Il propose des services de création graphique, de
                      référencement, de création de portails, de logiciels
                      standards et personnalisés, d’applications web accessibles
                      sur Internet. Il assure un rôle de conseil, d’assistance
                      et d’écoute à ses clients partenaires.
                    </p>
                  </div>
                </div>
                <div style={{ marginTop: "3%" }} className="row">
                  <div className="form-group">
                    <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                      II. Présentation de l’offre :
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <p style={{ fontSize: "25px" }}>
                      Nous avons l’honneur de vous proposer notre savoir-faire
                      pour le développement d’une application Mobile{" "}
                      {this.state.selectOffre.label}.
                    </p>
                  </div>
                </div>
                <div style={{ marginTop: "3%" }} className="row">
                  <div className="form-group">
                    <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                      III. Le contenu du site web :
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                      a) Version mobile :
                    </p>
                    <p style={{ fontSize: "24px" }}>
                      Cette version est dédiée pour les commerciaux pour faire
                      le suivi des commandes, ajouter des nouveaux clients par
                      géolocalisation, suivi des règlements, gestion de stock,
                      etc.
                    </p>
                    <p style={{ fontSize: "24px" }}>
                      Cette version contient les modules suivants :
                    </p>
                    <p style={{ fontSize: "24px" }}>
                      &#11162; Passer commandes.
                    </p>
                    <p style={{ fontSize: "24px" }}>
                      &#11162; Liste des commandes.
                    </p>
                    <p style={{ fontSize: "24px" }}>&#11162; Règlements.</p>
                    <p style={{ fontSize: "24px" }}>
                      &#11162; Clients (liste des clients, ajout des nouveaux
                      clients par géolocalisation).
                    </p>
                    <p style={{ fontSize: "24px" }}>&#11162; Visite clients.</p>
                    <p style={{ fontSize: "24px" }}>
                      &#11162; Liste Règlements.
                    </p>
                    <p style={{ fontSize: "24px" }}>&#11162; Facture.</p>
                    <p style={{ fontSize: "24px" }}>&#11162; Statistique.</p>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "15%" }} className="pagebreak">
                <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                  b) Version Administration web (cloud):
                </p>
                <p style={{ fontSize: "24px" }}>
                  La version admin permet de paramétrer contrôler l’activité
                  commerciale à temps réel. Cette version contient les modules
                  suivants :
                </p>
                <p style={{ fontSize: "24px" }}>
                  &#11162; Paramétrage des commerciaux.
                </p>
                <p style={{ fontSize: "24px" }}>
                  &#11162; Paramétrage des objectifs commerciaux.
                </p>
                <p style={{ fontSize: "24px" }}>
                  &#11162; Paramétrage E-mail, envoie, commande.
                </p>
                <p style={{ fontSize: "24px" }}>&#11162; Interface clients.</p>
                <p style={{ fontSize: "24px" }}>
                  &#11162; Interface commandes.
                </p>
                <p style={{ fontSize: "24px" }}>&#11162; Interface articles.</p>
                <p style={{ fontSize: "24px" }}>
                  &#11162; Interface règlements.
                </p>
                <p style={{ fontSize: "24px" }}>&#11162; Interface factures</p>
                <p style={{ fontSize: "24px" }}>
                  &#11162; Rapport de visite (récupéré par l’application des
                  commerciaux).{" "}
                </p>
                <p style={{ fontSize: "24px" }}>
                  &#11162; Positions commercial (récupéré par l’application des
                  commerciaux toutes les 30 mins).{" "}
                </p>
                <p style={{ fontSize: "24px" }}>&#11162; Statistique.</p>
                <p style={{ fontSize: "24px", marginLeft: "20px" }}>
                  &#9679; Chiffre d’affaires par commercial.
                </p>
                <p style={{ fontSize: "24px", marginLeft: "20px" }}>
                  &#9679; Chiffre d’affaires par article.
                </p>
                <p style={{ fontSize: "24px", marginLeft: "20px" }}>
                  &#9679; Chiffre d’affaires par famille de produits.
                </p>
                <p style={{ fontSize: "24px", marginLeft: "20px" }}>
                  &#9679; Commandes par commercial.
                </p>
                <p style={{ fontSize: "24px", marginLeft: "20px" }}>
                  &#9679; Commande par famille.
                </p>
                <p style={{ fontSize: "24px", marginLeft: "20px" }}>
                  &#9679; Chiffre d’affaires par article par commercial.
                </p>
              </div>
              <div className="pagebreak">
                <div style={{ marginTop: "15%" }} className="row">
                  <div className="form-group">
                    <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                      IV. Offre de Prix
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <p style={{ fontSize: "25px" }}>
                      &#11162; Le développement de l’interface de la partie
                      admin de l’application est estimé à{" "}
                      <strong style={{ color: this.state.colorText }}>
                        {this.state.prixFinal} Dinars Hors Taxes.
                      </strong>
                      .
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <p style={{ fontSize: "25px" }}>
                      Le développement de l’interface de la partie mobile est
                      comme suite
                    </p>
                    <p style={{ fontSize: "25px" }}>
                      &#11162;{" "}
                      <strong style={{ color: this.state.colorText }}>
                        {" "}
                        500 Dinars Hors Taxes par commercial{" "}
                      </strong>{" "}
                      pour un nombre de commerciaux inférieur à 5.
                    </p>
                    <p style={{ fontSize: "25px" }}>
                      &#11162;{" "}
                      <strong style={{ color: this.state.colorText }}>
                        {" "}
                        400 Dinars Hors Taxes le commercial{" "}
                      </strong>{" "}
                      pour un nombre de commerciaux entre 6 et 10
                    </p>
                    <p style={{ fontSize: "25px" }}>
                      &#11162;{" "}
                      <strong style={{ color: this.state.colorText }}>
                        {" "}
                        300 Dinars Hors Taxes par commercial{" "}
                      </strong>{" "}
                      pour un nombre de commerciaux 11 ou plus.
                    </p>
                    <p style={{ fontSize: "25px" }}>
                      &#11162; Pour tout développement supplémentaire l’heure
                      sera facturée à raison de{" "}
                      <strong style={{ color: this.state.colorText }}>
                        {" "}
                        50 Dinars Hors Taxe.{" "}
                      </strong>
                    </p>
                    <p style={{ fontSize: "25px" }}>
                      &#11162; Décompte tous les 100 Heures.
                    </p>
                    <p style={{ fontSize: "25px", fontWeight: "bold" }}>
                      &#11162; TVA à la facturation.
                    </p>
                  </div>
                </div>
                <div style={{ marginTop: "3%" }} className="row">
                  <div className="form-group">
                    <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                      V. Frais annuels :
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <p style={{ fontSize: "25px" }}>
                      &#11162; Les frais mensuels d’hébergement sur nos Cloud et
                      maintenance de l’application sont à{" "}
                      <strong style={{ color: this.state.colorText }}>
                        {this.state.fraisRes} Dinars Hors Taxe / mois.
                      </strong>
                      .
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <p style={{ fontSize: "25px" }}>
                      &#10020; Le payement se fait chaque 6 mois à partir de
                      Janvier {this.state.yearAfter}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "5%",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "110px",
                  }}
                  className="row"
                >
                  <div className="form-group">
                    <p style={{ fontSize: "25px" }}>
                      Dans l’attente d’une réponse favorable,{" "}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "110px",
                  }}
                  className="row"
                >
                  <div className="form-group">
                    <p style={{ fontSize: "25px" }}>
                      Veuillez agréer Monsieur l’expression{" "}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "110px",
                  }}
                  className="row"
                >
                  <div className="form-group">
                    <p style={{ fontSize: "25px" }}>
                      De notre très haute considération{" "}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "110px",
                  }}
                  className="row"
                >
                  <div className="form-group">
                    <p style={{ fontWeight: "bold", fontSize: "25px" }}>
                      <strong> Service Commercial </strong>{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <i
                  id="print_me_not"
                  style={{ fontSize: "30px", cursor: "pointer" }}
                  onClick={this.print}
                  class="fas fa-print"
                ></i>
              </div>
            </div>
          ) : (
            <div
              style={{ marginLeft: "7%", color: "black", marginRight: "3%" }}
            >
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  marginTop: "15px",
                }}
                className="logoSociete"
              >
                <div className="form-group">
                  <img
                    style={{ marginLeft: "-7%", width: "95%", height: "60%" }}
                    src={this.state.entete}
                    alt=""
                  />
                </div>
              </div>
              <div
                style={{ textAlign: "center", marginTop: "25%" }}
                className="adresse"
              >
                <div className="form-group">
                  <img
                    style={{ marginLeft: "-7%", width: "95%", height: "10%" }}
                    src={this.state.pied}
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div className="form-group">
                  <img
                    style={{ width: "95%", marginTop: "10%" }}
                    src={this.state.chart}
                    alt=""
                  />
                </div>
              </div>
              <div className="pagebreak">
                <div style={{ marginTop: "30%" }} className="row">
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "20%",
                    }}
                    className="form-group"
                  >
                    <p style={{ fontWeight: "15px", fontSize: "30px" }}>
                      Sfax, le {this.state.date}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "40%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="row"
                >
                  <div className="form-group">
                    <h1 style={{ fontWeight: "bold", fontSize: "50px" }}>
                      OFFRE DE PRIX
                    </h1>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="row"
                >
                  <div className="form-group">
                    <h1 style={{ fontSize: "35px" }}>
                      Développement d'un site web
                    </h1>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "15%",
                  }}
                  className="row"
                >
                  <div className="form-group">
                    <h1 style={{ fontSize: "35px" }}>Destiné à :</h1>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="row"
                >
                  <div className="form-group">
                    <h1 style={{ fontSize: "35px", fontWeight: "bold" }}>
                      {this.state.selectClient.label}
                    </h1>
                  </div>
                </div>
              </div>
              {this.state.selectRubrique.length < 11 ? (
                <div>
                  {" "}
                  <div className="pagebreak">
                    <div style={{ marginTop: "15%" }} className="row">
                      <div className="form-group">
                        <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                          I. Présentation de la société MTD GROUP :
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          MTD GROUP, groupe créé en 2000, spécialisé en solution
                          Internet et media
                        </p>
                        <p style={{ fontSize: "25px" }}>
                          Il propose des services de création graphique, de
                          référencement, de création de portails, de logiciels
                          standards et personnalisés, d’applications web
                          accessibles sur Internet. Il assure un rôle de
                          conseil, d’assistance et d’écoute à ses clients
                          partenaires.
                        </p>
                      </div>
                    </div>
                    <div style={{ marginTop: "3%" }} className="row">
                      <div className="form-group">
                        <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                          II. Présentation de l’offre :
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          Nous avons l’honneur de vous proposer notre
                          savoir-faire pour le développement d’un{" "}
                          {this.state.selectOffre.label} .
                        </p>
                      </div>
                    </div>
                    <div style={{ marginTop: "3%" }} className="row">
                      <div className="form-group">
                        <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                          III. Le contenu du site web :
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group">
                        <p style={{ fontSize: "24px" }}>
                          Le site web pour{" "}
                          <strong>
                            {" "}
                            La société {this.state.selectClient.label}{" "}
                          </strong>{" "}
                          va être conçu en Français contient les modules
                          suivants :
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group">
                        <p style={{ fontWeight: "bold", fontSize: "25px" }}>
                          {" "}
                          {this.state.selectRubrique.map((el) => (
                            <p style={{ fontSize: "22px" }}>
                              &#11162; {el.label}{" "}
                            </p>
                          ))}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="pagebreak">
                    <div style={{ marginTop: "20%" }} className="row">
                      <div className="form-group">
                        <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                          IV. Offre de Prix
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          &#11162;Le développement d’un{" "}
                          {this.state.selectOffre.label} est estimé à{" "}
                          <strong style={{ color: this.state.colorText }}>
                            {this.state.prixFinal} Dinars Hors Taxe
                          </strong>
                          .
                        </p>
                      </div>
                    </div>
                    {this.state.selectOption.map((elem) => (
                      <div className="row">
                        <div className="form-group">
                          <p style={{ fontSize: "25px" }}>
                            &#11162; L’addition d'un(e) {elem.options}{" "}
                            supplémentaire est estimée à{" "}
                            <strong style={{ color: this.state.colorText }}>
                              {elem.montant} Dinars Hors Taxe.
                            </strong>
                          </p>
                        </div>
                      </div>
                    ))}
                    <div style={{ marginTop: "3%" }} className="row">
                      <div className="form-group">
                        <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                          V. Frais annuels :
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          Réservation domaine, Hébergement et référencement :
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          &#11162; Les frais annuels de réservation du nom de
                          domaine, service messagerie, hébergement sont pour{" "}
                          <strong style={{ color: this.state.colorText }}>
                            {this.state.fraisRes} Dinars Hors Taxe le site
                          </strong>
                          .
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          &#11162; Les frais annuels de référencement sur les
                          moteurs de recherches (avec la sélection de 20 mots
                          clés) sont pour{" "}
                          <strong style={{ color: this.state.colorText }}>
                            {this.state.fraisRef} Dinars Hors Taxe le site.
                          </strong>
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "5%",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "110px",
                      }}
                      className="row"
                    >
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          Dans l’attente d’une réponse favorable,{" "}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "110px",
                      }}
                      className="row"
                    >
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          Veuillez agréer Monsieur l’expression{" "}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "110px",
                      }}
                      className="row"
                    >
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          De notre très haute considération{" "}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "110px",
                      }}
                      className="row"
                    >
                      <div className="form-group">
                        <p style={{ fontWeight: "bold", fontSize: "25px" }}>
                          <strong> Service Commercial </strong>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="pagebreak">
                    <div style={{ marginTop: "15%" }} className="row">
                      <div className="form-group">
                        <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                          I. Présentation de la société MTD GROUP :
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          MTD GROUP, groupe créé en 2000, spécialisé en solution
                          Internet et media
                        </p>
                        <p style={{ fontSize: "25px" }}>
                          Il propose des services de création graphique, de
                          référencement, de création de portails, de logiciels
                          standards et personnalisés, d’applications web
                          accessibles sur Internet. Il assure un rôle de
                          conseil, d’assistance et d’écoute à ses clients
                          partenaires.
                        </p>
                      </div>
                    </div>
                    <div style={{ marginTop: "3%" }} className="row">
                      <div className="form-group">
                        <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                          II. Présentation de l’offre :
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          Nous avons l’honneur de vous proposer notre
                          savoir-faire pour le développement d’un{" "}
                          {this.state.selectOffre.label} .
                        </p>
                      </div>
                    </div>
                    <div style={{ marginTop: "3%" }} className="row">
                      <div className="form-group">
                        <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                          III. Le contenu du site web :
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group">
                        <p style={{ fontSize: "24px" }}>
                          Le site web pour{" "}
                          <strong>
                            {" "}
                            La société {this.state.selectClient.label}{" "}
                          </strong>{" "}
                          va être conçu en Français contient les modules
                          suivants :
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group">
                        <p style={{ fontWeight: "bold", fontSize: "25px" }}>
                          {" "}
                          {this.state.selectRubrique.slice(0,10).map((el) => (
                            <p style={{ fontSize: "22px" }}>
                              &#11162; {el.label}{" "}
                            </p>
                          ))}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="pagebreak">
                    <div style={{ marginTop: "10%" }} className="row">
                      <div className="form-group">
                        <p style={{ fontWeight: "bold", fontSize: "25px" }}>
                          {" "}
                          {this.state.selectRubrique
                            .slice(10, this.state.selectRubrique.length)
                            .map((el) => (
                              <p style={{ fontSize: "22px" }}>
                                &#11162; {el.label}{" "}
                              </p>
                            ))}{" "}
                        </p>
                      </div>
                    </div>
                    <div style={{ marginTop: "1%" }} className="row">
                      <div className="form-group">
                        <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                          IV. Offre de Prix
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          &#11162;Le développement d’un{" "}
                          {this.state.selectOffre.label} est estimé à{" "}
                          <strong style={{ color: this.state.colorText }}>
                            {this.state.prixFinal} Dinars Hors Taxe
                          </strong>
                          .
                        </p>
                      </div>
                    </div>
                    {this.state.selectOption.map((elem) => (
                      <div className="row">
                        <div className="form-group">
                          <p style={{ fontSize: "25px" }}>
                            &#11162; L’addition d'un(e) {elem.options}{" "}
                            supplémentaire est estimée à{" "}
                            <strong style={{ color: this.state.colorText }}>
                              {elem.montant} Dinars Hors Taxe.
                            </strong>
                          </p>
                        </div>
                      </div>
                    ))}
                    <div style={{ marginTop: "3%" }} className="row">
                      <div className="form-group">
                        <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                          V. Frais annuels :
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          Réservation domaine, Hébergement et référencement :
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          &#11162; Les frais annuels de réservation du nom de
                          domaine, service messagerie, hébergement sont pour{" "}
                          <strong style={{ color: this.state.colorText }}>
                            {this.state.fraisRes} Dinars Hors Taxe le site
                          </strong>
                          .
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          &#11162; Les frais annuels de référencement sur les
                          moteurs de recherches (avec la sélection de 20 mots
                          clés) sont pour{" "}
                          <strong style={{ color: this.state.colorText }}>
                            {this.state.fraisRef} Dinars Hors Taxe le site.
                          </strong>
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "5%",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "110px",
                      }}
                      className="row"
                    >
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          Dans l’attente d’une réponse favorable,{" "}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "110px",
                      }}
                      className="row"
                    >
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          Veuillez agréer Monsieur l’expression{" "}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "110px",
                      }}
                      className="row"
                    >
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          De notre très haute considération{" "}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "110px",
                      }}
                      className="row"
                    >
                      <div className="form-group">
                        <p style={{ fontWeight: "bold", fontSize: "25px" }}>
                          <strong> Service Commercial </strong>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* <div className="pagebreak">
                <div style={{ marginTop: "20%" }} className="row">
                  <div className="form-group">
                    <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                      IV. Offre de Prix
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <p style={{ fontSize: "25px" }}>
                      &#11162;Le développement d’un{" "}
                      {this.state.selectOffre.label} est estimé à{" "}
                      <strong style={{ color: this.state.colorText }}>
                        {this.state.prixFinal} Dinars Hors Taxe
                      </strong>
                      .
                    </p>
                  </div>
                </div>
                {this.state.selectOption.map((elem) => (
                  <div className="row">
                    <div className="form-group">
                      <p style={{ fontSize: "25px" }}>
                        &#11162; L’addition d'un(e) {elem.options}{" "}
                        supplémentaire est estimée à{" "}
                        <strong style={{ color: this.state.colorText }}>
                          {elem.montant} Dinars Hors Taxe.
                        </strong>
                      </p>
                    </div>
                  </div>
                ))}
                <div style={{ marginTop: "3%" }} className="row">
                  <div className="form-group">
                    <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                      V. Frais annuels :
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <p style={{ fontSize: "25px" }}>
                      Réservation domaine, Hébergement et référencement :
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <p style={{ fontSize: "25px" }}>
                      &#11162; Les frais annuels de réservation du nom de
                      domaine, service messagerie, hébergement sont pour{" "}
                      <strong style={{ color: this.state.colorText }}>
                        {this.state.fraisRes} Dinars Hors Taxe le site
                      </strong>
                      .
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group">
                    <p style={{ fontSize: "25px" }}>
                      &#11162; Les frais annuels de référencement sur les
                      moteurs de recherches (avec la sélection de 20 mots clés)
                      sont pour{" "}
                      <strong style={{ color: this.state.colorText }}>
                        {this.state.fraisRef} Dinars Hors Taxe le site.
                      </strong>
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "5%",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "110px",
                  }}
                  className="row"
                >
                  <div className="form-group">
                    <p style={{ fontSize: "25px" }}>
                      Dans l’attente d’une réponse favorable,{" "}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "110px",
                  }}
                  className="row"
                >
                  <div className="form-group">
                    <p style={{ fontSize: "25px" }}>
                      Veuillez agréer Monsieur l’expression{" "}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "110px",
                  }}
                  className="row"
                >
                  <div className="form-group">
                    <p style={{ fontSize: "25px" }}>
                      De notre très haute considération{" "}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "110px",
                  }}
                  className="row"
                >
                  <div className="form-group">
                    <p style={{ fontWeight: "bold", fontSize: "25px" }}>
                      <strong> Service Commercial </strong>{" "}
                    </p>
                  </div>
                </div>
              </div> */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <i
                  id="print_me_not"
                  style={{ fontSize: "30px", cursor: "pointer" }}
                  onClick={this.print}
                  class="fas fa-print"
                ></i>
              </div>
            </div>
          )
        ) : (
          <div style={{ marginLeft: "7%", color: "black", marginRight: "3%" }}>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                marginTop: "15px",
              }}
              className="logoSociete"
            >
              <div className="form-group">
                <img
                  style={{ marginLeft: "-7%", width: "95%", height: "60%" }}
                  src={this.state.entete}
                  alt=""
                />
              </div>
            </div>
            <div
              style={{ textAlign: "center", marginTop: "25%" }}
              className="adresse"
            >
              <div className="form-group">
                <img
                  style={{ marginLeft: "-7%", width: "95%", height: "10%" }}
                  src={this.state.pied}
                  alt=""
                />
              </div>
            </div>
            <div>
              <div className="form-group">
                <img
                  style={{ width: "95%", marginTop: "10%" }}
                  src={this.state.chart}
                  alt=""
                />
              </div>
            </div>
            <div className="pagebreak">
              <div style={{ marginTop: "30%" }} className="row">
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20%",
                  }}
                  className="form-group"
                >
                  <p style={{ fontWeight: "15px", fontSize: "30px" }}>
                    Sfax, le {this.state.date}
                  </p>
                </div>
              </div>
              <div
                style={{
                  marginTop: "40%",
                  display: "flex",
                  justifyContent: "center",
                }}
                className="row"
              >
                <div className="form-group">
                  <h1 style={{ fontWeight: "bold", fontSize: "50px" }}>
                    OFFRE DE PRIX
                  </h1>
                </div>
              </div>
              <div
                style={{ display: "flex", justifyContent: "center" }}
                className="row"
              >
                <div className="form-group">
                  <h1 style={{ fontSize: "35px" }}>
                    Nos packs Social Media Management
                  </h1>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15%",
                }}
                className="row"
              >
                <div className="form-group">
                  <h1 style={{ fontSize: "35px" }}>Destiné à :</h1>
                </div>
              </div>
              <div
                style={{ display: "flex", justifyContent: "center" }}
                className="row"
              >
                <div className="form-group">
                  <h1 style={{ fontSize: "35px", fontWeight: "bold" }}>
                    {this.state.selectClient.label}
                  </h1>
                </div>
              </div>
            </div>
            {this.state.arrayPack.length == 1 ? (
              <div>
                <div className="pagebreak">
                  <div style={{ marginTop: "15%" }} className="row">
                    <div className="form-group">
                      <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                        I. Présentation de la société MTD GROUP :
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group">
                      <p style={{ fontSize: "25px" }}>
                        MTD GROUP, groupe créé en 2000, spécialisé en solution Internet
                        et media
                      </p>
                      <p style={{ fontSize: "25px" }}>
                        Il propose des services de création graphique, de
                        référencement, de création de portails, de logiciels standards
                        et personnalisés, d’applications web accessibles sur Internet.
                        Il assure un rôle de conseil, d’assistance et d’écoute à ses
                        clients partenaires.
                      </p>
                    </div>
                  </div>
                  <div style={{ marginTop: "3%" }} className="row">
                    <div className="form-group">
                      <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                        II. Présentation de l’offre :               {" "}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group">
                      <p style={{ fontSize: "25px" }}>
                        Nous avons l’honneur de vous proposer notre savoir-faire pour nos packs
                        Social Media Management.
                      </p>
                    </div>
                    {this.state.arrayPack.map((el) => (
                      <Fragment>
                        {el.pack == 1 ? (
                          <div
                            style={{ marginTop: "3%" }}
                            className="form-group"
                          >
                            <p
                              style={{ fontWeight: "bold", fontSize: "27.5px" }}
                            >
                              III. Le contenu du pack :
                            </p>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "27.5px",
                                color: "#4da6ff",
                              }}
                            >
                              1 - Pack Silver
                            </p>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "25px",
                                marginLeft: "5%",
                              }}
                            >
                              &#9633; Ce pack vous offre :
                            </p>
                            <ul style={{ fontSize: "25px" }}>
                              <li>1 réseau social</li>
                              <li>1 publication par semaine</li>
                              <li>Rédaction</li>
                              <li>Conception graphique</li>
                              <li>Recrutement de fans 30$ par mois</li>
                              <li>Sponsorisation des publications 20$</li>
                              <li>Rapport analytique chaque fin du mois</li>
                            </ul>
                          </div>
                        ) : null}
                        {el.pack == 2 ? (
                          <div
                            style={{ marginTop: "3%" }}
                            className="form-group"
                          >
                            <p
                              style={{ fontWeight: "bold", fontSize: "27.5px" }}
                            >
                              III. Le contenu du pack :
                            </p>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "27.5px",
                                color: "#4da6ff",
                              }}
                            >
                              1 - Pack Gold
                            </p>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "25px",
                                marginLeft: "5%",
                              }}
                            >
                              &#9633; Ce pack vous offre :
                            </p>
                            <ul style={{ fontSize: "25px" }}>
                              <li>2 réseaux sociaux (Facebook et Instagram)</li>
                              <li>2 publications par semaine</li>
                              <li>Rédaction</li>
                              <li>Conception graphique</li>
                              <li>Recrutement de fans 50$ par mois</li>
                              <li>Sponsorisation des publications 40$</li>
                              <li>Rapport analytique chaque fin du mois</li>
                            </ul>
                          </div>
                        ) : null}
                        {el.pack == 3 ? (
                          <div
                            style={{ marginTop: "3%" }}
                            className="form-group"
                          >
                            <p
                              style={{ fontWeight: "bold", fontSize: "27.5px" }}
                            >
                              III. Le contenu du pack :
                            </p>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "27.5px",
                                color: "#4da6ff",
                              }}
                            >
                              1 - Pack Platinum
                            </p>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "25px",
                                marginLeft: "5%",
                              }}
                            >
                              &#9633; Ce pack vous offre :
                            </p>
                            <ul style={{ fontSize: "25px" }}>
                              <li>
                                3 réseaux sociaux (Facebook, Instagram et LinkedIn)
                              </li>
                              <li>4 publications par semaine</li>
                              <li>Rédaction</li>
                              <li>Conception graphique</li>
                              <li>Recrutement de fans 70$ par mois</li>
                              <li>Sponsorisation des publications 160$</li>
                              <li>Rapport analytique chaque fin du mois</li>
                            </ul>
                          </div>
                        ) : null}
                        {el.pack == 4 ? (
                          <div
                            style={{ marginTop: "3%" }}
                            className="form-group"
                          >
                            <p
                              style={{ fontWeight: "bold", fontSize: "27.5px" }}
                            >
                              III. Le contenu du pack :
                            </p>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "27.5px",
                                color: "#4da6ff",
                              }}
                            >
                              1 -Pack Spéciale Instagram
                            </p>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "25px",
                                marginLeft: "5%",
                              }}
                            >
                              &#9633; Ce pack vous offre :
                            </p>
                            <ul style={{ fontSize: "25px" }}>
                              <li>
                                1 réseau social Instagram                     
                              </li>
                              <li>
                                {" "}
                                Planning et organisation de stratégie du contenu
                                pour le feed
                              </li>
                              <li>
                                {" "}
                                Publication de minimum 5 produits par semaine
                              </li>
                              <li>Rédaction de contenu</li>
                              <li>Conception graphique pour le feed</li>
                              <li>Conception graphique pour les stories</li>
                              <li>Création des highlights                 </li>
                              <li>Rapport fin du mois                   </li>
                            </ul>
                          </div>
                        ) : null}
                      </Fragment>
                    ))}
                  </div>
                </div>
                <div className="pagebreak">
                  <div style={{ marginTop: "15%" }} className="row">
                    <div className="form-group">
                      <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                        IV. Offre de Prix
                      </p>
                    </div>
                  </div>
                  {this.state.etatPrixPack == 1 ? (
                    <Fragment>
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          &#11162; L'établissement du service Social Media
                          Management est estimé à{" "}
                          <strong
                            style={{
                              color: this.state.colorText,
                              textDecoration: "line-through",
                            }}
                          >
                            {this.state.mntTotPackInit} Dinars Hors Taxes
                          </strong>{" "}
                          <strong style={{ color: this.state.colorText }}>
                            {this.state.mntTotPack} Dinars Hors Taxes.
                          </strong>{" "}
                          offerte pour la société{" "}
                          {this.state.selectClient.label}.
                        </p>
                      </div>
                      {this.state.selectOption.map((elem) => (
                        <div className="form-group">
                          <p style={{ fontSize: "25px" }}>
                            &#11162; L’addition d'un(e) {elem.options}{" "}
                            supplémentaire est estimée à{" "}
                            <strong style={{ color: this.state.colorText }}>
                              {elem.montant} Dinars Hors Taxe.
                            </strong>
                          </p>
                        </div>
                      ))}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          &#11162; L'établissement du service Social Media
                          Management est estimé à{" "}
                          <strong style={{ color: this.state.colorText }}>
                            {this.state.mntTotPack} Dinars Hors Taxes.
                          </strong>
                        </p>
                      </div>
                      {this.state.selectOption.map((elem) => (
                        <div className="form-group">
                          <p style={{ fontSize: "25px" }}>
                            &#11162; L’addition d'un(e) {elem.options}{" "}
                            supplémentaire est estimée à{" "}
                            <strong style={{ color: this.state.colorText }}>
                              {elem.montant} Dinars Hors Taxe.
                            </strong>
                          </p>
                        </div>
                      ))}
                    </Fragment>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: "110px",
                    }}
                    className="row"
                  >
                    <div style={{ textAlign: "end" }} className="form-group">
                      <p style={{ fontSize: "25px" }}>
                        Dans l’attente d’une réponse favorable,{" "}
                      </p>
                      <p style={{ fontSize: "25px" }}>
                        Veuillez agréer Monsieur l’expression{" "}
                      </p>
                      <p style={{ fontSize: "25px" }}>
                        De notre très haute considération{" "}
                      </p>
                      <p style={{ fontWeight: "bold", fontSize: "25px" }}>
                        <strong> Service Commercial </strong>
                        {" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : this.state.arrayPack.length == 2 ? (
              <div>
                <div className="pagebreak">
                  <div style={{ marginTop: "15%" }} className="row">
                    <div className="form-group">
                      <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                        I. Présentation de la société MTD GROUP :
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group">
                      <p style={{ fontSize: "25px" }}>
                        MTD GROUP, groupe créé en 2000, spécialisé en solution Internet
                        et media
                      </p>
                      <p style={{ fontSize: "25px" }}>
                        Il propose des services de création graphique, de
                        référencement, de création de portails, de logiciels standards
                        et personnalisés, d’applications web accessibles sur Internet.
                        Il assure un rôle de conseil, d’assistance et d’écoute à ses
                        clients partenaires.
                      </p>
                    </div>
                  </div>
                  <div style={{ marginTop: "3%" }} className="row">
                    <div className="form-group">
                      <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                        II. Présentation de l’offre :               {" "}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group">
                      <p style={{ fontSize: "25px" }}>
                        Nous avons l’honneur de vous proposer notre savoir-faire pour nos packs
                        Social Media Management.
                      </p>
                    </div>
                    <div style={{ marginTop: "3%" }} className="form-group">
                      {this.state.arrayPack[0].pack.includes(1) == true ? (
                        <div>
                          <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                            III. Le contenu du pack :
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "27.5px",
                              color: "#4da6ff",
                            }}
                          >
                            1 - Pack Silver
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "25px",
                              marginLeft: "5%",
                            }}
                          >
                            &#9633; Ce pack vous offre :
                          </p>
                          <ul style={{ fontSize: "25px" }}>
                            <li>1 réseau social</li>
                            <li>1 publication par semaine</li>
                            <li>Rédaction</li>
                            <li>Conception graphique</li>
                            <li>Recrutement de fans 30$ par mois</li>
                            <li>Sponsorisation des publications 20$</li>
                            <li>Rapport analytique chaque fin du mois</li>
                          </ul>
                        </div>
                      ) : null}
                      {this.state.arrayPack[0].pack.includes(2) == true ? (
                        <div>
                          <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                            III. Le contenu du pack :
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "27.5px",
                              color: "#4da6ff",
                            }}
                          >
                            2 - Pack Gold
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "25px",
                              marginLeft: "5%",
                            }}
                          >
                            &#9633; Ce pack vous offre :
                          </p>
                          <ul style={{ fontSize: "25px" }}>
                            <li>2 réseaux sociaux (Facebook et Instagram)</li>
                            <li>2 publications par semaine</li>
                            <li>Rédaction</li>
                            <li>Conception graphique</li>
                            <li>Recrutement de fans 50$ par mois</li>
                            <li>Sponsorisation des publications 40$</li>
                            <li>Rapport analytique chaque fin du mois</li>
                          </ul>
                        </div>
                      ) : null}
                      {this.state.arrayPack[0].pack.includes(3) == true ? (
                        <div>
                          <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                            III. Le contenu du pack :
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "27.5px",
                              color: "#4da6ff",
                            }}
                          >
                            1 - Pack Platinum
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "25px",
                              marginLeft: "5%",
                            }}
                          >
                            &#9633; Ce pack vous offre :
                          </p>
                          <ul style={{ fontSize: "25px" }}>
                            <li>
                              3 réseaux sociaux (Facebook, Instagram et LinkedIn)
                            </li>
                            <li>4 publications par semaine</li>
                            <li>Rédaction</li>
                            <li>Conception graphique</li>
                            <li>Recrutement de fans 70$ par mois</li>
                            <li>Sponsorisation des publications 160$</li>
                            <li>Rapport analytique chaque fin du mois</li>
                          </ul>
                        </div>
                      ) : null}
                      {this.state.arrayPack[0].pack.includes(4) == true ? (
                        <div>
                          <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                            III. Le contenu du pack :
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "27.5px",
                              color: "#4da6ff",
                            }}
                          >
                            1 -Pack Spéciale Instagram
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "25px",
                              marginLeft: "5%",
                            }}
                          >
                            &#9633; Ce pack vous offre :
                          </p>
                          <ul style={{ fontSize: "25px" }}>
                            <li>
                              1 réseau social Instagram                     
                            </li>
                            <li>
                              {" "}
                              Planning et organisation de stratégie du contenu
                              pour le feed
                            </li>
                            <li>
                              {" "}
                              Publication de minimum 5 produits par semaine
                            </li>
                            <li>Rédaction de contenu</li>
                            <li>Conception graphique pour le feed</li>
                            <li>Conception graphique pour les stories</li>
                            <li>Création des highlights                 </li>
                            <li>Rapport fin du mois                   </li>
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "15%" }} className="pagebreak">
                  {this.state.arrayPack[1].pack.includes(1) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        2 - Pack Silver
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>1 réseau social</li>
                        <li>1 publication par semaine</li>
                        <li>Rédaction</li>
                        <li>Conception graphique</li>
                        <li>Recrutement de fans 30$ par mois</li>
                        <li>Sponsorisation des publications 20$</li>
                        <li>Rapport analytique chaque fin du mois</li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[1].pack.includes(2) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        2 - Pack Gold
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>2 réseaux sociaux (Facebook et Instagram)</li>
                        <li>2 publications par semaine</li>
                        <li>Rédaction</li>
                        <li>Conception graphique</li>
                        <li>Recrutement de fans 50$ par mois</li>
                        <li>Sponsorisation des publications 40$</li>
                        <li>Rapport analytique chaque fin du mois</li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[1].pack.includes(3) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        2 - Pack Platinum
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>
                          3 réseaux sociaux (Facebook, Instagram et LinkedIn)
                        </li>
                        <li>4 publications par semaine</li>
                        <li>Rédaction</li>
                        <li>Conception graphique</li>
                        <li>Recrutement de fans 70$ par mois</li>
                        <li>Sponsorisation des publications 160$</li>
                        <li>Rapport analytique chaque fin du mois</li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[1].pack.includes(4) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        2 -Pack Spéciale Instagram
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>1 réseau social Instagram                     </li>
                        <li>
                          {" "}
                          Planning et organisation de stratégie du contenu pour
                          le feed
                        </li>
                        <li> Publication de minimum 5 produits par semaine</li>
                        <li>Rédaction de contenu</li>
                        <li>Conception graphique pour le feed</li>
                        <li>Conception graphique pour les stories</li>
                        <li>Création des highlights                 </li>
                        <li>Rapport fin du mois                   </li>
                      </ul>
                    </div>
                  ) : null}
                  <div style={{ marginTop: "5%" }} className="row">
                    <div className="form-group">
                      <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                        IV. Offre de Prix
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    {this.state.etatPrixPack == 1 ? (
                      <Fragment>
                        <div className="form-group">
                          <p style={{ fontSize: "25px" }}>
                            &#11162; L'établissement du service Social Media
                            Management est estimé à{" "}
                            <strong
                              style={{
                                color: this.state.colorText,
                                textDecoration: "line-through",
                              }}
                            >
                              {this.state.mntTotPackInit} Dinars Hors Taxes
                            </strong>{" "}
                            <strong style={{ color: this.state.colorText }}>
                              {this.state.mntTotPack} Dinars Hors Taxes.
                            </strong>{" "}
                            offerte pour la société{" "}
                            {this.state.selectClient.label}.
                          </p>
                        </div>
                        {this.state.selectOption.map((elem) => (
                          <div className="form-group">
                            <p style={{ fontSize: "25px" }}>
                              &#11162; L’addition d'un(e) {elem.options}{" "}
                              supplémentaire est estimée à{" "}
                              <strong style={{ color: this.state.colorText }}>
                                {elem.montant} Dinars Hors Taxe.
                              </strong>
                            </p>
                          </div>
                        ))}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="form-group">
                          <p style={{ fontSize: "25px" }}>
                            &#11162; L'établissement du service Social Media
                            Management est estimé à{" "}
                            <strong style={{ color: this.state.colorText }}>
                              {this.state.mntTotPack} Dinars Hors Taxes.
                            </strong>
                          </p>
                        </div>
                        {this.state.selectOption.map((elem) => (
                          <div className="form-group">
                            <p style={{ fontSize: "25px" }}>
                              &#11162; L’addition d'un(e) {elem.options}{" "}
                              supplémentaire est estimée à{" "}
                              <strong style={{ color: this.state.colorText }}>
                                {elem.montant} Dinars Hors Taxe.
                              </strong>
                            </p>
                          </div>
                        ))}
                      </Fragment>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: "110px",
                    }}
                    className="row"
                  >
                    <div style={{ textAlign: "end" }} className="form-group">
                      <p style={{ fontSize: "25px" }}>
                        Dans l’attente d’une réponse favorable,{" "}
                      </p>
                      <p style={{ fontSize: "25px" }}>
                        Veuillez agréer Monsieur l’expression{" "}
                      </p>
                      <p style={{ fontSize: "25px" }}>
                        De notre très haute considération{" "}
                      </p>
                      <p style={{ fontWeight: "bold", fontSize: "25px" }}>
                        <strong> Service Commercial </strong>
                        {" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : this.state.arrayPack.length == 3 ? (
              <div>
                <div className="pagebreak">
                  <div style={{ marginTop: "15%" }} className="row">
                    <div className="form-group">
                      <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                        I. Présentation de la société MTD GROUP :
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group">
                      <p style={{ fontSize: "25px" }}>
                        MTD GROUP, groupe créé en 2000, spécialisé en solution Internet
                        et media
                      </p>
                      <p style={{ fontSize: "25px" }}>
                        Il propose des services de création graphique, de
                        référencement, de création de portails, de logiciels standards
                        et personnalisés, d’applications web accessibles sur Internet.
                        Il assure un rôle de conseil, d’assistance et d’écoute à ses
                        clients partenaires.
                      </p>
                    </div>
                  </div>
                  <div style={{ marginTop: "3%" }} className="row">
                    <div className="form-group">
                      <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                        II. Présentation de l’offre :               {" "}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group">
                      <p style={{ fontSize: "25px" }}>
                        Nous avons l’honneur de vous proposer notre savoir-faire pour nos packs
                        Social Media Management.
                      </p>
                    </div>
                    <div style={{ marginTop: "3%" }} className="form-group">
                      {this.state.arrayPack[0].pack.includes(1) == true ? (
                        <div>
                          <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                            III. Le contenu du pack :
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "27.5px",
                              color: "#4da6ff",
                            }}
                          >
                            1 - Pack Silver
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "25px",
                              marginLeft: "5%",
                            }}
                          >
                            &#9633; Ce pack vous offre :
                          </p>
                          <ul style={{ fontSize: "25px" }}>
                            <li>1 réseau social</li>
                            <li>1 publication par semaine</li>
                            <li>Rédaction</li>
                            <li>Conception graphique</li>
                            <li>Recrutement de fans 30$ par mois</li>
                            <li>Sponsorisation des publications 20$</li>
                            <li>Rapport analytique chaque fin du mois</li>
                          </ul>
                        </div>
                      ) : null}
                      {this.state.arrayPack[0].pack.includes(2) == true ? (
                        <div>
                          <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                            III. Le contenu du pack :
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "27.5px",
                              color: "#4da6ff",
                            }}
                          >
                            1 - Pack Gold
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "25px",
                              marginLeft: "5%",
                            }}
                          >
                            &#9633; Ce pack vous offre :
                          </p>
                          <ul style={{ fontSize: "25px" }}>
                            <li>2 réseaux sociaux (Facebook et Instagram)</li>
                            <li>2 publications par semaine</li>
                            <li>Rédaction</li>
                            <li>Conception graphique</li>
                            <li>Recrutement de fans 50$ par mois</li>
                            <li>Sponsorisation des publications 40$</li>
                            <li>Rapport analytique chaque fin du mois</li>
                          </ul>
                        </div>
                      ) : null}
                      {this.state.arrayPack[0].pack.includes(3) == true ? (
                        <div>
                          <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                            III. Le contenu du pack :
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "27.5px",
                              color: "#4da6ff",
                            }}
                          >
                            1 - Pack Platinum
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "25px",
                              marginLeft: "5%",
                            }}
                          >
                            &#9633; Ce pack vous offre :
                          </p>
                          <ul style={{ fontSize: "25px" }}>
                            <li>
                              3 réseaux sociaux (Facebook, Instagram et LinkedIn)
                            </li>
                            <li>4 publications par semaine</li>
                            <li>Rédaction</li>
                            <li>Conception graphique</li>
                            <li>Recrutement de fans 70$ par mois</li>
                            <li>Sponsorisation des publications 160$</li>
                            <li>Rapport analytique chaque fin du mois</li>
                          </ul>
                        </div>
                      ) : null}
                      {this.state.arrayPack[0].pack.includes(4) == true ? (
                        <div>
                          <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                            III. Le contenu du pack :
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "27.5px",
                              color: "#4da6ff",
                            }}
                          >
                            1 -Pack Spéciale Instagram
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "25px",
                              marginLeft: "5%",
                            }}
                          >
                            &#9633; Ce pack vous offre :
                          </p>
                          <ul style={{ fontSize: "25px" }}>
                            <li>
                              1 réseau social Instagram                     
                            </li>
                            <li>
                              {" "}
                              Planning et organisation de stratégie du contenu
                              pour le feed
                            </li>
                            <li>
                              {" "}
                              Publication de minimum 5 produits par semaine
                            </li>
                            <li>Rédaction de contenu</li>
                            <li>Conception graphique pour le feed</li>
                            <li>Conception graphique pour les stories</li>
                            <li>Création des highlights                 </li>
                            <li>Rapport fin du mois                   </li>
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "15%" }} className="pagebreak">
                  {this.state.arrayPack[1].pack.includes(1) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        2 - Pack Silver
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>1 réseau social</li>
                        <li>1 publication par semaine</li>
                        <li>Rédaction</li>
                        <li>Conception graphique</li>
                        <li>Recrutement de fans 30$ par mois</li>
                        <li>Sponsorisation des publications 20$</li>
                        <li>Rapport analytique chaque fin du mois</li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[1].pack.includes(2) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        2 - Pack Gold
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>2 réseaux sociaux (Facebook et Instagram)</li>
                        <li>2 publications par semaine</li>
                        <li>Rédaction</li>
                        <li>Conception graphique</li>
                        <li>Recrutement de fans 50$ par mois</li>
                        <li>Sponsorisation des publications 40$</li>
                        <li>Rapport analytique chaque fin du mois</li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[1].pack.includes(3) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        2 - Pack Platinum
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>
                          3 réseaux sociaux (Facebook, Instagram et LinkedIn)
                        </li>
                        <li>4 publications par semaine</li>
                        <li>Rédaction</li>
                        <li>Conception graphique</li>
                        <li>Recrutement de fans 70$ par mois</li>
                        <li>Sponsorisation des publications 160$</li>
                        <li>Rapport analytique chaque fin du mois</li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[1].pack.includes(4) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        2 -Pack Spéciale Instagram
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>1 réseau social Instagram                     </li>
                        <li>
                          {" "}
                          Planning et organisation de stratégie du contenu pour
                          le feed
                        </li>
                        <li> Publication de minimum 5 produits par semaine</li>
                        <li>Rédaction de contenu</li>
                        <li>Conception graphique pour le feed</li>
                        <li>Conception graphique pour les stories</li>
                        <li>Création des highlights                 </li>
                        <li>Rapport fin du mois                   </li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[2].pack.includes(1) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        3 - Pack Silver
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>1 réseau social</li>
                        <li>1 publication par semaine</li>
                        <li>Rédaction</li>
                        <li>Conception graphique</li>
                        <li>Recrutement de fans 30$ par mois</li>
                        <li>Sponsorisation des publications 20$</li>
                        <li>Rapport analytique chaque fin du mois</li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[2].pack.includes(2) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        3 - Pack Gold
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>2 réseaux sociaux (Facebook et Instagram)</li>
                        <li>2 publications par semaine</li>
                        <li>Rédaction</li>
                        <li>Conception graphique</li>
                        <li>Recrutement de fans 50$ par mois</li>
                        <li>Sponsorisation des publications 40$</li>
                        <li>Rapport analytique chaque fin du mois</li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[2].pack.includes(3) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        3 - Pack Platinum
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>
                          3 réseaux sociaux (Facebook, Instagram et LinkedIn)
                        </li>
                        <li>4 publications par semaine</li>
                        <li>Rédaction</li>
                        <li>Conception graphique</li>
                        <li>Recrutement de fans 70$ par mois</li>
                        <li>Sponsorisation des publications 160$</li>
                        <li>Rapport analytique chaque fin du mois</li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[2].pack.includes(4) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        3 -Pack Spéciale Instagram
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>1 réseau social Instagram                     </li>
                        <li>
                          {" "}
                          Planning et organisation de stratégie du contenu pour
                          le feed
                        </li>
                        <li> Publication de minimum 5 produits par semaine</li>
                        <li>Rédaction de contenu</li>
                        <li>Conception graphique pour le feed</li>
                        <li>Conception graphique pour les stories</li>
                        <li>Création des highlights                 </li>
                        <li>Rapport fin du mois                   </li>
                      </ul>
                    </div>
                  ) : null}
                </div>
                <div className="pagebreak">
                  <div style={{ marginTop: "15%" }} className="row">
                    <div className="form-group">
                      <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                        IV. Offre de Prix
                      </p>
                    </div>
                  </div>
                  {this.state.etatPrixPack == 1 ? (
                    <Fragment>
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          &#11162; L'établissement du service Social Media
                          Management est estimé à{" "}
                          <strong
                            style={{
                              color: this.state.colorText,
                              textDecoration: "line-through",
                            }}
                          >
                            {this.state.mntTotPackInit} Dinars Hors Taxes
                          </strong>{" "}
                          <strong style={{ color: this.state.colorText }}>
                            {this.state.mntTotPack} Dinars Hors Taxes.
                          </strong>{" "}
                          offerte pour la société{" "}
                          {this.state.selectClient.label}.
                        </p>
                      </div>
                      {this.state.selectOption.map((elem) => (
                        <div className="form-group">
                          <p style={{ fontSize: "25px" }}>
                            &#11162; L’addition d'un(e) {elem.options}{" "}
                            supplémentaire est estimée à{" "}
                            <strong style={{ color: this.state.colorText }}>
                              {elem.montant} Dinars Hors Taxe.
                            </strong>
                          </p>
                        </div>
                      ))}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          &#11162; L'établissement du service Social Media
                          Management est estimé à{" "}
                          <strong style={{ color: this.state.colorText }}>
                            {this.state.mntTotPack} Dinars Hors Taxes.
                          </strong>
                        </p>
                      </div>
                      {this.state.selectOption.map((elem) => (
                        <div className="form-group">
                          <p style={{ fontSize: "25px" }}>
                            &#11162; L’addition d'un(e) {elem.options}{" "}
                            supplémentaire est estimée à{" "}
                            <strong style={{ color: this.state.colorText }}>
                              {elem.montant} Dinars Hors Taxe.
                            </strong>
                          </p>
                        </div>
                      ))}
                    </Fragment>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: "110px",
                    }}
                    className="row"
                  >
                    <div style={{ textAlign: "end" }} className="form-group">
                      <p style={{ fontSize: "25px" }}>
                        Dans l’attente d’une réponse favorable,{" "}
                      </p>
                      <p style={{ fontSize: "25px" }}>
                        Veuillez agréer Monsieur l’expression{" "}
                      </p>
                      <p style={{ fontSize: "25px" }}>
                        De notre très haute considération{" "}
                      </p>
                      <p style={{ fontWeight: "bold", fontSize: "25px" }}>
                        <strong> Service Commercial </strong>
                        {" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="pagebreak">
                  <div style={{ marginTop: "15%" }} className="row">
                    <div className="form-group">
                      <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                        I. Présentation de la société MTD GROUP :
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group">
                      <p style={{ fontSize: "25px" }}>
                        MTD GROUP, groupe créé en 2000, spécialisé en solution Internet
                        et media
                      </p>
                      <p style={{ fontSize: "25px" }}>
                        Il propose des services de création graphique, de
                        référencement, de création de portails, de logiciels standards
                        et personnalisés, d’applications web accessibles sur Internet.
                        Il assure un rôle de conseil, d’assistance et d’écoute à ses
                        clients partenaires.
                      </p>
                    </div>
                  </div>
                  <div style={{ marginTop: "3%" }} className="row">
                    <div className="form-group">
                      <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                        II. Présentation de l’offre :               {" "}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group">
                      <p style={{ fontSize: "25px" }}>
                        Nous avons l’honneur de vous proposer notre savoir-faire pour nos packs
                        Social Media Management.
                      </p>
                    </div>
                    <div style={{ marginTop: "3%" }} className="form-group">
                      {this.state.arrayPack[0].pack.includes(1) == true ? (
                        <div>
                          <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                            III. Le contenu du pack :
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "27.5px",
                              color: "#4da6ff",
                            }}
                          >
                            1 - Pack Silver
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "25px",
                              marginLeft: "5%",
                            }}
                          >
                            &#9633; Ce pack vous offre :
                          </p>
                          <ul style={{ fontSize: "25px" }}>
                            <li>1 réseau social</li>
                            <li>1 publication par semaine</li>
                            <li>Rédaction</li>
                            <li>Conception graphique</li>
                            <li>Recrutement de fans 30$ par mois</li>
                            <li>Sponsorisation des publications 20$</li>
                            <li>Rapport analytique chaque fin du mois</li>
                          </ul>
                        </div>
                      ) : null}
                      {this.state.arrayPack[0].pack.includes(2) == true ? (
                        <div>
                          <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                            III. Le contenu du pack :
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "27.5px",
                              color: "#4da6ff",
                            }}
                          >
                            1 - Pack Gold
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "25px",
                              marginLeft: "5%",
                            }}
                          >
                            &#9633; Ce pack vous offre :
                          </p>
                          <ul style={{ fontSize: "25px" }}>
                            <li>2 réseaux sociaux (Facebook et Instagram)</li>
                            <li>2 publications par semaine</li>
                            <li>Rédaction</li>
                            <li>Conception graphique</li>
                            <li>Recrutement de fans 50$ par mois</li>
                            <li>Sponsorisation des publications 40$</li>
                            <li>Rapport analytique chaque fin du mois</li>
                          </ul>
                        </div>
                      ) : null}
                      {this.state.arrayPack[0].pack.includes(3) == true ? (
                        <div>
                          <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                            III. Le contenu du pack :
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "27.5px",
                              color: "#4da6ff",
                            }}
                          >
                            1 - Pack Platinum
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "25px",
                              marginLeft: "5%",
                            }}
                          >
                            &#9633; Ce pack vous offre :
                          </p>
                          <ul style={{ fontSize: "25px" }}>
                            <li>
                              3 réseaux sociaux (Facebook, Instagram et LinkedIn)
                            </li>
                            <li>4 publications par semaine</li>
                            <li>Rédaction</li>
                            <li>Conception graphique</li>
                            <li>Recrutement de fans 70$ par mois</li>
                            <li>Sponsorisation des publications 160$</li>
                            <li>Rapport analytique chaque fin du mois</li>
                          </ul>
                        </div>
                      ) : null}
                      {this.state.arrayPack[0].pack.includes(4) == true ? (
                        <div>
                          <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                            III. Le contenu du pack :
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "27.5px",
                              color: "#4da6ff",
                            }}
                          >
                            1 -Pack Spéciale Instagram
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: "25px",
                              marginLeft: "5%",
                            }}
                          >
                            &#9633; Ce pack vous offre :
                          </p>
                          <ul style={{ fontSize: "25px" }}>
                            <li>
                              1 réseau social Instagram                     
                            </li>
                            <li>
                              {" "}
                              Planning et organisation de stratégie du contenu
                              pour le feed
                            </li>
                            <li>
                              {" "}
                              Publication de minimum 5 produits par semaine
                            </li>
                            <li>Rédaction de contenu</li>
                            <li>Conception graphique pour le feed</li>
                            <li>Conception graphique pour les stories</li>
                            <li>Création des highlights                 </li>
                            <li>Rapport fin du mois                   </li>
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "15%" }} className="pagebreak">
                  {this.state.arrayPack[1].pack.includes(1) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        2 - Pack Silver
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>1 réseau social</li>
                        <li>1 publication par semaine</li>
                        <li>Rédaction</li>
                        <li>Conception graphique</li>
                        <li>Recrutement de fans 30$ par mois</li>
                        <li>Sponsorisation des publications 20$</li>
                        <li>Rapport analytique chaque fin du mois</li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[1].pack.includes(2) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        2 - Pack Gold
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>2 réseaux sociaux (Facebook et Instagram)</li>
                        <li>2 publications par semaine</li>
                        <li>Rédaction</li>
                        <li>Conception graphique</li>
                        <li>Recrutement de fans 50$ par mois</li>
                        <li>Sponsorisation des publications 40$</li>
                        <li>Rapport analytique chaque fin du mois</li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[1].pack.includes(3) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        2 - Pack Platinum
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>
                          3 réseaux sociaux (Facebook, Instagram et LinkedIn)
                        </li>
                        <li>4 publications par semaine</li>
                        <li>Rédaction</li>
                        <li>Conception graphique</li>
                        <li>Recrutement de fans 70$ par mois</li>
                        <li>Sponsorisation des publications 160$</li>
                        <li>Rapport analytique chaque fin du mois</li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[1].pack.includes(4) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        2 -Pack Spéciale Instagram
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>1 réseau social Instagram                     </li>
                        <li>
                          {" "}
                          Planning et organisation de stratégie du contenu pour
                          le feed
                        </li>
                        <li> Publication de minimum 5 produits par semaine</li>
                        <li>Rédaction de contenu</li>
                        <li>Conception graphique pour le feed</li>
                        <li>Conception graphique pour les stories</li>
                        <li>Création des highlights                 </li>
                        <li>Rapport fin du mois                   </li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[2].pack.includes(1) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        3 - Pack Silver
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>1 réseau social</li>
                        <li>1 publication par semaine</li>
                        <li>Rédaction</li>
                        <li>Conception graphique</li>
                        <li>Recrutement de fans 30$ par mois</li>
                        <li>Sponsorisation des publications 20$</li>
                        <li>Rapport analytique chaque fin du mois</li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[2].pack.includes(2) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        3 - Pack Gold
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>2 réseaux sociaux (Facebook et Instagram)</li>
                        <li>2 publications par semaine</li>
                        <li>Rédaction</li>
                        <li>Conception graphique</li>
                        <li>Recrutement de fans 50$ par mois</li>
                        <li>Sponsorisation des publications 40$</li>
                        <li>Rapport analytique chaque fin du mois</li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[2].pack.includes(3) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        3 - Pack Platinum
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>
                          3 réseaux sociaux (Facebook, Instagram et LinkedIn)
                        </li>
                        <li>4 publications par semaine</li>
                        <li>Rédaction</li>
                        <li>Conception graphique</li>
                        <li>Recrutement de fans 70$ par mois</li>
                        <li>Sponsorisation des publications 160$</li>
                        <li>Rapport analytique chaque fin du mois</li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[2].pack.includes(4) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        3 -Pack Spéciale Instagram
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>1 réseau social Instagram                     </li>
                        <li>
                          {" "}
                          Planning et organisation de stratégie du contenu pour
                          le feed
                        </li>
                        <li> Publication de minimum 5 produits par semaine</li>
                        <li>Rédaction de contenu</li>
                        <li>Conception graphique pour le feed</li>
                        <li>Conception graphique pour les stories</li>
                        <li>Création des highlights                 </li>
                        <li>Rapport fin du mois                   </li>
                      </ul>
                    </div>
                  ) : null}
                </div>
                <div style={{ marginTop: "15%" }} className="pagebreak">
                  {this.state.arrayPack[3].pack.includes(1) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        4 - Pack Silver
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>1 réseau social</li>
                        <li>1 publication par semaine</li>
                        <li>Rédaction</li>
                        <li>Conception graphique</li>
                        <li>Recrutement de fans 30$ par mois</li>
                        <li>Sponsorisation des publications 20$</li>
                        <li>Rapport analytique chaque fin du mois</li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[3].pack.includes(2) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        4 - Pack Gold
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>2 réseaux sociaux (Facebook et Instagram)</li>
                        <li>2 publications par semaine</li>
                        <li>Rédaction</li>
                        <li>Conception graphique</li>
                        <li>Recrutement de fans 50$ par mois</li>
                        <li>Sponsorisation des publications 40$</li>
                        <li>Rapport analytique chaque fin du mois</li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[3].pack.includes(3) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        4 - Pack Platinum
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>
                          3 réseaux sociaux (Facebook, Instagram et LinkedIn)
                        </li>
                        <li>4 publications par semaine</li>
                        <li>Rédaction</li>
                        <li>Conception graphique</li>
                        <li>Recrutement de fans 70$ par mois</li>
                        <li>Sponsorisation des publications 160$</li>
                        <li>Rapport analytique chaque fin du mois</li>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.arrayPack[3].pack.includes(4) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        4 -Pack Spéciale Instagram
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          marginLeft: "5%",
                        }}
                      >
                        &#9633; Ce pack vous offre :
                      </p>
                      <ul style={{ fontSize: "25px" }}>
                        <li>1 réseau social Instagram                     </li>
                        <li>
                          {" "}
                          Planning et organisation de stratégie du contenu pour
                          le feed
                        </li>
                        <li> Publication de minimum 5 produits par semaine</li>
                        <li>Rédaction de contenu</li>
                        <li>Conception graphique pour le feed</li>
                        <li>Conception graphique pour les stories</li>
                        <li>Création des highlights                 </li>
                        <li>Rapport fin du mois                   </li>
                      </ul>
                    </div>
                  ) : null}
                  <div style={{ marginTop: "5%" }} className="row">
                    <div className="form-group">
                      <p style={{ fontWeight: "bold", fontSize: "27.5px" }}>
                        IV. Offre de Prix
                      </p>
                    </div>
                  </div>
                  {this.state.etatPrixPack == 1 ? (
                    <Fragment>
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          &#11162; L'établissement du service Social Media
                          Management est estimé à{" "}
                          <strong
                            style={{
                              color: this.state.colorText,
                              textDecoration: "line-through",
                            }}
                          >
                            {this.state.mntTotPackInit} Dinars Hors Taxes
                          </strong>{" "}
                          <strong style={{ color: this.state.colorText }}>
                            {this.state.mntTotPack} Dinars Hors Taxes.
                          </strong>{" "}
                          offerte pour la société{" "}
                          {this.state.selectClient.label}.
                        </p>
                      </div>
                      {this.state.selectOption.map((elem) => (
                        <div className="form-group">
                          <p style={{ fontSize: "25px" }}>
                            &#11162; L’addition d'un(e) {elem.options}{" "}
                            supplémentaire est estimée à{" "}
                            <strong style={{ color: this.state.colorText }}>
                              {elem.montant} Dinars Hors Taxe.
                            </strong>
                          </p>
                        </div>
                      ))}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="form-group">
                        <p style={{ fontSize: "25px" }}>
                          &#11162; L'établissement du service Social Media
                          Management est estimé à{" "}
                          <strong style={{ color: this.state.colorText }}>
                            {this.state.mntTotPack} Dinars Hors Taxes.
                          </strong>
                        </p>
                      </div>
                      {this.state.selectOption.map((elem) => (
                        <div className="form-group">
                          <p style={{ fontSize: "25px" }}>
                            &#11162; L’addition d'un(e) {elem.options}{" "}
                            supplémentaire est estimée à{" "}
                            <strong style={{ color: this.state.colorText }}>
                              {elem.montant} Dinars Hors Taxe.
                            </strong>
                          </p>
                        </div>
                      ))}
                    </Fragment>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: "110px",
                    }}
                    className="row"
                  >
                    <div style={{ textAlign: "end" }} className="form-group">
                      <p style={{ fontSize: "25px" }}>
                        Dans l’attente d’une réponse favorable,{" "}
                      </p>
                      <p style={{ fontSize: "25px" }}>
                        Veuillez agréer Monsieur l’expression{" "}
                      </p>
                      <p style={{ fontSize: "25px" }}>
                        De notre très haute considération{" "}
                      </p>
                      <p style={{ fontWeight: "bold", fontSize: "25px" }}>
                        <strong> Service Commercial </strong>
                        {" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <i
                id="print_me_not"
                style={{ fontSize: "30px", cursor: "pointer" }}
                onClick={this.print}
                class="fas fa-print"
              ></i>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(ImprContratOffre);
