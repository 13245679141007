import React, { Component } from "react";
import axios from "axios";
import jQuery from "jquery";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";

class ModifSociete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      societeId: "",
      nom: "",
      adresse: "",
      rib: "",
      mf: "",
      rc: "",
      tel: "",
      email: "",
      siteWeb: "",
      logo: null
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
      let societeId = this.props.location.state.societeId;
      axios
        .get(
          process.env.REACT_APP_API_URL +
          "Back_mtd/GetSocieteById.php?id=" +
          societeId
        )
        .then((res) => {
          console.log(res);
          this.setState({
            societeId: res.data.societeId,
            nom: res.data.nom,
            adresse: res.data.adresse,
            rib: res.data.rib,
            mf: res.data.mf,
            rc: res.data.rc,
            tel: res.data.tel,
            email: res.data.email,
            siteWeb: res.data.siteWeb
          });
        });
    }
  }

  modifNom = (event) => {
    this.setState({
      nom: event.target.value,
    });
  };

  modifAdresse = (event) => {
    this.setState({
      adresse: event.target.value,
    });
  };

  modifRib = (event) => {
    this.setState({
      rib: event.target.value,
    });
  };

  modifMf = (event) => {
    this.setState({
      mf: event.target.value,
    });
  };

  modifRc = (event) => {
    this.setState({
      rc: event.target.value,
    });
  };

  modifTel = (event) => {
    this.setState({
      tel: event.target.value,
    });
  };

  modifEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  modifSiteWeb = (event) => {
    this.setState({
      siteWeb: event.target.value,
    });
  };

  modifLogo(e) {
    this.setState({ logo: e.target.files })
  }

  modif = () => {
    var logo = '';
    if (this.state.logo != null) {
      logo = process.env.REACT_APP_API_URL + "images/logo_societe/" + this.state.logo[0].name
    }
    jQuery.ajax({
      url:
        process.env.REACT_APP_API_URL +
        "Back_mtd/UpdSociete.php?id=" +
        this.state.societeId,
      type: "POST",
      data: {
        nom: this.state.nom,
        adresse: this.state.adresse,
        rib: this.state.rib,
        mf: this.state.mf,
        rc: this.state.rc,
        tel: this.state.tel,
        email: this.state.email,
        siteWeb: this.state.siteWeb,
        logo: logo
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          if (this.state.logo != null) {
            let files = this.state.logo;
            console.warn('data files', files)
            let reader = new FileReader();
            reader.readAsDataURL(files[0])
            reader.onload = (e) => {
              console.log(e)
              jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mtd/UploadLogoSociete.php',
                type: 'POST',
                data: {
                  file: e.target.result,
                  nomfile: this.state.logo[0].name
                },
                dataType: 'json',
                success: (code_html, statut) => {
                }
              }
              )
            }
            this.props.history.push("/Societes");
          }
          else {
            this.props.history.push("/Societes");
          }
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title"> Modification Societe </h3>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Nom</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.nom}
                        onChange={this.modifNom}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Adress</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.adresse}
                        onChange={this.modifAdresse}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>RIB</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.rib}
                        onChange={this.modifRib}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Matricule fiscale</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.mf}
                        onChange={this.modifMf}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Registre de commerce</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Registre de commerce"
                        value={this.state.rc}
                        onChange={this.modifRc}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Tel</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.tel}
                        onChange={this.modifTel}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.email}
                        onChange={this.modifEmail}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Site Web</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.siteWeb}
                        onChange={this.modifSiteWeb}
                        style={{
                          borderRadius: "5px",
                          border: "solid 1px #B3B3B3",
                        }}
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Logo</label>
                      <input type="file" class="form-control" name="file" onChange={(e) => this.modifLogo(e)} />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      style={{ borderRadius: "5px" }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.modif}
                    >
                      Confirmer
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ModifSociete);
