import React, { Component } from "react";
import axios from "axios";
import jQuery from "jquery";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

class ModifOffre extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offreId: '',
            nom: ""
        };
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem("userPermissions")) != null) {
            var offreId = this.props.location.state.offreId;
            axios
                .get(
                    process.env.REACT_APP_API_URL +
                    "Back_mtd/GetOffreById.php?id=" +
                    offreId
                )
                .then((res) => {
                    console.log(res);
                    this.setState({
                        offreId: offreId,
                        nom: res.data.nom
                    });
                });
        }
    }

    modifOffre = (event) => {
        this.setState({
            nom: event.target.value,
        });
    };

    modif = () => {
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + "Back_mtd/UpdOffre.php?id=" +
                this.state.offreId,
            type: "POST",
            data: {
                nom: this.state.nom
            },
            dataType: "json",
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push("/Offres");
                } else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            },
        });
    };

    render() {
        return (
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="page-header">
                        <h3 className="page-title"> Modification offre </h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 grid-margin">
                            <div className="card">
                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Offre</label>
                                            <input
                                                value={this.state.nom}
                                                type="text"
                                                className="form-control"
                                                onChange={this.modifOffre}
                                                style={{
                                                    borderRadius: "5px",
                                                    border: "solid 1px #B3B3B3",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <button
                                            style={{ borderRadius: "5px" }}
                                            type="button"
                                            className="btn btn-success"
                                            onClick={this.modif}
                                        >
                                            Confirmer
                  </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ModifOffre);