import React, { Component, Fragment } from "react";
import jQuery from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import "./paginate.css";

class ImprContrat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adresseSociete: "",
      societe: "",
      mfSociete: "",
      commercial: "",
      qualiteCom: "",
      emailCom: "",
      telCom: "",
      client: "",
      adresseClient: "",
      villeClient: "",
      mfClient: "",
      responsable: "",
      fonction: "",
      telClient: "",
      emailClient: "",
      prix: "",
      tauxRemise: "",
      prixFinal: "",
      paiement: [],
      delais: "",
      offres: "",
      fraisRes: "",
      fraisRef: "",
      tva: "",
      retenue: "",
      rubrique: [],
      langue: "",
      sousTotHT: "",
      mntTva: "",
      totalTtc: "",
      retenueSource: "",
      netPayer: "",
      options: [],
      yearAfter: "",
      villeContrat: "",
      pack: [],
      prixPack: "",
      nbrMois: "",
      dateSignContrat: "",
      mntTotCom: "",
      offreId: "",
    };
  }
  componentDidMount() {
    var url = window.location.href;
    var arrayContrat = url.split("=");
    var bonComId = arrayContrat[1];
    //
    var yearAfter = new Date().getFullYear();
    //
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mtd/GetInfoContrat.php",
      type: "POST",
      data: {
        bonComId: bonComId,
      },
      dataType: "json",
      success: (code_html, statut) => {
        this.setState(
          {
            adresseSociete: code_html.adresseSociete,
            societe: code_html.societe,
            mfSociete: code_html.mfSociete,
            commercial: code_html.commercial,
            qualiteCom: code_html.qualiteCom,
            emailCom: code_html.emailCom,
            telCom: code_html.telCom,
            client: code_html.client,
            adresseClient: code_html.adresseClient,
            villeClient: code_html.villeClient,
            mfClient: code_html.mfClient,
            responsable: code_html.responsable,
            fonction: code_html.fonction,
            telClient: code_html.telClient,
            emailClient: code_html.emailClient,
            prix: code_html.prix,
            tauxRemise: code_html.tauxRemise,
            prixFinal: code_html.prixFinal,
            paiement: code_html.paiement,
            delais: code_html.delais,
            offres: code_html.offres,
            fraisRes: code_html.fraisRes,
            fraisRef: code_html.fraisRef,
            tva: code_html.tva,
            retenue: code_html.retenue,
            date: code_html.date,
            rubrique: code_html.rubrique,
            langue: code_html.langue,
            options: code_html.options,
            villeContrat: code_html.villeContrat,
            yearAfter: parseInt(yearAfter) + 1,
            pack: code_html.pack,
            prixPack: code_html.prixPack,
            nbrMois: code_html.mois,
            dateSignContrat: code_html.date.substr(0, 5),
            mntTotCom: code_html.mntTotCom,
            offreId: code_html.offreId,
          },
          () => {
            this.setState(
              {
                totalMontant: this.state.options
                  .map((el) => parseFloat(el.montant))
                  .reduce((a, b) => a + b, 0),
              },
              () => {
                if (this.state.prixFinal == "") {
                  this.setState(
                    {
                      prixFinal: 0,
                    },
                    () => {
                      var sousTotHT =
                        parseFloat(this.state.totalMontant) +
                        parseFloat(this.state.prixFinal) +
                        parseFloat(this.state.prixPack) * this.state.nbrMois +
                        parseFloat(this.state.mntTotCom);
                      this.setState(
                        {
                          sousTotHT: sousTotHT,
                        },
                        () => {
                          var mntTva =
                            (this.state.sousTotHT *
                              parseFloat(this.state.tva)) /
                            100;
                          this.setState(
                            {
                              mntTva: mntTva,
                              totalTtc: mntTva + this.state.sousTotHT,
                            },
                            () => {
                              var retenueSource =
                                (this.state.totalTtc *
                                  parseFloat(this.state.retenue)) /
                                100;
                              this.setState(
                                {
                                  retenueSource: retenueSource,
                                },
                                () => {
                                  var netPayer =
                                    this.state.totalTtc -
                                    this.state.retenueSource;
                                  this.setState({
                                    netPayer: netPayer.toFixed(2),
                                  });
                                }
                              );
                            }
                          );
                        }
                      );
                    }
                  );
                } else {
                  var sousTotHT =
                    parseFloat(this.state.totalMontant) +
                    parseFloat(this.state.prixFinal) +
                    parseFloat(this.state.prixPack) * this.state.nbrMois +
                    parseFloat(this.state.mntTotCom);
                  this.setState(
                    {
                      sousTotHT: sousTotHT,
                    },
                    () => {
                      var mntTva =
                        (this.state.sousTotHT * parseFloat(this.state.tva)) /
                        100;
                      this.setState(
                        {
                          mntTva: mntTva,
                          totalTtc: mntTva + this.state.sousTotHT,
                        },
                        () => {
                          var retenueSource =
                            (this.state.totalTtc *
                              parseFloat(this.state.retenue)) /
                            100;
                          this.setState(
                            {
                              retenueSource: retenueSource,
                            },
                            () => {
                              var netPayer =
                                this.state.totalTtc - this.state.retenueSource;
                              this.setState({
                                netPayer: netPayer.toFixed(2),
                              });
                            }
                          );
                        }
                      );
                    }
                  );
                }
              }
            );
          }
        );
      },
    });
  }

  print = () => {
    window.print();
  };

  render() {
    return (
      <div style={{ marginLeft: "7%", marginRight: "7%", color: "black" }}>
        {this.state.prixPack > 0 ? (
          <Fragment>
            <div className="pagebreak">
              <div style={{ marginTop: "5%" }}>
                <h1
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "30px",
                  }}
                >
                  Contrat
                </h1>
              </div>
              <div style={{ marginTop: "5%" }}>
                <p className="xxx">Entre les soussignés :</p>
              </div>
              <div>
                <p
                  className="xxx"
                  style={{
                    marginTop: "2%",
                    fontWeight: "bold",
                    fontSize: "25px",
                  }}
                >
                  La société {this.state.societe}
                </p>
              </div>
              <div style={{ marginTop: "2%" }}>
                <p className="xxx">
                  Adresse : {this.state.adresseSociete} SFAX
                </p>
                <p className="xxx">
                  Immatriculée au registre du commerce et des sociétés sous le
                  numéro d'immatriculation {this.state.mfSociete}, représentée
                  en la personne de <strong> {this.state.commercial} </strong>{" "}
                  en sa qualité de {this.state.qualiteCom}.
                </p>
              </div>
              <div>
                <p className="xxx">Email : {this.state.emailCom} </p>
              </div>
              <div>
                <p className="xxx">Mobile : {this.state.telCom} </p>
              </div>
              <div>
                <p className="xxx">
                  Ci-après désigné comme « le Concepteur »,{" "}
                </p>
              </div>
              <div>
                <p
                  style={{
                    marginTop: "2%",
                    fontWeight: "bold",
                    fontSize: "25px",
                  }}
                >
                  {" "}
                  Et La société {this.state.client}
                </p>
              </div>
              <div style={{ marginTop: "2%" }}>
                <p className="xxx">
                  Adresse : {this.state.adresseClient} {this.state.villeClient}
                </p>
                <p className="xxx">
                  Représentée en la personne de{" "}
                  <strong>Mr/Mme {this.state.responsable}</strong> en sa
                  qualité de {this.state.fonction}.
                </p>
              </div>
              <div>
                <p className="xxx">Email : {this.state.emailClient} </p>
              </div>
              <div>
                <p className="xxx">Tel. : {this.state.telClient} </p>
              </div>
              <div>
                <p className="xxx">Ci-après désigné comme « le Client ». </p>
              </div>
              <div style={{ marginTop: "3%" }}>
                <h3
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    fontSize: "30px",
                  }}
                >
                  Bon de commande
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxx">
                  Le présent contrat constitue un bon de commande définitif.
                </p>
              </div>
              <div style={{ marginTop: "3%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  PRÉAMBULE :
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxx">Le Client demande à disposer :</p>
              </div>
              <div>
                <p className="xxx">
                  &#11162; Service Social Media Management pendant{" "}
                  {this.state.nbrMois} mois ;
                </p>
              </div>
            </div>
            <div
              style={{ marginTop: "6%", marginBottom: "10%" }}
              className="pagebreak"
            >
              <div>
                <p className="xxx">
                  Le Prestataire accepte les conditions stipulées dans le
                  présent contrat pour la réalisation d’un service Social Media
                  Management pendant {this.state.nbrMois} mois.
                </p>
                <p className="xxx">Les parties conviennent ce qui suit :</p>
              </div>
              <div style={{ marginTop: "2.5%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  ARTICLE 1 : Objet du contrat
                </h3>
              </div>
              <div style={{ marginTop: "2.5%" }}>
                <p className="xxx">
                  <strong>Le Concepteur</strong> créera pour le compte du{" "}
                  <strong>client</strong> un service de Social Media Management
                  conformément aux conditions définies par le présent contrat,
                  moyennant rémunération.
                </p>
              </div>
              <div style={{ marginTop: "2.5%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  ARTICLE 2 : Missions du concepteur
                </h3>
              </div>
              <div style={{ marginTop: "2.5%" }}>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  Le pack du service de marketing digital contient :
                </p>
              </div>
              {this.state.pack[0].value.includes(1) == true ? (
                <div>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "27.5px",
                      color: "#4da6ff",
                    }}
                  >
                    1 - Pack Silver
                  </p>
                  <ul style={{ fontSize: "25px", fontWeight: "bold" }}>
                    <p className="xxx">&#11162; 1 réseau social;</p>
                    <p className="xxx">&#11162; 1 publication par semaine;</p>
                    <p className="xxx">&#11162; Rédaction ;</p>
                    <p className="xxx">&#11162; Conception graphique ;</p>
                    <p className="xxx">
                      &#11162; Recrutement de fans 30$ par mois;
                    </p>
                    <p className="xxx">
                      &#11162; Sponsorisation des publications 20$;
                    </p>
                    <p className="xxx">
                      &#11162;Rapport analytique chaque fin du mois
                    </p>
                  </ul>
                </div>
              ) : null}
              {this.state.pack[0].value.includes(2) == true ? (
                <div>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "27.5px",
                      color: "#4da6ff",
                    }}
                  >
                    1 - Pack Gold
                  </p>
                  <ul style={{ fontSize: "25px", fontWeight: "bold" }}>
                    <p className="xxx">
                      &#11162; 2 réseaux sociaux (Facebook et Instagram)
                    </p>
                    <p className="xxx">&#11162; 2 publications par semaine;</p>
                    <p className="xxx">&#11162; Rédaction ;</p>
                    <p className="xxx">&#11162; Conception graphique ;</p>
                    <p className="xxx">
                      &#11162; Recrutement de fans 50$ par mois
                    </p>
                    <p className="xxx">
                      &#11162; Sponsorisation des publications 40$
                    </p>
                    <p className="xxx">
                      &#11162; Rapport analytique chaque fin du mois
                    </p>
                  </ul>
                </div>
              ) : null}
              {this.state.pack[0].value.includes(3) == true ? (
                <div>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "27.5px",
                      color: "#4da6ff",
                    }}
                  >
                    1 - Pack Platinum
                  </p>
                  <ul style={{ fontSize: "25px", fontWeight: "bold" }}>
                    <p className="xxx">
                      &#11162;
                      3 réseaux sociaux (Facebook, Instagram et LinkedIn)
                    </p>
                    <p className="xxx">&#11162; 4 publications par semaine;</p>
                    <p className="xxx">&#11162; Rédaction ;</p>
                    <p className="xxx">&#11162; Conception graphique ;</p>
                    <p className="xxx">
                      &#11162; Recrutement de fans 70$ par mois;
                    </p>
                    <p className="xxx">
                      &#11162; Sponsorisation des publications 160$;
                    </p>
                    <p className="xxx">
                      &#11162; Rapport analytique chaque fin du mois
                    </p>
                  </ul>
                </div>
              ) : null}
              {this.state.pack[0].value.includes(4) == true ? (
                <div>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "27.5px",
                      color: "#4da6ff",
                    }}
                  >
                    1 - Pack special Instagram
                  </p>
                  <ul style={{ fontSize: "25px", fontWeight: "bold" }}>
                    <p className="xxx">&#11162; 1 réseau social Instagram</p>
                    <p className="xxx">
                      &#11162; Planning et organisation de stratégie du contenu
                      pour le feed
                    </p>
                    <p className="xxx">
                      &#11162; Publication de minimum 5 produits par semaine
                    </p>
                    <p className="xxx">&#11162; Rédaction de contenu;</p>
                    <p className="xxx">
                      &#11162; Conception graphique pour le feed
                    </p>
                    <p className="xxx">
                      &#11162; Conception graphique pour les stories
                    </p>
                    <p className="xxx">&#11162; Création des highlights</p>
                    <p className="xxx">&#11162; Rapport fin du mois</p>
                  </ul>
                </div>
              ) : null}
              {this.state.pack.length > 1 ? (
                <div>
                  {this.state.pack[1].value.includes(1) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        2 - Pack Silver
                      </p>
                      <ul style={{ fontSize: "25px", fontWeight: "bold" }}>
                        <p className="xxx">&#11162; 1 réseau social;</p>
                        <p className="xxx">
                          &#11162; 1 publication par semaine;
                        </p>
                        <p className="xxx">&#11162; Rédaction ;</p>
                        <p className="xxx">&#11162; Conception graphique ;</p>
                        <p className="xxx">
                          &#11162; Recrutement de fans 30$ par mois;
                        </p>
                        <p className="xxx">
                          &#11162; Sponsorisation des publications 20$;
                        </p>
                        <p className="xxx">
                          &#11162;Rapport analytique chaque fin du mois
                        </p>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.pack[1].value.includes(2) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        2 - Pack Gold
                      </p>
                      <ul style={{ fontSize: "25px", fontWeight: "bold" }}>
                        <p className="xxx">
                          &#11162; 2 réseaux sociaux (Facebook et Instagram)
                        </p>
                        <p className="xxx">
                          &#11162; 2 publications par semaine;
                        </p>
                        <p className="xxx">&#11162; Rédaction ;</p>
                        <p className="xxx">&#11162; Conception graphique ;</p>
                        <p className="xxx">
                          &#11162; Recrutement de fans 50$ par mois
                        </p>
                        <p className="xxx">
                          &#11162; Sponsorisation des publications 40$
                        </p>
                        <p className="xxx">
                          &#11162; Rapport analytique chaque fin du mois
                        </p>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.pack[1].value.includes(3) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        2 - Pack Platinum
                      </p>
                      <ul style={{ fontSize: "25px", fontWeight: "bold" }}>
                        <p className="xxx">
                          &#11162;
                          3 réseaux sociaux (Facebook, Instagram et LinkedIn)
                        </p>
                        <p className="xxx">
                          &#11162; 4 publications par semaine;
                        </p>
                        <p className="xxx">&#11162; Rédaction ;</p>
                        <p className="xxx">&#11162; Conception graphique ;</p>
                        <p className="xxx">
                          &#11162; Recrutement de fans 70$ par mois;
                        </p>
                        <p className="xxx">
                          &#11162; Sponsorisation des publications 160$;
                        </p>
                        <p className="xxx">
                          &#11162; Rapport analytique chaque fin du mois
                        </p>
                      </ul>
                    </div>
                  ) : null}
                  {this.state.pack[1].value.includes(4) == true ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "27.5px",
                          color: "#4da6ff",
                        }}
                      >
                        2 - Pack Special Instagram
                      </p>
                      <ul style={{ fontSize: "25px", fontWeight: "bold" }}>
                        <p className="xxx">
                          &#11162; 1 réseau social Instagram
                        </p>
                        <p className="xxx">
                          &#11162; Planning et organisation de stratégie du
                          contenu pour le feed
                        </p>
                        <p className="xxx">
                          &#11162; Publication de minimum 5 produits par semaine
                        </p>
                        <p className="xxx">&#11162; Rédaction de contenu;</p>
                        <p className="xxx">
                          &#11162; Conception graphique pour le feed
                        </p>
                        <p className="xxx">
                          &#11162; Conception graphique pour les stories
                        </p>
                        <p className="xxx">&#11162; Création des highlights</p>
                        <p className="xxx">&#11162; Rapport fin du mois</p>
                      </ul>
                    </div>
                  ) : null}{" "}
                </div>
              ) : null}
            </div>
            <div
              style={{ marginTop: "10%", marginBottom: "10%" }}
              className="pagebreak"
            >
              <div style={{ marginTop: "3%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  ARTICLE 3 : Prix et Modalités de paiement
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxxx">
                  Le Client procédera au paiement de{" "}
                  <strong>
                    {" "}
                    {this.state.sousTotHT} Dinars Hors Taxe soit{" "}
                    {this.state.totalTtc} Dinars TTC
                  </strong>{" "}
                  Selon le cas:
                </p>
              </div>
              <div style={{ marginLeft: "2%" }}>
                {this.state.paiement.map((el) => (
                  <p className="xxx">
                    &#9679;<strong>{el.mnt} DT</strong> {el.motif}
                  </p>
                ))}
              </div>
              <div>
                <p className="xxx">
                  Le Client respectera l'ensemble de la législation en vigueur
                  relative à l'informatique, aux fichiers et aux libertés.
                </p>
              </div>
              <div style={{ marginTop: "3%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  ARTICLE 4: Litige et juridiction compétente
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxx">
                  En cas de différend, les parties conviennent de rechercher une
                  solution amiable. Si la démarche échoue, le litige sera
                  tranché par le Tribunal de Grande Instance de Tunis.
                </p>
              </div>
              <div>
                <p className="xxx">
                  Fait le {this.state.date} à {this.state.villeContrat}
                </p>
                <p className="xxx">
                  Signature précédée de la mention «lu et approuvé » :
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "7%",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <p style={{ fontSize: "22px", fontWeight: "bold" }}>
                    La société {this.state.societe}
                  </p>
                  <p style={{ fontSize: "22px", fontWeight: "bold" }}>
                    Mr. {this.state.commercial}
                  </p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <p style={{ fontSize: "22px", fontWeight: "bold" }}>
                    La société {this.state.client}
                  </p>
                  <p style={{ fontSize: "22px", fontWeight: "bold" }}>
                    Mr. {this.state.responsable}
                  </p>
                </div>
              </div>
            </div>
          </Fragment>
        ) : this.state.offreId == 7 ? (
          <Fragment>
            <div className="pagebreak">
              <div style={{ marginTop: "5%" }}>
                <h1
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "30px",
                  }}
                >
                  Contrat
                </h1>
              </div>
              <div style={{ marginTop: "5%" }}>
                <p className="xxx">Entre les soussignés :</p>
              </div>
              <div>
                <p
                  className="xxx"
                  style={{
                    marginTop: "2%",
                    fontWeight: "bold",
                    fontSize: "25px",
                  }}
                >
                  La société {this.state.societe}
                </p>
              </div>
              <div style={{ marginTop: "2%" }}>
                <p className="xxx">
                  Adresse : {this.state.adresseSociete} SFAX
                </p>
                <p className="xxx">
                  Immatriculée au registre du commerce et des sociétés sous le
                  numéro d'immatriculation {this.state.mfSociete}, représentée
                  en la personne de <strong> {this.state.commercial} </strong>{" "}
                  en sa qualité de {this.state.qualiteCom}.
                </p>
              </div>
              <div>
                <p className="xxx">Email : {this.state.emailCom} </p>
              </div>
              <div>
                <p className="xxx">Mobile : {this.state.telCom} </p>
              </div>
              <div>
                <p className="xxx">
                  Ci-après désigné comme « le Concepteur »,{" "}
                </p>
              </div>
              <div>
                <p
                  style={{
                    marginTop: "2%",
                    fontWeight: "bold",
                    fontSize: "25px",
                  }}
                >
                  {" "}
                  Et La société {this.state.client}
                </p>
              </div>
              <div style={{ marginTop: "2%" }}>
                <p className="xxx">
                  Adresse : {this.state.adresseClient} {this.state.villeClient}
                </p>
                <p className="xxx">
                  Représentée en la personne de{" "}
                  <strong>Mr/Mme {this.state.responsable}</strong> en sa
                  qualité de {this.state.fonction}.
                </p>
              </div>
              <div>
                <p className="xxx">Email : {this.state.emailClient} </p>
              </div>
              <div>
                <p className="xxx">Tel. : {this.state.telClient} </p>
              </div>
              <div>
                <p className="xxx">Ci-après désigné comme « le Client ». </p>
              </div>
              <div style={{ marginTop: "3%" }}>
                <h3
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    fontSize: "30px",
                  }}
                >
                  Bon de commande
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxx">
                  Le présent contrat constitue un bon de commande définitif.
                </p>
              </div>
              <div style={{ marginTop: "3%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  PRÉAMBULE :
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxx">Le Client demande à disposer :</p>
              </div>
              <div>
                <p className="xxx">
                  &#11162; Développement d’une application mobile MFV.
                </p>
              </div>
              <div>
                <p className="xxx">
                  Le Prestataire accepte les conditions stipulées dans le
                  présent contrat pour la réalisation et le développement d’une
                  application mobile MFV. Les parties conviennent ce qui suit :
                </p>
              </div>
            </div>
            <div
              style={{ marginTop: "10%", marginBottom: "10%" }}
              className="pagebreak"
            >
              <div style={{ marginTop: "3%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  ARTICLE 1 : Objet du contrat
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxx">
                  <strong>Le Concepteur</strong> créera pour le compte du{" "}
                  <strong>client</strong> une application mobile avec une
                  interface web conformément aux conditions définies par le
                  présent contrat, moyennant rémunération.
                </p>
              </div>
              <div style={{ marginTop: "3%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  ARTICLE 2 : Missions du concepteur
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p
                  style={{ color: "grey", fontWeight: "bold" }}
                  className="xxx"
                >
                  &#11162; La version mobile contient les modules suivants :
                </p>
              </div>
              <div style={{ marginLeft: "2%" }}>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  &#11162; Passer commandes.
                </p>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  &#11162; Liste des commandes.
                </p>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  &#11162; Règlements.
                </p>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  &#11162; Clients (liste des clients, ajout des nouveaux
                  clients par géolocalisation).
                </p>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  &#11162; Visite clients.
                </p>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  &#11162; Liste Règlements.
                </p>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  &#11162; Facture.
                </p>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  &#11162; Statistique.
                </p>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p
                  style={{ color: "grey", fontWeight: "bold" }}
                  className="xxx"
                >
                  &#11162; La version mobile contient les modules suivants :
                </p>
              </div>
              <div style={{ marginLeft: "2%" }}>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  &#11162; Paramétrage des commerciaux.
                </p>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  &#11162; Paramétrage des objectifs commerciaux.
                </p>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  &#11162; Paramétrage E-mail, envoie, commande.
                </p>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  &#11162; Interface clients.
                </p>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  &#11162; Interface commandes.
                </p>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  &#11162; Interface articles.
                </p>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  &#11162; Interface règlements.
                </p>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  &#11162; Interface factures
                </p>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  &#11162; Rapport de visite (récupéré par l’application des
                  commerciaux).
                </p>
                <p style={{ fontWeight: "bold" }} className="xxx">
                  &#11162; Positions commercial (récupéré par l’application des
                  commerciaux toutes les 30 mins).
                </p>
              </div>
            </div>
            <div
              style={{ marginTop: "10%", marginBottom: "10%" }}
              className="pagebreak"
            >
              <p style={{ fontWeight: "bold" }} className="xxx">
                &#11162; Statistique :
              </p>
              <div style={{ marginLeft: "3%", fontWeight: "bold" }}>
                <p style={{ fontSize: "24px" }}>
                  &#9679; Chiffre d’affaires par commercial.
                </p>
                <p style={{ fontSize: "24px" }}>
                  &#9679; Chiffre d’affaires par article.
                </p>
                <p style={{ fontSize: "24px" }}>
                  &#9679; Chiffre d’affaires par famille de produits.
                </p>
                <p style={{ fontSize: "24px" }}>
                  &#9679; Commandes par commercial.
                </p>
                <p style={{ fontSize: "24px" }}>
                  &#9679; Commande par famille.
                </p>
                <p style={{ fontSize: "24px" }}>
                  &#9679; Chiffre d’affaires par article par commercial.
                </p>
              </div>
              <div style={{ marginTop: "3%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  ARTICLE 3 : Prix et Modalités de paiement
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxxx">
                  Le Client procédera au paiement de{" "}
                  <strong>
                    {" "}
                    {this.state.sousTotHT} Dinars Hors Taxe soit{" "}
                    {this.state.totalTtc} Dinars TTC
                  </strong>{" "}
                  Selon le cas:
                </p>
              </div>
              <div>
                <p className="xxx">
                  Le Client procède à un paiement d’un somme de{" "}
                  <strong> {this.state.totalTtc} Dinars TTC</strong> répartis
                  comme suit:
                </p>
              </div>
              <div style={{ marginLeft: "2%" }}>
                {this.state.paiement.map((el) => (
                  <p className="xxx">
                    &#9679;{el.pourcentage} <strong>{el.mnt} DT</strong>{" "}
                    {el.motif}
                  </p>
                ))}
              </div>
              <div style={{ marginTop: "3%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  ARTICLE 4 : Hébergement du logiciel web{" "}
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxx">
                  &#9679; Les frais mensuels d’hébergement sur nos Cloud et
                  maintenance de l’application sont estimés à{" "}
                  <strong>
                    {" "}
                    {this.state.fraisRes} Dinars Hors Taxe / mois.{" "}
                  </strong>
                </p>
              </div>
              <div style={{ marginLeft: "4%" }}>
                <p className="xxx">
                  &#11162; Premier paiement en janvier {this.state.yearAfter}.
                </p>
              </div>
              <div style={{ marginTop: "3%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  ARTICLE 5 : Délais de réalisation
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxx">
                  <strong>Le Concepteur</strong> s’engage à achever le site web
                  dans un délai de {this.state.delais} à partir de la date de
                  réception complète des informations. En cas de non réception
                  de la totalité des données et du contenu du site web,{" "}
                  <strong>Le Concepteur</strong> à le droit d’achever le site
                  web et de la facturer.
                  <strong>Le Client</strong> respectera l'ensemble de la
                  législation en vigueur relative à l'informatique, aux fichiers
                  et aux libertés.
                </p>
              </div>
            </div>
            <div className="pagebreak">
              <div style={{ marginTop: "10%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  ARTICLE 6: Résiliation
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxx">
                  Les deux sociétés ont le droit de résilier le contrat, à son
                  terme le {this.state.dateSignContrat} de chaque année,
                  unilatéralement par simple notification sans générer ni
                  indemnités ni restitution d’argent dû.
                </p>
              </div>
              <div style={{ marginTop: "3%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  ARTICLE 7: Litige et juridiction compétente
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxx">
                  En cas de différend, les parties conviennent de rechercher une
                  solution amiable. Si la démarche échoue, le litige sera
                  tranché par le Tribunal de Grande Instance de Tunis.
                </p>
                <p className="xxx">
                  Fait le {this.state.date} à {this.state.villeContrat}
                </p>
                <p className="xxx">
                  Signature précédée de la mention «lu et approuvé » :
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "7%",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <p style={{ fontSize: "22px", fontWeight: "bold" }}>
                    La société {this.state.societe}
                  </p>
                  <p style={{ fontSize: "22px", fontWeight: "bold" }}>
                    Mr/Mme. {this.state.commercial}
                  </p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <p style={{ fontSize: "22px", fontWeight: "bold" }}>
                    La société {this.state.client}
                  </p>
                  <p style={{ fontSize: "22px", fontWeight: "bold" }}>
                    Mr/Mme. {this.state.responsable}
                  </p>
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="pagebreak">
              <div style={{ marginTop: "5%" }}>
                <h1
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "30px",
                  }}
                >
                  Contrat
                </h1>
              </div>
              <div style={{ marginTop: "5%" }}>
                <p className="xxx">Entre les soussignés :</p>
              </div>
              <div>
                <p
                  className="xxx"
                  style={{
                    marginTop: "2%",
                    fontWeight: "bold",
                    fontSize: "25px",
                  }}
                >
                  La société {this.state.societe}
                </p>
              </div>
              <div style={{ marginTop: "2%" }}>
                <p className="xxx">
                  Adresse : {this.state.adresseSociete} SFAX
                </p>
                <p className="xxx">
                  Immatriculée au registre du commerce et des sociétés sous le
                  numéro d'immatriculation {this.state.mfSociete}, représentée
                  en la personne de <strong> {this.state.commercial} </strong>{" "}
                  en sa qualité de {this.state.qualiteCom}.
                </p>
              </div>
              <div>
                <p className="xxx">Email : {this.state.emailCom} </p>
              </div>
              <div>
                <p className="xxx">Mobile : {this.state.telCom} </p>
              </div>
              <div>
                <p className="xxx">
                  Ci-après désigné comme « le Concepteur »,{" "}
                </p>
              </div>
              <div>
                <p
                  style={{
                    marginTop: "2%",
                    fontWeight: "bold",
                    fontSize: "25px",
                  }}
                >
                  {" "}
                  Et La société {this.state.client}
                </p>
              </div>
              <div style={{ marginTop: "2%" }}>
                <p className="xxx">
                  Adresse : {this.state.adresseClient} {this.state.villeClient}
                </p>
                <p className="xxx">
                  Représentée en la personne de{" "}
                  <strong>Mr/Mme {this.state.responsable}</strong> en sa
                  qualité de {this.state.fonction}.
                </p>
              </div>
              <div>
                <p className="xxx">Email : {this.state.emailClient} </p>
              </div>
              <div>
                <p className="xxx">Tel. : {this.state.telClient} </p>
              </div>
              <div>
                <p className="xxx">Ci-après désigné comme « le Client ». </p>
              </div>
              <div style={{ marginTop: "3%" }}>
                <h3
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    fontSize: "30px",
                  }}
                >
                  Bon de commande
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxx">
                  Le présent contrat constitue un bon de commande définitif.
                </p>
              </div>
              <div style={{ marginTop: "3%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  PRÉAMBULE :
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxx">Le Client demande à disposer :</p>
              </div>
              <div>
                <p className="xxx">
                  &#11162; Développement d’un site web {this.state.offres} en{" "}
                  {this.state.langue}.
                </p>
              </div>
              <div>
                <p className="xxx">
                  Le Client et le Prestataire concluent un contrat pour la
                  réalisation et le développement d’un site web.
                </p>
              </div>
              <div>
                <p className="xxx">
                  Le Prestataire accepte les conditions stipulées dans le
                  présent contrat pour la réalisation et le développement d’un
                  site web.Les parties conviennent ce qui suit :
                </p>
              </div>
            </div>
            <div
              style={{ marginTop: "10%", marginBottom: "10%" }}
              className="pagebreak"
            >
              <div style={{ marginTop: "3%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  ARTICLE 1 : Objet du contrat
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxx">
                  <strong>Le Concepteur</strong> créera pour le compte du{" "}
                  <strong>client</strong> un site web conformément aux
                  conditions définies par le présent contrat, moyennant
                  rémunération.
                </p>
              </div>
              <div style={{ marginTop: "3%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  ARTICLE 2 : Missions du concepteur
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p
                  style={{ color: "grey", fontWeight: "bold" }}
                  className="xxx"
                >
                  Développement site web :
                </p>
              </div>
              <div style={{ marginLeft: "2%" }}>
                {this.state.rubrique.map((el) => (
                  <p style={{ fontWeight: "bold" }} className="xxx">
                    &#9679;{el.label}
                  </p>
                ))}
              </div>
              <div style={{ marginTop: "3%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  ARTICLE 3 : Prix et Modalités de paiement
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxxx">
                  Le Client procédera au paiement de{" "}
                  <strong>
                    {" "}
                    {this.state.sousTotHT} Dinars Hors Taxe soit{" "}
                    {this.state.totalTtc} Dinars TTC
                  </strong>{" "}
                  Selon le cas:
                </p>
              </div>
              <div>
                <p className="xxx">
                  Le Client procède à un paiement d’un somme de{" "}
                  <strong> {this.state.totalTtc} Dinars TTC</strong> répartis
                  comme suit:
                </p>
              </div>
              <div style={{ marginLeft: "2%" }}>
                {this.state.paiement.map((el) => (
                  <p className="xxx">
                    &#9679;{el.pourcentage} <strong>{el.mnt} DT</strong>{" "}
                    {el.motif}
                  </p>
                ))}
              </div>
            </div>
            <div
              style={{ marginTop: "10%", marginBottom: "10%" }}
              className="pagebreak"
            >
              <div style={{ marginTop: "3%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  ARTICLE 4 : Hébergement et référencement du site web
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxx">
                  &#9679; Les frais annuels de réservation du nom de domaine,
                  service messagerie, hébergement sont pour{" "}
                  <strong> {this.state.fraisRes} Dinars Hors Taxe. </strong>
                </p>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxx">
                  &#9679; Les frais annuel de référencement sur les moteurs de
                  recherches est à la somme de{" "}
                  <strong> {this.state.fraisRef} Dinars en Hors Taxe.</strong>
                </p>
              </div>
              <div style={{ marginLeft: "4%" }}>
                <p className="xxx">
                  &#11162; Premier paiement est à l’avance, la date de la
                  facturation sera le mois de janvier {this.state.yearAfter}.
                </p>
              </div>
              <div style={{ marginTop: "3%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  ARTICLE 5 : Délais de réalisation
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxx">
                  <strong>Le Concepteur</strong> s’engage à achever les sites
                  web dans un délai de {this.state.delais} à partir de la date
                  de réception complète des informations. En cas de non
                  réception de la totalité des données et du contenu du site
                  web,<strong>Le Concepteur</strong> à le droit d’achever le
                  site web et de la facturer. Le Client respectera l'ensemble de
                  la législation en vigueur relative à l'informatique, aux
                  fichiers et aux libertés
                </p>
              </div>
              <div style={{ marginTop: "3%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  ARTICLE 6: Résiliation
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxx">
                  Les deux sociétés ont le droit de résilier le contrat, à son
                  terme le {this.state.dateSignContrat} de chaque année,
                  unilatéralement par simple notification sans générer ni
                  indemnités ni restitution d’argent dû.
                </p>
              </div>
              <div style={{ marginTop: "3%" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "30px" }}>
                  ARTICLE 7: Litige et juridiction compétente
                </h3>
              </div>
              <div style={{ marginTop: "3%" }}>
                <p className="xxx">
                  En cas de différend, les parties conviennent de rechercher une
                  solution amiable. Si la démarche échoue, le litige sera
                  tranché par le Tribunal de Grande Instance de Tunis.
                </p>
                <p className="xxx">
                  Fait le {this.state.date} à {this.state.villeContrat}
                </p>
                <p className="xxx">
                  Signature précédée de la mention «lu et approuvé » :
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "7%",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <p style={{ fontSize: "22px", fontWeight: "bold" }}>
                    La société {this.state.societe}
                  </p>
                  <p style={{ fontSize: "22px", fontWeight: "bold" }}>
                    Mr/Mme. {this.state.commercial}
                  </p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <p style={{ fontSize: "22px", fontWeight: "bold" }}>
                    La société {this.state.client}
                  </p>
                  <p style={{ fontSize: "22px", fontWeight: "bold" }}>
                    Mr/Mme. {this.state.responsable}
                  </p>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}
export default withRouter(ImprContrat);
