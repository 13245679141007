import React, { Component } from 'react'
import axios from 'axios'
import jQuery from 'jquery'
import { Link, BrowserRouter as Router, Route, Switch, withRouter, HashRouter, Redirect } from 'react-router-dom'
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';

class AjoutOffreRubrique extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offre: [],
            selectedOffre: '',
            rubrique: [],
            selectedRubrique: []
        }
    }

    componentDidMount() {
        if (JSON.parse((localStorage.getItem('userPermissions'))) != null) {
            axios.get(process.env.REACT_APP_API_URL + 'Back_mtd/SelectOffre.php').then(res => {
                console.log(res)
                this.setState({
                    offre: res.data
                })
            })
            axios.get(process.env.REACT_APP_API_URL + 'Back_mtd/SelectRubrique.php').then(res => {
                console.log(res)
                this.setState({
                    rubrique: res.data
                })
            })
        }
        else {
            document.location = '/'
        }
    }

    addOffre = selectedOffre => {
        this.setState({ selectedOffre: selectedOffre });
    };

    addRubrique = selectedRubrique => {
        this.setState({ selectedRubrique: selectedRubrique });
    }

    ajout = () => {
        if (this.state.selectedOffre != '' && this.state.selectedRubrique.length > 0) {
            jQuery.ajax({
                url: process.env.REACT_APP_API_URL + 'Back_mtd/AddOffreRubrique.php',
                type: 'POST',
                data: {
                    offreId: this.state.selectedOffre.value,
                    arrayRubrique: this.state.selectedRubrique
                },
                dataType: 'json',
                success: (code_html, statut) => {
                    if (code_html.Result == "OK") {
                        this.props.history.push('/OffreRubrique');
                    }
                    else if (code_html.Result == "KO") {
                        alert("erreur,l'un de vos champs est vide!!");
                    }
                }
            });
        }
        else {
            toast.error('⛔ Veuillez verifier les champs offre et rubrique  !!', { containerId: 'A' });
        }
    }

    render() {
        return (
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="page-header">
                        <h3 className="page-title"> Ajout offre-rubrique </h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 grid-margin">
                            <div className="card">
                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Offre</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.addOffre}
                                                options={this.state.offre}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Rubrique</label>
                                            <Select
                                                isMulti={true}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={this.addRubrique}
                                                options={this.state.rubrique}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <button
                                            style={{ borderRadius: "5px" }}
                                            type="button"
                                            className="btn btn-success"
                                            onClick={this.ajout}
                                        >
                                            Confirmer
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer transition={Flip} enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} autoClose={2500} />
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(AjoutOffreRubrique);





