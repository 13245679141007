import React, { Component } from 'react';
import { Link, BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom'

class StatiquePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        var userPermissions = JSON.parse((localStorage.getItem('userPermissions')));
        if (userPermissions != null) {
        }
        else {
            this.props.history.push('/');
        }
    }

    clearStorage = () => {
        localStorage.clear();
        this.props.history.push('/');
    }

    render() {
        return (
            <div className="container-scroller">
                {/* partial:../../partials/_navbar.html */}
                <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                    <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                        <a className="navbar-brand brand-logo"><img style={{ width: '35%', height: '50%' }} src="../../assets/images/mtd.png" alt="logo" /></a>
                        <a className="navbar-brand brand-logo-mini"><img src="../../assets/images/logo-mini.svg" alt="logo" /></a>
                    </div>
                    <div className="navbar-menu-wrapper d-flex align-items-stretch">
                        <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
                            <span className="mdi mdi-menu" />
                        </button>
                        <ul className="navbar-nav navbar-nav-right">
                            <li className="nav-item nav-logout d-none d-lg-block">
                                <a className="nav-link">
                                    <i style={{ cursor: 'pointer' }} onClick={this.clearStorage} className="mdi mdi-power" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
                {/* partial */}
                <div className="container-fluid page-body-wrapper">
                    {/* partial:../../partials/_sidebar.html */}
                    <nav className="sidebar sidebar-offcanvas" id="sidebar">
                        <ul className="nav">
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                                    <span className="menu-title">Paramétrage</span>
                                    <i className="menu-arrow" />
                                    <i className="mdi mdi-format-list-bulleted" />
                                </a>
                                <div className="collapse" id="ui-basic">
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item"> <Link to="/Users" className="nav-link">Gestion utilisateur</Link></li>
                                        <li className="nav-item"> <Link to="/Societes" className="nav-link">Societes</Link></li>
                                        <li className="nav-item"> <Link to="/Clients" className="nav-link">Clients</Link></li>
                                        <li className="nav-item"> <Link to="/Offres" className="nav-link">Offres</Link></li>
                                        <li className="nav-item"> <Link to="/Rubrique" className="nav-link">Rubrique</Link></li>
                                        <li className="nav-item"> <Link to="/OffreRubrique" className="nav-link">Offre-rubrique</Link></li>
                                        <li className="nav-item"> <Link to="/OffresSociete" className="nav-link">Offre-sociéte</Link></li>
                                        <li className="nav-item"> <Link to="/Options" className="nav-link">Supplémént</Link></li>
                                        <li className="nav-item"> <Link to="/ContratOffre" className="nav-link">Offre de prix</Link></li>
                                        <li className="nav-item"> <Link to="/BonDeCommande" className="nav-link">Bon de commande</Link></li>
                                    </ul>
                                </div>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" href="../../index.html">
                                    <span className="menu-title">Bande de commande</span>
                                    <i className="mdi mdi-home menu-icon" />
                                </a>
                            </li> */}
                        </ul>
                    </nav>
                    {/* partial */}
                    {this.props.children}
                    {/* main-panel ends */}
                </div>
                {/* page-body-wrapper ends */}
            </div>
        );
    }
}
export default withRouter(StatiquePage);