import React, { Component } from "react";
import jQuery from "jquery";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class AjoutOffre extends Component {
    constructor(props) {
        super(props);
        this.state = {
            arrayOffre: [{ nom: "" }],
            ligneOffreSupp: "",
            modal: false
        };
    }

    addOffre = (event, index) => {
        var nom = event.target.value;
        this.setState({
            arrayOffre: this.state.arrayOffre.map((el, id) =>
                id === index ? Object.assign(el, { nom: nom }) : el
            ),
        });
    };

    addLigneOffre = () => {
        var element = { nom: "" };
        this.setState({
            arrayOffre: [...this.state.arrayOffre, element],
        });
    };

    toggleOffre = (index) => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
            ligneOffreSupp: index,
        }));
    };

    deleteOffre = () => {
        this.state.arrayOffre.splice(this.state.ligneOffreSupp, 1);
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }));
    };

    nonDeleteOffre = () => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }));
    };

    ajout = () => {
        jQuery.ajax({
            url: process.env.REACT_APP_API_URL + "Back_mtd/AjoutOffre.php",
            type: "POST",
            data: {
                arrayOffre: this.state.arrayOffre
            },
            dataType: "json",
            success: (code_html, statut) => {
                if (code_html.Result == "OK") {
                    this.props.history.push("/Offres");
                } else if (code_html.Result == "KO") {
                    alert("erreur,l'un de vos champs est vide!!");
                }
            },
        });
    };

    render() {
        return (
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="page-header">
                        <h3 className="page-title"> Ajout Offre </h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 grid-margin">
                            <div className="card">
                                <div className="card-body">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Offres:</label>
                                        </div>
                                    </div>
                                    <table
                                        style={{ width: "70%", marginTop: "10px" }}
                                        className="table table-bordered"
                                    >
                                        <thead>
                                            <tr>
                                                <th className="col-md-10" style={{ textAlign: "center" }} scope="col">
                                                    offre
                                            </th>
                                                <th className="col-md-2" style={{ textAlign: "center" }} scope="col">
                                                    Supp
                                            </th>
                                            </tr>
                                        </thead>
                                        {this.state.arrayOffre.map((el, index) => (
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style={{ textAlign: "center" }}
                                                        className="col-md-10"
                                                    >
                                                        <input
                                                            style={{ width: '100%' }}
                                                            type="text"
                                                            onChange={(e) => this.addOffre(e, index)}
                                                        />
                                                    </td>
                                                    <td
                                                        className="col-md-2"
                                                        style={{ textAlign: "center" }}
                                                    >
                                                        <button
                                                            className="buttonSupprimer"
                                                            onClick={() => this.toggleOffre(index)}
                                                        >
                                                            <i
                                                                style={{ color: "white" }}
                                                                className="fas fa-trash-alt"
                                                            ></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            marginLeft: "15px",
                                            marginTop: '5px'
                                        }}
                                    >
                                        <Button
                                            style={{ borderRadius: "5px" }}
                                            onClick={this.addLigneOffre}
                                            color="secondary"
                                        >
                                            <i className="fas fa-plus-circle"></i>Ajouter ligne
                                    </Button>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <button
                                            style={{ borderRadius: "5px" }}
                                            type="button"
                                            className="btn btn-success"
                                            onClick={this.ajout}
                                        >
                                            Confirmer
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.nonDeleteOffre}
                    className={this.props.className}
                    fade={false}
                >
                    <ModalHeader toggle={this.nonDeleteOffre}>
                        {" "}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "465px",
                            }}
                        >
                            <img width="30%" src="./images/deleteModal.png" alt=".." />
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <div
                            style={{
                                fontFamily: "Montserrat, sans-serif",
                                FontSize: "14px",
                                FontWeight: "700",
                                LineHeight: "18.375px",
                            }}
                        >
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <p>Êtes-Vous sûr de vouloir supprimer cette ligne ?</p>
                            </div>
                            <div
                                className="hvr-push"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    marginBottom: "15px",
                                }}
                            >
                                <div>
                                    <button
                                        onClick={() => this.deleteOffre()}
                                        style={{
                                            backgroundColor: "#00FF7F",
                                            borderRadius: "5px",
                                            color: "white",
                                        }}
                                    >
                                        <i
                                            style={{ color: "white" }}
                                            className="far fa-thumbs-up"
                                        ></i>
                        Oui
                      </button>
                                </div>
                                <div>
                                    <button
                                        onClick={() => this.nonDeleteOffre()}
                                        style={{
                                            backgroundColor: "#D9534F",
                                            borderRadius: "5px",
                                            color: "white",
                                        }}
                                    >
                                        <i
                                            style={{ color: "white" }}
                                            className="far fa-thumbs-down"
                                        ></i>
                        Non
                      </button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default withRouter(AjoutOffre);
