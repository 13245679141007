import React, { Component } from "react";
import axios from "axios";
import jQuery from "jquery";
import { Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import Select from "react-select";

class ModifOffreSociete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offreSocieteId: "",
      offres: [],
      selectOffre: "",
      societes: [],
      selectSociete: "",
    };
  }

  componentDidMount() {
    let offreSocieteId = this.props.location.state.offreSocieteId;
    axios
      .get(
        process.env.REACT_APP_API_URL +
        "Back_mtd/GetOffreSociete.php?id=" +
        offreSocieteId
      )
      .then((res) => {
        console.log(res);
        this.setState({
          offreSocieteId: res.data.offreSocieteId,
          selectOffre: res.data.offres,
          selectSociete: res.data.societes,
        });
      });
    axios
      .get(process.env.REACT_APP_API_URL + "Back_mtd/GetOffres.php")
      .then((res) => {
        console.log(res);
        this.setState({
          offres: res.data,
        });
      });
    axios
      .get(process.env.REACT_APP_API_URL + "Back_mtd/GetSocietes.php")
      .then((res) => {
        console.log(res);
        this.setState({
          societes: res.data,
        });
      });
  }

  modifOffre = (selectOffre) => {
    this.setState({ selectOffre: selectOffre });
  };

  modifSociete = (selectSociete) => {
    this.setState({ selectSociete: selectSociete });
  };

  modif = () => {
    jQuery.ajax({
      url: process.env.REACT_APP_API_URL + "Back_mtd/UpdOffreSociete.php",
      type: "POST",
      data: {
        offreSocieteId: this.state.offreSocieteId,
        societes: this.state.selectSociete,
        offres: this.state.selectOffre.value,
      },
      dataType: "json",
      success: (code_html, statut) => {
        if (code_html.Result == "OK") {
          this.props.history.push("/OffresSociete");
        } else if (code_html.Result == "KO") {
          alert("erreur,l'un de vos champs est vide!!");
        }
      },
    });
  };

  render() {
    return (
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h3 className="page-title"> Modification offre-sociéte </h3>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>offres</label>
                      <Select
                        value={this.state.selectOffre}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.modifOffre}
                        options={this.state.offres}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Societe</label>
                      <Select
                        isMulti={true}
                        value={this.state.selectSociete}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.modifSociete}
                        options={this.state.societes}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      style={{ borderRadius: "5px" }}
                      type="button"
                      className="btn btn-success"
                      onClick={this.modif}
                    >
                      Confirmer
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ModifOffreSociete);
